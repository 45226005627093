import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row, Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import "../../../css/wallet.scss";
import { translate } from "../../../utils/translate";
import { numberWithCommas } from "../../../utils/function";
import Select from "react-select";
import { url, api } from "../../../utils/api";
import swal from "sweetalert";
import ComingSoon from "../ComingSoon";
import LiveSupport from "../../components/Live/LiveSupport";
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
// import OtpTimer from "otp-timer";


const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "none",
      boxShadow: state.isFocused ? null : null,
      color: "white",
      height: "3.5rem",
      padding: "7px"
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      backdropFilter: "blur(100px)",
      color: "black",
      borderRadius: "14px",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0
    }),
    singleValue: (base) => ({
      ...base,
      color: "#1b1b1b",
    })
  };


const WithdrawCrypto = (props) => {
    const [wallet, setWallet] = useState(null);
    const [usdt, setUSDT] = useState(0);
    useEffect(() => {
        getWalletType();
        getWallet();
    }, []);
    const [successModal, setSuccessModal] = useState(false);
    const [walletType, setWalletType] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [password, setPassword] = useState('');
    const [emailVerifyCode, setEmailVerifyCode] = useState('');
    const [smsVerifyCode, setSMSVerifyCode] = useState('');
    let errorsObj = { walletType: '', walletAddress: '', amount: '', password: '', emailVerifyCode: '', smsVerifyCode: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [successData, setSuccessData] = useState('');
    const [showTimeout, setShowTimeout] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingOTP, setLoadingOTP] = useState(false);
    const setValue = (e) => {
        setSelectedWithdrawal({ value: e.value, label: e.label })
    }
    const submit = (e) => {
        e.preventDefault();
        var onlyNumber = /^\d+$/;
        let error = false;
        const errorObj = { ...errorsObj };
        if (selectedWithdrawal.value === '') {
            errorObj.walletType = translate(props.lang, "This field is required");
            error = true;
        }
        if (walletAddress === '') {
            errorObj.walletAddress = translate(props.lang, "This field is required");
            error = true;
        }
        if (amount === '') {
            errorObj.amount = translate(props.lang, "This field is required");
            error = true;
        }
        else if (!onlyNumber.test(amount)) {
            errorObj.amount = 'Only Numbers are allowed in this field';
            error = false;
        }
        if (password === '') {
            errorObj.password = translate(props.lang, "This field is required");
            error = true;
        }
        if (emailVerifyCode === '') {
            errorObj.emailVerifyCode = translate(props.lang, "This field is required");
            error = true;
        }

        setErrors(errorObj);
        if (error) return;
        setLoading(true);
        let data = {
            "withdrawalType": selectedWithdrawal.value,
            "walletAddress": walletAddress,
            "amount": amount,
            "transactionPassword": password,
            "emailVerifyCode": emailVerifyCode
        }
        fetch(api.wallet + '/withdrawal', {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setWalletAddress('');
                    setAmount('');
                    setPassword('');
                    setEmailVerifyCode('');
                    setSMSVerifyCode('');
                    setSuccessData(result.dbWalletWithdrawalDto);
                    setSuccessModal(true);
                    setLoading(false);
                    setShowTimeout(0);
                }
                else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                    setLoading(false);
                }

            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const closeSuccessModal = () => {
        setSuccessModal(false);
    }
    const [selectedWithdrawal, setSelectedWithdrawal] = useState({ value: '', label: '' });

    const renderImage = (value) => {
        return require('../../../images/wallet/WithdrawTether-USDT.png').default;
    }

    const getWalletType = (e) => {
        fetch(api.wallet + '/withdrawalTypeList', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setWalletType(result.withdrawalTypeList);
                    setTransactionFee(result.withdrawalTypeList[1].transferFee)
                    setSelectedWithdrawal({ value: result.withdrawalTypeList[1].key, label: result.withdrawalTypeList[1].value, transferFee: result.withdrawalTypeList[1].transferFee })
                }
                else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }

            }).catch(error => {
                console.log("Error:", error);
            });
    }
    const getWallet = (e) => {
        fetch(api.wallet + '/walletList', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    let usdt = result.walletList.filter(item => item.walletType === 'USDT');
                    if (usdt.length > 0) {
                        setUSDT(usdt[0].walletBalance);
                    }
                }
                else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const sendOTP = () => {
        setLoadingOTP(true);
        fetch(api.wallet + '/sendWithdrawalOtp', {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    swal(translate(props.lang, "Success"), result.message, "success");
                    setShowTimeout(60);
                }
                else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                    setLoadingOTP(false);
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const handleTimerOnChange = useCallback(async () => {
        if (showTimeout > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setShowTimeout(prev => prev - 1)
        }
        else {
            setLoadingOTP(false);
        }
    }, [showTimeout])

    useEffect(() => {
        handleTimerOnChange()
    }, [handleTimerOnChange])


    return (
        <>
            <Row className="justify-content-center mt-3  pb-0-md">
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Link to="/wallet/withdrawal" className="d-flex align-items-center mt-4 mt-md-0">
                        <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
                    </Link>
                    <div className="card card-bx cardBg transfer mt-3">



                        <Row className="justify-content-start align-items-start vertical-divider">


                            <Col lg={12} md={12} xs={12} sm={12} c>
                                <div className="px-5">
                                    <Row className="justify-content-start ">
                                        <Col lg={12} md={12} xs={12} sm={12}>
                                            <div className="pb-4 mt-5 mt-md-4">
                                                <span className={`headerText`}>{translate(props.lang, "Crypto Withdrawal")}</span>
                                            </div>

                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                                            <div className="form-group" id="selectWithdrawal">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Wallet Balance")}</span>
                                                </label>

                                                <div>
                                                    <span className={`font-weight-700 font-40 text-uppercase text-blue`}>{usdt ? numberWithCommas(usdt) : "0"} </span> <span className="font-weight-500 font-16 text-uppercase text-blue">USD</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-4" id="selectWithdrawal">
                                            <div className="form-group" id="selectWithdrawal">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "withdrawal type")}</span>
                                                </label>
                                                <div className={`input-border mt-2 ${errors.walletType ? 'errorBorder' : ''}`}>
                                                    <Select
                                                        options={walletType && walletType.map((item, i) => ({ value: item.key, label: item.value, transferFee: item.transferFee }))}
                                                        value={selectedWithdrawal}
                                                        onChange={(e) => setValue(e)}
                                                        placeholder={translate(props.lang, "PLEASE SELECT")}
                                                      
                                                        styles={customStyles}

                                                    />
                                                </div>
                                                <span className="error">{translate(props.lang, errors.walletType)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "wallet address")}</span>
                                                </label>
                                                <div className={`input-group mt-2 ${errors.walletAddress ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Please Enter Your Wallet Address")}
                                                        onChange={(e) => setWalletAddress(e.target.value)}
                                                        value={walletAddress}
                                                    />
                                                    <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid pencil-icon" />
                                                    </div>
                                                </div>
                                                <span className="error">{translate(props.lang, errors.walletAddress)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "withdraw amount")}</span>
                                                </label>
                                                <div className={`input-group mt-2 ${errors.amount ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Enter Your Withdraw Amount")}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        value={amount}
                                                        autoComplete="new-password"
                                                    />
                                                    <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid pencil-icon" />
                                                    </div>
                                                </div>
                                                <span className="error">{translate(props.lang, errors.amount)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Transaction Password")}</span>
                                                </label>
                                                <div className={`input-group mt-2 ${errors.password ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="password"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Enter Your Transaction Password")}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={password}
                                                        autoComplete="new-password"
                                                    />
                                                    <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid pencil-icon" />
                                                    </div>
                                                </div>

                                                <span className="error">{translate(props.lang, errors.password)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "email verify code")}</span>
                                                </label>
                                                <div className={`input-group mt-2 ${errors.password ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Enter Your Email Verify Code")}
                                                        onChange={(e) => setEmailVerifyCode(e.target.value)}
                                                        value={emailVerifyCode}
                                                    />
                                                    <div className='mx-0 mx-md-2 d-flex justify-content-center align-items-center'>
                                                        <button
                                                            onClick={sendOTP}
                                                            disabled={loadingOTP}
                                                            className="requestCodeBtn py-1 px-3 me-2"
                                                        >  {showTimeout > 0 ? <span>{showTimeout}</span> : <span>{translate(props.lang, "Send")}</span>}
                                                        </button>
                                                    </div>

                                                </div>
                                                <span className="error">{translate(props.lang, errors.emailVerifyCode)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        {/* <Col lg={6} md={7} sm={12} xs={12} className="mb-2">

                                            <button
                                                onClick={sendOTP}
                                                disabled={loadingOTP}
                                                className="btn default-btn btn-block font-weight-400 font-16"
                                            >  {showTimeout > 0 ? <span>{showTimeout}</span> :<span>{translate(props.lang,"Send OTP To Email")}</span>}
                                            </button>

                                        </Col> */}
                                               <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                            <button className="btn primary-btn btn-block font-weight-400 font-16" onClick={(e) => submit(e)} disabled={loading}>
                                                {loading ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Confirm")}
                                            </button>
                                        </Col>
                                        {/* <Col>
                                            <div className="text-center mt-3">
                                                <span className={`font-weight-400 font-13 text-red-1`}>{translate(props.lang, "*Withdraw will be charged 5 USDT per transaction")}</span>
                                            </div>
                                        </Col> */}
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

            {/* <div>
                <div className="font-weight-400 font-16 text-grey-3 pb-2">
                    {translate(props.lang, "Withdraw T&C")}
                </div>
                <div className="font-weight-400 font-16 text-grey-3 pb-2">
                    {translate(props.lang, "CRM wallet is allow to withdraw commission and bonus once per week.")}
                </div>
                <div className="font-weight-400 font-14 text-grey-3 pb-3">
                    {translate(props.lang, "C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.")}
                </div>
            </div>
            <div className="mt-2 pb-5">
                <div className="font-weight-400 font-16 text-grey-3 pb-2">
                    {translate(props.lang, "Termination")}
                </div>
                <div className="font-weight-400 font-16 text-grey-3">
                    {translate(props.lang, "If investor terminate contract before period end, just able to withdraw invest capital without ROI.")}
                </div>
            </div> */}

            <Modal className="fade successModel" show={successModal} onHide={() => setSuccessModal(false)} centered>
                <div className="position-relative">
                    <Modal.Header className="px-2 py-4">

                    </Modal.Header>
                    <Modal.Body className="py-5">
                        <div className="text-center payment-success-container">
                            <img src={require('../../../images/investment/payment_success.png').default} className="img-fluid payment-success-img" />
                        </div>
                        <div className="text-center pt-4 mb-2">
                            <span className="withdraw-subscript-text">{translate(props.lang, "withdrawal request submitted")}</span>
                        </div>

                        <div className="px-3">
                            <Row className="justify-content-center align-items-center mt-3">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <span className="investment-label">{translate(props.lang, "coin")}</span>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="text-start text-md-end">
                                    <span className="investment-value">{successData ? successData.walletType : "usdt"}</span>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center mt-3">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <span className="investment-label">{translate(props.lang, "address")}</span>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="text-start text-md-end">
                                    <span className="investment-value">{successData ? successData.walletAddress : ""}</span>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center mt-3">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <span className="investment-label">{translate(props.lang, "Network")}</span>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="text-start text-md-end">
                                    <span className="investment-value">{successData ? successData.network : ""}</span>
                                </Col>
                            </Row>
                            {/* <div className="mt-5 mt-md-3 text-center text-md-start">
                                <span className={`font-weight-400 font-13 text-red-1`}>{translate(props.lang, "*Withdraw will be charged 3% of total amount")}</span>
                            </div> */}
                            <Row className="justify-content-center align-items-center mt-4 mt-md-5">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="choose-package-btn py-3 text-center" onClick={() => closeSuccessModal()}>
                                        <span className="choose-package-text">{translate(props.lang, "Complete")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>

        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(WithdrawCrypto);
