import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Input } from 'reactstrap';
import "../../../css/setting.scss";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import { FaSpinner } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";
import Select from "react-select";
import { replaceUrl } from "../../../utils/replaceUrl";
import imageCompression from 'browser-image-compression';
const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "none",
      boxShadow: state.isFocused ? null : null,
      color: "white",
      height: "3.5rem",
      padding: "7px"
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      backdropFilter: "blur(100px)",
      color: "black",
      borderRadius: "14px",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0
    }),
    singleValue: (base) => ({
      ...base,
      color: "#1b1b1b",
    })
  };
const KYC = (props) => {
    const Option = [
        { value: "MALE", label: translate(props.lang, 'Male') },
        { value: 'FEMALE', label: translate(props.lang, 'Female') },
        //     { value: 'BTC', label: 'BTC' },
        //     { value: 'ETH', label: 'ETH' }
         ]
    
    let errorsObj = { fullName: '', identityNo: '', gender: '', phoneNo: '', address: '', postcode: '', country: '', icImageUrl: '', icProofImageUrl: '', bankImageUrl: '' };
    const [formData, setFormData] = useState({
        fullName: '',
        identityNo: '',
        gender: '',
        address: '',
        postcode: '',
        country: '',
        icImageUrl: '',
        icProofImageUrl: '',
        bankImageUrl: '',
        phoneNo: '',
    })
    const [errors, setErrors] = useState(errorsObj);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState('');
    const [kycStatus, setKycStatus] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [img, setImg] = useState({
        icImageUrl: null,
        icProofImageUrl: null,
        bankImageUrl: null,
    });
    const [dialCode, setDialCode] = useState('-');
    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }


    const submitForm = () => {
        let check = true;
        const errorMsg = { ...errorsObj };
        var onlyNumber = /^\d+$/;
        var fileSizeChecking = false;
        if (!formData.fullName) {
            errorMsg.fullName = translate(props.lang, "This field is required");
            check = false;
        }
        if (!formData.gender) {
            errorMsg.gender = translate(props.lang, "This field is required");
            check = false;
        }
        if (!formData.identityNo) {
            errorMsg.identityNo = translate(props.lang, "This field is required");
            check = false;
        }
        if (!formData.address) {
            errorMsg.address = translate(props.lang, "This field is required");
            check = false;
        }
        if (!formData.postcode) {
            errorMsg.postcode = translate(props.lang, "This field is required");
            check = false;
        }
        
        if (!formData.phoneNo) {
            errorMsg.phoneNo = translate(props.lang, "This field is required");
            check = false;
        }
        else if (!onlyNumber.test(formData.phoneNo)) {
            errorMsg.phoneNo = 'Only Numbers are allowed in this field';
            check = false;
        }
        if (!selectedCountry) {
            errorMsg.country = translate(props.lang, "This field is required");
            check = false;
        }
        if (!formData.icImageUrl) {
            errorMsg.icImageUrl = translate(props.lang, "This field is required");
            check = false;
        }

        if (formData.icImageUrl) {
            if (formData.icImageUrl.name) {
                if (!formData.icImageUrl.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.icImageUrl = translate(props.lang, "Only allow format jpg or png");
                    check = false;
                }
            }
        }
        if (!formData.icProofImageUrl) {
            errorMsg.icProofImageUrl =  translate(props.lang, "This field is required");
            check = false;
        }
        if (formData.icProofImageUrl) {
            if (formData.icProofImageUrl.name) {
                if (!formData.icProofImageUrl.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.icProofImageUrl = translate(props.lang, "Only allow format jpg or png");
                    check = false;
                }
            }
        }
        if (!formData.bankImageUrl) {
            errorMsg.bankImageUrl = translate(props.lang, "This field is required");
            check = false;
        }

        if (formData.bankImageUrl) {
            if (formData.bankImageUrl.name) {
                if (!formData.bankImageUrl.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.bankImageUrl = translate(props.lang, "Only allow format jpg or png");
                    check = false;
                }
            }
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fileSizeChecking = validateFileSize()
       
            if(fileSizeChecking){
                const data = new FormData()
                let country = selectedCountry ?  countries.length > 0 ? countries.find(e => e.label === selectedCountry) : countries.find(e => e.label === selectedCountry) : ""; 
                data.append("fullname",formData.fullName)
                // data.append("phoneNo",formData.phoneNo)
                data.append("identityNo",formData.identityNo)
                data.append("address1",formData.address)
                data.append("postCode",formData.postcode)
                data.append("countryCode",country.value)
                data.append("phoneNo",formData.phoneNo)
                data.append("icFrontUpload",formData.icImageUrl)
                data.append("gender",formData.gender)
                data.append("icHandHeldUpload",formData.icProofImageUrl)
                data.append("icBankUpload",formData.bankImageUrl)
                fetch(api.kyc, {
                    method: "POST",
                    headers: new Headers({
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: data
                })
                .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0]) {
                        getKycInfo();
                        setLoading(false);
                        swal(translate(props.lang, 'Success'), result.message, "success");
                    }
                    else {
                        setLoading(false);
                        swal(translate(props.lang, 'Error'), result.message, "error");

                    }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        } else {
            setLoading(false);
            swal(translate(props.lang, 'Error'), translate(props.lang, 'Please fill in all required fields with valid information.'), "error");
        }
    }

    useEffect(() => {
        getCountry();
        // getKycInfo();
    }, [props.lang])

    const validateFileSize = () =>{
        const MAX_FILE_SIZE = 25000000 // 10MB
    
        const fileSizeKiloBytes = formData.icImageUrl.size
        const fileSizeKiloBytes1 = formData.icProofImageUrl.size
        const fileSizeKiloBytes2 = formData.bankImageUrl.size
        if(fileSizeKiloBytes + fileSizeKiloBytes1 + fileSizeKiloBytes2 > MAX_FILE_SIZE){
            swal({
                title: translate(props.lang,'Error'),
                text:  translate(props.lang,"Total image size cannot more than 25 MB"),
                icon: "warning",
                showCancelButton : false, 
                buttons: translate(props.lang, 'Ok')         
            })
            setLoading(false)
            return false;
        }
        else if(fileSizeKiloBytes + fileSizeKiloBytes1 + fileSizeKiloBytes2 < 50000){
            swal({
                title: translate(props.lang,'Error'),
                text:  translate(props.lang,"Total image size cannot less than 50 KB"),
                icon: "warning",
                showCancelButton : false, 
                buttons: translate(props.lang, 'Ok')         
            })
            setLoading(false)
            return false;
        }
        else{
            return true;
        }
       
    }

    const uploadImage = async(e, type) => {   
        if (e.target.files[0]) {
            let size = (e.target.files[0].size / 1024 / 1024).toFixed(2);
            console.log(size,"size")
            const newFormData = { ...formData };
            const newImg = { ...img };
            if (size < 2) {
                console.log("here")
                if (type === "icImageUrl") {
                    newFormData['icImageUrl'] = e.target.files[0];
                    newImg.icImageUrl = URL.createObjectURL(e.target.files[0]);
                
                } else if (type === "icProofImageUrl") {
                    newFormData['icProofImageUrl'] = e.target.files[0];
                    newImg.icProofImageUrl = URL.createObjectURL(e.target.files[0]);
                
                } else if (type === "bankImageUrl") {
                    newFormData['bankImageUrl'] = e.target.files[0];
                    newImg.bankImageUrl = URL.createObjectURL(e.target.files[0]);
    
                }
                setFormData(newFormData);
                setImg(newImg);
            }
            else {
                const options = {
                    maxSizeMB: 1,
                    useWebWorker: true,
                    maxWidthOrHeight: 1024,
                }
                try {
                    const compressedFile = await imageCompression(e.target.files[0], options);
                    var file = new File([compressedFile], compressedFile.name, { lastModified: Math.floor(Date.now() / 1000) });
                    if (type === "icImageUrl") {
                        newFormData['icImageUrl'] = file;
                        newImg.icImageUrl = URL.createObjectURL(file);
        
                    } else if (type === "icProofImageUrl") {
                        newFormData['icProofImageUrl'] = file;
                        newImg.icProofImageUrl = URL.createObjectURL(file);
                    
                    } else if (type === "bankImageUrl") {
                        newFormData['bankImageUrl'] = file;
                        newImg.bankImageUrl = URL.createObjectURL(file);
             
                    }
                    setFormData(newFormData);
                    setImg(newImg);
                } catch (error) {
                    console.log(error);
                }
                
            }
        }
    }

    const getKycInfo = (countries = []) => {
        fetch(api.user + '/getKycInfo', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
            let result = JSON.parse(responseJson[1]);
            if (responseJson[0]) {
                    let c = result.country ?  countries.length > 0 ? countries.find(e => e.label === result.country) : countries.find(e => e.label === result.country) : ""; 
                    setKycStatus(result.kycStatusDesc)
                    setSelectedCountry(c.label ?? "");
                    if (result.status !== 'Pending') {
                        setFormData(result)
                        if (result.kycStatus !== 'Rejected') {
                            setImg(
                                {
                                    icImageUrl: result.icImageUrl,
                                    icProofImageUrl: result.icProofImageUrl,
                                    bankImageUrl: result.bankImageUrl
                                }
                            )
                        }
                    }
                }
                else {
                    swal(translate(props.lang, 'Error'), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getCountry = (e) => {
        fetch(api.country, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language" : props.lang,
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            let c = responseJson.countryList.map(i => {
                return {
                    value: i.key,
                    label: i.value,
                }
            })
            setCountries(c);
            getKycInfo(c);
        }).catch(error => {
            console.log("Error:", error);
        });
      }


    return (
        <>
            <div className="mt-3">
                <Row className="justify-content-center align-items-center">
                    <Col lg={12} md={12} xs={12} sm={12} className="mt-3 mt-md-0">
                        <div className="card card-black h-100 py-4 px-4">
                            <div className="card-body align-items-center">
                                <Row className="justify-content-center align-items-center ">
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div className="pb-2">
                                            <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "fullname")}</span>
                                        </div>
                                        <div className={`input-group ${errors.fullName ? 'errorBorder' : ''}`}>
                                            <Input
                                                type="text"
                                                name="fullName"
                                                placeholder={translate(props.lang, "Enter Your Fullname")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={formData.fullName}
                                                onChange={(e) => handleAddFormChange(e)}
                                                readOnly={kycStatus ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                             <div className="px-3">
                                                <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.fullName)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3" id="kyc">
                                        <div className="pb-2">
                                            <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "gender")}</span>
                                        </div>
                                        <div className={`input-border ${errors.gender ? 'errorBorder' : ''}`}>
                                             <Select
                                                    options={Option}
                                                    value={Option.find(option => option.value === formData.gender)}
                                                    onChange={(e) => handleOptionSelected(e, "gender")}
                                                    placeholder={translate(props.lang, "Select Gender")}
                                                    styles={customStyles}
                                                    isDisabled={kycStatus ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                            
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.gender)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div className="pb-2">
                                            <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "ic/ passport no.")}</span>
                                        </div>
                                        <div className={`input-group ${errors.identityNo ? 'errorBorder' : ''}`}>
                                            <Input
                                                type="text"
                                                name="identityNo"
                                                placeholder={translate(props.lang, "Enter Your IC / Passport No.")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={formData.identityNo}
                                                onChange={(e) => handleAddFormChange(e)}
                                                readOnly={kycStatus  ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                              <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.identityNo)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div className="pb-2">
                                         <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "phone number")}</span>
                                        </div>
                                        <div className={`input-group ${errors.phoneNo ? 'errorBorder' : ''}`}>
                                        {/* <span className="input-group-text dialCode px-4">{dialCode}</span> */}
                                            <Input
                                                type="text"
                                                name="phoneNo"
                                                placeholder={translate(props.lang, "Enter Phone Number")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={formData.phoneNo}
                                                onChange={(e) => handleAddFormChange(e)}
                                                readOnly={kycStatus  ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                              <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.phoneNo)}</span>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <div className="pb-2">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "residential address")}</span>
                                        </div>
                                        <div className={`input-group ${errors.address ? 'errorBorder' : ''}`}>
                                            <Input
                                                type="text"
                                                name="address"
                                                placeholder={translate(props.lang, "Enter Residential Address")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={formData.address}
                                                onChange={(e) => handleAddFormChange(e)}
                                                readOnly={kycStatus  ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                              <div className="px-3">
                                                <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.address)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div className="pb-2">
                                            <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "postcode")}</span>
                                        </div>
                                        <div className={`input-group ${errors.postcode ? 'errorBorder' : ''}`}>
                                            
                                            <Input
                                                type="text"
                                                name="postcode"
                                                placeholder={translate(props.lang, "Enter Your Postcode")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={formData.postcode}
                                                onChange={(e) => handleAddFormChange(e)}
                                                readOnly={kycStatus  ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                              <div className="px-3">
                                                <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.postcode)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3" id="kyc" >
                                        <div className="pb-2">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "Country")}</span>
                                        </div>
                                        <div className={`input-border ${errors.country ? 'errorBorder' : ''}`}>
                                            <Select
                                                options={countries}
                                                onChange={(e) =>  setSelectedCountry(e.label)}
                                                value={countries && countries.filter(option => option.label === selectedCountry)}
                                                placeholder={translate(props.lang, "SELECT COUNTRY")}
                                                styles={customStyles}
                                                isDisabled={kycStatus  ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? false : true : false}   
                                            />
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.country)}</span>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col lg={4} md={4} sm={6} xs={6} className="mb-3">
                                        <div>
                                            <span className={`font-weight-500 font-18 text-capitalize text-grey-1`}>{translate(props.lang, "joining date")}</span>
                                        </div>
                                        <div className="mt-2">
                                            <span className="font-weight-500 font-18 text-blue-1 text-capitalize">{formData ? formData.joiningDate : ""}</span>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={6} className="mb-3">
                                        <div>
                                            <span className={`font-weight-500 font-18 text-capitalize text-grey-1`}>{translate(props.lang, "Referral Code")}</span>
                                        </div>
                                        <div className="mt-2">
                                            <span className="font-weight-500 font-18 text-blue-1 text-capitalize">{formData ? formData.referralCode : ""}</span>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <span className={`font-weight-500 font-18 text-capitalize text-grey-1`}>{translate(props.lang, "kyc status")}</span>
                                        </div>
                                        <div className="mt-2">
                                            <span className="font-weight-500 font-18 text-blue-1 text-capitalize">{formData ? formData.kycStatus : ""}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-start justify-content-center">
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <div className={`card-document text-center p-4 ${errors.icImageUrl ? "errorBorder" : ''}`}>
                                            <label htmlFor="uploadIc">
                                                <div className="text-center">
                                                    {img.icImageUrl ? (
                                                        <div className="py-2">
                                                            <img src={replaceUrl(img.icImageUrl)} className="img-fluid document" alt="document" />
                                                        </div>
                                                    ) : (
                                                        <img src={require('../../../images/kyc/icdoc.png').default} className="img-fluid" alt="document" />
                                                    )}
                                                </div>

                                            </label>
                                            <input type={kycStatus ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? "file" : "" : "file"} id="uploadIc" className=" d-none" onChange={(e) => uploadImage(e, "icImageUrl")} />
                                        </div>
                                        <div className="text-start mt-4 mt-md-3 pb-4 pb-md-0">
                                            <span className={`font-weight-500 font-18 text-grey-1`}>{translate(props.lang, "Upload IC/ Passport")}</span>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.icImageUrl)}</span>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <div className={`card-document text-center p-4 ${errors.icProofImageUrl ? "errorBorder" : ''}`}>
                                            <label htmlFor="uploadSelfie">
                                                <div className="text-center">
                                                    {img.icProofImageUrl ? (
                                                        <div className="py-2">
                                                            <img src={replaceUrl(img.icProofImageUrl)} className="img-fluid document" alt="document" />
                                                        </div>
                                                    ) : (
                                                        <img src={require('../../../images/kyc/icselfie.png').default} className="img-fluid" alt="document"/>
                                                    )}
                                                </div>

                                            </label>
                                            <input type={kycStatus ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? "file" : "" : "file"} id="uploadSelfie" className=" d-none" onChange={(e) => uploadImage(e, "icProofImageUrl")} />
                                        </div>
                                        <div className="text-start mt-4 mt-md-3 pb-4 pb-md-0">
                                            <span className={`font-weight-500 font-18 text-grey-1`}>{translate(props.lang, "Upload Photo with Holding of IC/ Passport")}</span>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.icProofImageUrl)}</span>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <div className={`card-document text-center p-4 ${errors.bankImageUrl ? "errorBorder" : ''}`}>
                                            <label htmlFor="uploadDocument">
                                                <div className="text-center">
                                                    {img.bankImageUrl ? (
                                                        <div className="py-2">
                                                            <img src={replaceUrl(img.bankImageUrl)} className="img-fluid document" alt="document" />
                                                        </div>
                                                    ) : (
                                                        <img src={require('../../../images/kyc/doc.png').default} className="img-fluid" alt="document" />
                                                    )}
                                                </div>
                                            </label>
                                            <input type={kycStatus ? (kycStatus === 'Pending' || kycStatus === 'Rejected') ? "file" : "" : "file"} id="uploadDocument" className=" d-none" onChange={(e) => uploadImage(e, "bankImageUrl")} />
                                        </div>
                                        <div className="text-start mt-4 mt-md-3 pb-4 pb-md-0">
                                            <span className={`font-weight-500 font-18 text-grey-1`}>{translate(props.lang, "Upload Proof of Address (Utility Bill/ Phone Bill/ Bank Slip)")}</span>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang,errors.bankImageUrl)}</span>
                                    </Col>
                                </Row>
                                {kycStatus && (kycStatus === 'Pending' || kycStatus === 'Rejected') ? (
                                <div className="text-center form-group mb-3 mt-5">
                                    <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={submitForm} disabled={loading}>
                                        {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Submit")}
                                    </button>
                                </div>
                                ):null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(KYC);
