import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { api } from '../../utils/api';
import swal from "sweetalert";
import { translate } from '../../utils/translate';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export function signupAction(data, history, lang) {

}

// export function logout(history) {
//     localStorage.removeItem('cgi-crm');
//     history.push('/login');
//     return {
//         type: LOGOUT_ACTION,
//     };
// }

export function logout(history, path) {
    localStorage.removeItem('cgi-crm');
    if(path){
        if(path.includes('/login') || path.includes('/register') || path.includes('/email-verify')  || path.includes('/thankyou') || path.includes('/forgot-password')){
        }else{
            history.push('/login');
        }
    }else{
        history.push('/login');
    }
    
    
    return {
        type: LOGOUT_ACTION,
    };
}

// export function loginAction(email, password, history, lang) {
//     return (dispatch) => {
//         login(email, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data);
//                 runLogoutTimer(
//                     dispatch,
//                     response.data.expiresIn * 1000,
//                     history,
//                 );
//                 dispatch(loginConfirmedAction(response.data));
// 				history.push('/dashboard');                
//             })
//             .catch((error) => {
// 				//console.log(error);
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }

export function loginAction(email, password, history, lang) {
    return (dispatch) => {
        let data = {
            "username": email,
            "password": password,
            "type" : "TH_GROUP"
        };
        fetch(api.authenticate, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json", 
                "Content-Language": lang
            }),
            body: JSON.stringify(data)
        })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0]){
                let userInfo = result;
                saveTokenInLocalStorage(userInfo);
                runLogoutTimer(
                    dispatch,
                    3600 * 1000,
                    history,
                );
                dispatch(loginConfirmedAction(userInfo));
                history.push('/dashboard');  
            }else{
                swal(translate(lang,'Oops'), result.message, "error");
                // const errorMessage = formatError(responseJson.message);
                const errorMessage = result.message;
                dispatch(loginFailedAction(errorMessage));
                dispatch(loadingToggleAction(false))
            }
        }).catch(error => {
            console.error("error",error);
        });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
