let en = null;
let zh_cn = null;

export let translate = (lang, key) => {
    switch (lang) {
        case "EN":
            if (!en) {
                en = require("../language/en").default;
            }
            if(en[key]){
                return en[key];
            }else{
                return key;
            }
            
        case "ZH":
            if (!zh_cn) {
                zh_cn = require("../language/zh_cn").default;
            }
            if(zh_cn[key]){
                return zh_cn[key];
            }else{
                return key;
            }
        default:
            if (en === null) {
                en = require("../language/en").default;
            }
            if(en[key]){
                return en[key];
            }else{
                return key;
            }
            // return en[key];
    }
};