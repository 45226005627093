import React from "react";
import { connect } from "react-redux";
import { Col, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import "../../../css/setting.scss";
import { translate } from "../../../utils/translate";
import { FaAngleRight } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";

const Deposit = (props) => {
    return (
        <>
            <Row className="setting mt-3 mt-md-0">
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mt-3 mt-md-0">
                    <Link to="deposit/crypto">
                        <div className={`card-black card`}>
                            <Row className="justify-content-center align-items-center px-4 py-3 py-md-5">
                                <Col lg={12} md={12} sm={5} xs={5}>
                                    <div>
                                        <img src={require("../../../images/setting/crypto.png").default} className="img-fluid setting-img" />
                                    </div>
                                </Col>
                                <Col>
                                    <Row className="align-items-center justify-content-center mt-0 mt-md-2">
                                        <Col lg={9} md={9} sm={8} xs={8} className="px-0 px-md-2">
                                            <div className="ms-0  ms-md-3 mt-0 mt-md-1">
                                                <span className={`settingText font-weight-500`}>{translate(props.lang, "Crypto Deposit")}</span>
                                            </div>
                                        </Col>
                                        <Col className="text-end">
                                            <FaAngleRight className="fa-2x  mb-1 mb-md-0" color="000063" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>
                {/* <Col lg={4} md={4} sm={12} xs={12} className="mb-2">
                    <Link to="setting/change-email">
                        <div className={`card-black card`}>
                            <Row className="justify-content-center align-items-center px-4 py-4">
                                <Col lg={12} md={12} sm={5} xs={5}>
                                    <div>
                                        <img src={require("../../../images/setting/email.png").default} className="img-fluid setting-img" />
                                    </div>
                                </Col>
                                <Col>
                                    <Row className="align-items-center justify-content-center mt-3">
                                        <Col lg={9} md={9} sm={7} xs={7}>
                                            <span className={`settingText font-weight-500 font-18`}>{translate(props.lang, "Set Email Address")}</span>
                                        </Col>
                                        <Col>
                                            <FaAngleRight className="fa-2x" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col> */}
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <Link to="deposit/fiat">
                        <div className={`card-black card`}>
                            <Row className="justify-content-center align-items-center px-4 py-3 py-md-5 ">
                                <Col lg={12} md={12} sm={5} xs={5}>
                                    <div>
                                        <img src={require("../../../images/setting/fiat.png").default} className="img-fluid setting-img" />
                                    </div>
                                </Col>
                                <Col>
                                    <Row className="align-items-center justify-content-center mt-0 mt-md-2">
                                        <Col lg={9} md={9} sm={8} xs={8} className="px-0 px-md-2">
                                            <div className="ms-0 ms-md-3 mt-0 mt-md-1">
                                                <span className={`settingText font-weight-500`}>{translate(props.lang, "Fiat Deposit")}</span>
                                            </div>
                                        </Col>
                                        <Col className="text-end">
                                            <FaAngleRight className="fa-2x arrow mb-1 mb-md-0" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Link>
                </Col>
            </Row>
      
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(Deposit);
