import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { Accordion, Card } from 'react-bootstrap';
import { url, api } from "../../../utils/api";
import ReactPaginate from "react-paginate"
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import Loadingv1 from '../../components/Loading/Loadingv1';


const Status = (props) => {
   const [
      activeaccordionRoundedStylish,
      setActiveaccordionRoundedStylish,
   ] = useState(0);
   const productPerpage = 10;
   const [totalRecord, setTotalRecord] = useState(0);
   const [result, setResult] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [content, setContent] = useState(null);
   const [loading, setLoading] = useState(false);
   useEffect(() => {
    getCampaignStatus();
      // getDialCode(selectedCountry);
  }, [pageNumber]);

  
  const getCampaignStatus = () => {
      setLoading(true);
      fetch(api.event + `/gasFreeEventList?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language": props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
          .then(response => Promise.all([response.ok, response.text()]))
          .then(responseJson => {
              let result = JSON.parse(responseJson[1]);
              if (responseJson[0]) {
                  setContent(result.eventList)  
                  var pageCount = Math.ceil(result.eventList.totalRecords / productPerpage)
                  setPageCount(pageCount);
                  setLoading(false);
                }
                
               else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
                  setLoading(false);
              }
          }).catch(error => {
              console.error("error", error);
          });
  }
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
   }
 

   return (
      <>
         <div className="mt-3">
            <div className="d-none d-md-block">
            <Row className="justify-content-center align-items-center">
               <Col lg={12} md={11} xs={11} sm={11}>
                  <div className="card card-black h-100 py-4 px-4">
                     <div className="card-header align-items-center border-bottom-0">
                        <span className={`headerText text-capitalize`}>{translate(props.lang, "status")}</span>
                     </div>
                     <div className="card-body align-items-center pt-3 pb-3">
                        {loading ? (
                           <Loadingv1/>
                        ):
                        <Table responsive className="w-100 investHistory">
                           <thead>
                              <tr>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Name")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Date And Time")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "status")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Remark")}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {content && content.gasFreeEventDtoList.map((item, i) => (
                                 <tr key={i}>
                                    <td className={`investHistory-txt font-weight-400 font-weight-400 font-12`}>{item.name}</td>
                                    <td className={`investHistory-txt font-weight-400 font-weight-400 font-12 text-capitalize`}>{item.registerDate}</td>
                                    <td className={`investHistory-txt font-weight-400 font-weight-400 font-12 status ${item.status.toLowerCase()}`}>{item.status}</td>
                                    <td className={`investHistory-txt font-weight-400 font-weight-400 font-12`}>{item.remark}</td>
                                 </tr>
                              ))}

                              {content && content.gasFreeEventDtoList.length === 0 ? (
                              <tr>
                                 <td colspan="4" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                              </tr>
                           ) : null}
                           </tbody>
                        </Table>
                        }
                     </div>
                     <div>
                        {content && content.gasFreeEventDtoList.length > 0 ? (
                           <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                              previousLinkClassName={"pagination__previouslink text-blue-1"}
                              nextLinkClassName={"pagination__nextlink text-blue-1"}
                              disabledClassName={"pagination__link--disabled text-muted"}
                              activeClassName={"pagination__link--active text-white"}

                           />
                        ):null}
                  </div>
                  </div>
                  
               </Col>
            </Row>
            </div>


            <div className="d-block d-md-none">
            {loading ? (
               <Loadingv1/>
            ):
            <>
            <div className="card cardBg">
               <div className="card-body">
                  <Row className="justify-content-center align-items-center" id="gas">
                     <Col>
                        <span className="headerText ms-2">{translate(props.lang, "status")}</span>
                     </Col>
                  
                  
                     <Accordion
                        className="accordion accordion-bordered rounded-0 deposit-accordion mt-4"
                        defaultActiveKey="0"
                  >
                        {content && content.gasFreeEventDtoList.map((d, i) => (
                           <div className="accordion-item cardBgMobile" key={i}>
                           <Accordion.Toggle
                              as={Card.Text}
                              eventKey={`${i}`}
                              className={`accordion-header ${
                              activeaccordionRoundedStylish === i ? "" : "collapsed"
                              } accordion-header-primary`}
                              onClick={() =>
                              setActiveaccordionRoundedStylish(
                                    activeaccordionRoundedStylish === i ? -1 : i
                              )
                              }
                           >
                              <span className="accordion-header-icon"></span>
                              <Row className="justify-content-start">
                                       <Col xs={5} className="text-start pb-2">
                                          <span className="investmentDetailHeader-label">{translate(props.lang, "Name")}</span>
                                       </Col>
                           
                                       <Col xs={5} className="text-start">
                                          <span className="investmentDetailHeader-label">{translate(props.lang, "status")}</span>
                                       
                                       </Col>
                                       
                                       
                                    </Row>
                                    <Row className="justify-content-start">
                                       <Col xs={5} className="text-start pb-2">                        
                                          <span className="investmentDetailHeader-value">{d.name}</span>
                                       </Col>
                                       
                                       <Col xs={5} className="text-start">
                                          <span className={`investmentDetailHeader-value ${d.status}`}>{d.status}</span>                          
                                       </Col>
                                    
                                    </Row>
                                    <Col xs={2}>
                                       <span className="accordion-header-indicator ">{activeaccordionRoundedStylish === i ? (
                                                <img src={require('../../../images/common/up.png').default} className="img-fluid arrow float-end" />
                                          ) : ( <img src={require('../../../images/common/down.png').default} className="img-fluid arrow float-end" />)}</span>
                                       </Col>
                           </Accordion.Toggle>
                           <Accordion.Collapse
                              eventKey={`${i}`}
                              className="accordion__body"
                           >
                              
                              <div className="accordion-body-text" style={{paddingTop: 0}}>
                                    <Row className="justify-content-center align-items-start mt-3">
                                                <Col sm={12} xs={12} className="mb-3">
                                                   <span className="investmentDetailBody-label">{translate(props.lang, "Remark")}</span>
                                                   
                                                </Col>
                                                <Col sm={12} xs={12} className="mb-3">
                                                   <span className="investmentDetailBody-value">{d.remark}</span>
                                                                                            
                                                </Col>         
                                                <Col sm={12} xs={12} className="mb-3">
                                                   <span className="investmentDetailBody-label">{translate(props.lang, "Date And Time")}</span>           
                                                </Col>
                                                <Col sm={12} xs={12} className="mb-3">
                                                <span className="investmentDetailBody-value">{d.registerDate}</span>
                                                
                                                </Col>                                     
                                       </Row>       
                              </div>
                           </Accordion.Collapse>
                           </div>
                           
                        ))}

                     {content && content.gasFreeEventDtoList.length === 0 ? (
                        <div className="ms-2">
                           <span className={`font-weight-400 font-16`}>{translate(props.lang, "No result found")}</span>
                     </div>
                     ):null}
                     
                     {content && content.gasFreeEventDtoList.length > 0 ? (
                     <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                        previousLinkClassName={"pagination__previouslink text-blue-1"}
                        nextLinkClassName={"pagination__nextlink text-blue-1"}
                        disabledClassName={"pagination__link--disabled text-muted"}
                        activeClassName={"pagination__link--active text-white"}

                     />
                  ):null}                 
                     
                  </Accordion>
               
                  </Row>
                  </div>
            </div>
         <div>
      
         </div>
         </>
         }
      </div>
         </div>
      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(Status);
