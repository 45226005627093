import React,{useState,useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { numberWithCommas } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import ComingSoon from "../ComingSoon";



const Performance = (props) => {
   const [imageIndex, setImageIndex] = useState(0)
   const [teamPerformance,setTeamPerformance] = useState(null)
   const carousel1 = [
      require('../../../images/team/graph.png').default,
      require('../../../images/team/levelBonusGraph.png').default,
      require('../../../images/team/subsribeBonusGraph.png').default,
      require('../../../images/team/teamLeaderGraph.png').default,
   ]
   const getTeamPerformance = (e) => {
      fetch(api.team + '/teamPerformance', {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language" : props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
      .then(response => response.json())
      .then(responseJson => {
         setTeamPerformance(responseJson);
      }).catch(error => {
          console.log("Error:", error);
      });
    }
    useEffect(() => {
      getTeamPerformance();
   }, [props.auth]);

   const nextImage = (i) =>{
      i++;
      if(i===4){
         setImageIndex(0);
      }
      else{
         setImageIndex(i);
      }
   
   }

   const prevImage = (i) =>{
      i--;
      if(i===-1){
         setImageIndex(3);
      }
      else{
         setImageIndex(i);
      }
    
      
     
     
   }
   return (
      <>
         <ComingSoon></ComingSoon>
      </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Performance);
