import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row,Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import "../../../css/wallet.scss";
import { translate } from "../../../utils/translate";
import { numberWithCommas } from "../../../utils/function";
import Select from "react-select";
import { url, api } from "../../../utils/api";
import swal from "sweetalert";
import ComingSoon from "../ComingSoon";
// import OtpTimer from "otp-timer";
import LiveSupport from '../../components/Live/LiveSupport';
import { FaSpinner } from "react-icons/fa";
const customStyles = {
    control: (base, state) => ({
        ...base,
        background: "transparent",
        border: "none",
        boxShadow: state.isFocused ? null : null,
        color: "#000063",
        marginBottom: "10px"

    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        //   background: "linear-gradient(180deg, rgba(20, 73, 78, 0.8) 50%, rgba(20, 73, 78, 0.9) 100%)",
        backdropFilter: "blur(100px)",
        color: "black",
        borderRadius: "14px",
        backgroundColor:"#EBEBEB",
        left: 3,
        marginTop: 3
    }),
    menuList: (base) => ({
        ...base,
        padding: 0
    }),
    singleValue: (base) => ({
        ...base,
        color: "#000063",
        top: window.innerWidth > 667 ? "58%" : "50%",
        lineHeight:"30px"
    })
};


const Withdrawal = (props) => {
    const [successModal, setSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [password, setPassword] = useState('');
    let errorsObj = { transferFrom: '', transferTo: '', amount: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [successData, setSuccessData] = useState('');
    const [transferFrom, setTransferFrom] = useState('');
    const [transferTo, setTransferTo] = useState('')
 
    const submit = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (transferFrom === '') {
            errorObj.transferFrom = translate(props.lang, "This field is required");
            error = true;
        }
        if (transferTo === '') {
            errorObj.transferTo = translate(props.lang, "This field is required");
            error = true;
        }
        if (transferFrom === transferTo) {
            errorObj.transferFrom = translate(props.lang, "Unable Transfer To Same Wallet");
            error = true;

        }
        if (amount === '') {
            errorObj.amount =  translate(props.lang, "This field is required");
            error = true;
        }
        if (password === '') {
            errorObj.password = translate(props.lang, "This field is required");
            error = true;
        }
      
        setErrors(errorObj);
        if (error) return;
        setLoading(true);
        let data = {
            "amount": amount,
            "transactionPassword": password,
            "from" : transferFrom.value,
            "to" : transferTo.value
        }
        fetch(api.wallet + '/topupGasFee', {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setAmount('');
                    setPassword('');
                    setSuccessData(result);
                    setSuccessModal(true);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }

            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const closeSuccessModal = () => {
        setSuccessModal(false);
    }
    const Option = [
        { value: "USDT", label: 'USDT' },
        { value: 'GF_AUTO', label: 'GF_AUTO' },
        { value: 'GF_MANUAL', label: 'GF_MANUAL' }
    ]

    

    return (
        <>
            <Row className="justify-content-center mt-3 mt-md-0 pb-5  pb-0-md" id="internalTransfer">
            <Col lg={12} md={12} sm={12} xs={12}>
                <div className="card card-bx cardBg h-100 py-4 transfer ">
                <div className="card-header align-items-center ms-4 border-bottom-0">
                    <span className={`font-weight-600 font-22 text-capitalize text-blue-1`}>{translate(props.lang, "Internal Transfer")}</span>
                 </div>
                    <div className="card-body align-items-center pt-0 pb-3">
                      
                        <Row className="justify-content-center align-items-start h-100 vertical-divider">
                               
                            <Col lg={12}>
                                <div className="px-4">
                                    <Row className="justify-content-center h-100  mt-4">
                                        <Col lg={6} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group" id="selectWithdrawal">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Transfer From")}</span>
                                                </label>
                                                <div className={`input-border mt-1 ${errors.transferFrom ? 'errorBorder' : ''}`}>
                                                    <Select
                                                        options={Option}
                                                        value={Option && Option.filter(option => option.label === transferFrom.label)}
                                                        onChange={(e) => setTransferFrom(e)}
                                                        placeholder={translate(props.lang, "PLEASE SELECT")}
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        styles={customStyles}
                                                        
                                                    />
                                                </div>
                                                <span className="error">{translate(props.lang, errors.transferFrom)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Transfer To")}</span>
                                                </label>
                                                <div className={`input-border mt-1 ${errors.transferTo ? 'errorBorder' : ''}`}>
                                                    <Select
                                                        options={Option}
                                                        value={Option && Option.filter(option => option.label === transferTo.label)}
                                                        onChange={(e) => setTransferTo(e)}
                                                        placeholder={translate(props.lang, "PLEASE SELECT")}
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        styles={customStyles}
                                                       
                                                    />
                                                </div>
                                                <span className="error">{translate(props.lang, errors.transferTo)}</span>
                                            </div>
                                        </Col>
                                        {/* <div className="it-txt text-end d-none d-md-block">{translate(props.lang,"Total amount:")} 1000.00</div> */}
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <div className="d-block d-md-none">
                                                    <label className="mb-1 ">
                                                        <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "amount")}</span>
                                                    </label>
{/* 
                                                    <span className="it-txt ms-5">{translate(props.lang,"Total amount:")} 1000.00</span> */}
                                                </div>

                                                <div className="d-none d-md-block">
                                                    <label className="mb-1 ">
                                                        <span className={`font-weight-500 font-16 text-black-2 text-capitalize`}>{translate(props.lang, "amount")}</span>
                                                    </label>

                                                  
                                                </div>
                                            
                                                <div className={`input-group mt-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="text"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Enter Amount")}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        value={amount}
                                                        autoComplete="new-password"
                                                    />
                                                    <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid pencil-icon" />
                                                    </div>
                                                </div>
                                                <span className="error">{translate(props.lang, errors.amount)}</span>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xs={12} sm={12} className="mb-4">
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Transaction Password")}</span>
                                                </label>
                                                <div className={`input-group mt-1 ${errors.password ? 'errorBorder' : ''}`}>
                                                    <input
                                                        type="password"
                                                        className="form-control input-transparent font-weight-400 font-16"
                                                        placeholder={translate(props.lang, "Enter Your Transaction Password")}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={password}
                                                        autoComplete="new-password"
                                                    />
                                                    <div className="px-3">
                                                        <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid pencil-icon" />
                                                    </div>
                                                </div>

                                                <span className="error">{translate(props.lang, errors.password)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-end align-items-center mt-4">
                                        <Col lg={12} md={5} sm={12} xs={12} className="mb-2">
                                            <button className="btn primary-btn btn-block font-weight-400 font-16" onClick={(e) => submit(e)} disabled={loading}>
                                                {loading ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Confirm")}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        
                        
                    </div>
                </div>
            </Col>
            </Row>
            
      
          
            
            <Modal className="fade successModel" show={successModal} onHide={() => setSuccessModal(false)} centered>
            <div className="position-relative">
            <Modal.Header className="px-2 py-4">
            
                  
               </Modal.Header>
                <Modal.Body className="py-5">
                    <div className="text-center payment-success-container">
                        <img src={require('../../../images/investment/payment_success.png').default} className="img-fluid payment-success-img" />
                    </div>
                    <div className="text-center pt-4 mb-2">
                        <span className="withdraw-subscript-text">{translate(props.lang, "Internal Transfer")}</span>
                    </div>
                    
                    <div className="px-3">
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <span className="investment-label">{translate(props.lang, "USDT Balance")}</span>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                <span className="investment-value">{successData ? successData.usdtBalance : ""}</span>
                            </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-3">
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <span className="investment-label">{translate(props.lang, "GF Balance")}</span>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                <span className="investment-value">{successData ? successData.gfBalance : ""}</span>
                            </Col>
                        </Row>
                       
                    
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="choose-package-btn py-3 text-center" onClick={() => closeSuccessModal()}>
                                    <span className="choose-package-text">{translate(props.lang, "Complete")}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
            </Modal>
       
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Withdrawal);
