import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../../../css/support.scss";
import { FaHeadphones, FaHeadphonesAlt, FaTelegramPlane } from "react-icons/fa";
import { translate } from "../../../utils/translate";

const LiveSupport = (props) => {
    return (
        <div className="support">
            <a href="https://t.me/CrownGlobalInternational" target="_blank">
                <div className="con-tooltip left btn-telegram p-2 d-flex align-items-center justify-content-center">
                    <img src={require('../../../images/common/livesupport.svg').default} className="support-icon" />
                    <div className={`tooltip ${props.lang} text-start`}>
                        <span className="font-weight-400 font-12">{translate(props.lang, "Click Me for Customer Support")}</span>
                    </div>
                </div>
            </a>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
        lang: i18n.lang,
    };
};
export default withRouter(connect(mapStateToProps)(LiveSupport));
