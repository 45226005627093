import React, { useContext, useState, useEffect } from "react";
import {  Switch, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Row, Col, Button } from 'reactstrap';

import "./index.css";
import "./chart.css";
import "./step.css";

import Nav from "./layouts/nav";

import {routes, leader_routes} from "../utils/route";
import { ThemeContext } from "../context/ThemeContext";
import { api } from "../utils/api";
import { translate } from "../utils/translate";
import Dashboard from "./pages/Dashboard/Dashboard";
import ComingSoon from "./pages/ComingSoon";
import LiveSupport from "./components/Live/LiveSupport";
import swal from "sweetalert";

const Markup = (props) => {
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [passwordModal, setPasswordModal] = useState(false);
  const [secondaryPassword, setSecondaryPassword] = useState("");
  const [sendVerifyCode, setSendVerifyCode] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorApi, setErrorApi] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    transactionPassword: '',
    confirmPassword: '',
  });




  const closePasswordModal = () => {
    setSecondaryPassword(null);
    setConfirmPassword(null);
    setVerifyCode(null);
    setSendVerifyCode(false);
    setLoading(false);
    setPasswordModal(false);
  }

    
  // const getUserInfo = () => {
  //   fetch(api.user + '/viewProfile', {
  //     method: "GET",
  //     headers: new Headers({
  //       "Content-Type": "application/JSON",
  //       "Content-Language": props.lang,
  //       "Authorization": "Bearer " + props.auth.auth.token
  //     }),
  //   })
  //   .then(response => Promise.all([response.ok, response.text()]))
  //   .then(responseJson => {
  //     let result = JSON.parse(responseJson[1]);
  //       if (responseJson[0]) {
  //         dispatch({
  //           type: 'UPDATE_PROFILE',
  //           profile: result
  //         });
  //       }
  //       else {
  //         swal(translate(props.lang, 'Error'), responseJson.message, "error");
  //       }
  //     }).catch(error => {
  //       console.error("error", error);
  //     });
  // }

  // const submitPassword = () => {
  //   const errorObj = { ...error };
  //   errorObj.transactionPassword = "";
  //   errorObj.confirmPassword = "";
  //   errorObj.verifyCode = "";
  //   setErrorApi(null)
  //   let check = true;
  //   if(!secondaryPassword){
  //     check = false;
  //     errorObj.transactionPassword = "This field is required.";
  //   }
  //   if(!confirmPassword){
  //     check = false;
  //     errorObj.confirmPassword = "This field is required.";
  //   }
  //   if(!verifyCode){
  //     check = false;
  //     errorObj.verifyCode = "This field is required.";
  //   }
  //   if(secondaryPassword && confirmPassword){
  //     if(secondaryPassword !== confirmPassword){
  //       check = false;
  //       errorObj.confirmPassword = "Transaction password does not match with confirm password.";
  //     }
  //   }
  //   if(check){
  //     setError(errorObj);
  //     setLoading(true)
  //     let data = {
  //       "newTransactionPassword": secondaryPassword,
  //       "confirmTransactionPassword": confirmPassword,
  //       "verifyCode": verifyCode
  //     }
  //     fetch(api.user + '/changeTransactionPassword', {
  //       method: "POST",
  //       headers: new Headers({
  //         "Content-Type": "application/json",
  //         "Content-Language" : props.lang,
  //         "Authorization": "Bearer " + props.auth.auth.token
  //       }),
  //       body: JSON.stringify(data)
  //    })
  //    .then(response => Promise.all([response.ok, response.text()]))
  //    .then(responseJson => {
  //     console.log(responseJson);
  //     setLoading(false)
  //       let result = JSON.parse(responseJson[1]);
  //       if(responseJson[0]){
  //         closePasswordModal();
  //         setSuccessModal(true);
  //       }else{
  //         setErrorApi(result.message)
  //       }
  //    }).catch(error => {
  //          console.log("Error:", error);
  //    });
  //   }else{
  //     setError(errorObj);
  //   }
  // }

  // const sendCode = () => {
  //   setSendVerifyCode(true);
  //   fetch(api.user + '/generateTransactionPasswordOtpEmail', {
  //     method: "POST",
  //     headers: new Headers({
  //       "Content-Type": "application/json",
  //       "Content-Language" : props.lang,
  //       "Authorization": "Bearer " + props.auth.auth.token
  //     }),
  //  })
  //  .then(response => Promise.all([response.ok, response.text()]))
  //  .then(responseJson => {
  //   setLoading(false)
  //     let result = JSON.parse(responseJson[1]);
  //     if(responseJson[0]){
  //       setSendVerifyCode(true);
  //     }else{
  //       setErrorApi(result.message)
  //     }
  //  }).catch(error => {
  //        console.log("Error:", error);
  //  });
  // }

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${ menuToggle ? "menu-toggle" : ""}`}
      >
        {!pagePath && <Nav />}
        <div className={`${!pagePath ? "content-body pb-5" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
                <Route component={ComingSoon} />
              <Route path='/' exact component={Dashboard} />
            </Switch>
           
          </div>
        </div>
       
      </div>
      {/* <LiveSupport /> */}
    </>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(Markup);
