/// Pages
import Dashboard from "../jsx/pages/Dashboard/Dashboard";
import Wallets from "../jsx/pages/Wallets/Wallets";
import Portfolio from "../jsx/pages/Investment/Portfolio";
import NewInvestment from "../jsx/pages/Investment/NewInvestment";
import InvestmentDetails from "../jsx/pages/Investment/InvestmentDetails";

import ChangePassword from "../jsx/pages/Setting/ChangePassword";
import ChangeEmailAddress from "../jsx/pages/Setting/ChangeEmailAddress";
import Setting from "../jsx/pages/Setting/Setting";

import Deposit from "../jsx/pages/Wallets/Deposit";
import WalletHistory from "../jsx/pages/Wallets/WalletHistory";
import Withdrawal from "../jsx/pages/Wallets/Withdrawal";
// import Transfer from "../jsx/pages/Wallets/Transfer";
import GroupInvestment from "../jsx/pages/Team/GroupInvestment";
import Team from "../jsx/pages/Team/Team";
import Referral from "../jsx/pages/Team/Referral";
import Performance from "../jsx/pages/Team/Performance";
import Profile from "../jsx/pages/Profile/Profile";
import KYC from "../jsx/pages/Setting/KYC";
import Language from "../jsx/pages/Language/Language";
import TotalTeamInvestment from "../jsx/pages/Team/TotalTeamInvestment";
// import BonusWallet from "../jsx/pages/Wallets/BonusWallet";
import WalletTransactionHistory from "../jsx/pages/Wallets/WalletTransactionHistory";
import InvestmentWalletDetails from "../jsx/pages/Wallets/InvestmentWalletDetails";
import BonusTransactionHistory from "../jsx/pages/Team/BonusTransactionHistory";
import ChangeTransactionPassword from "../jsx/pages/Setting/ChangeTransactionPassword";
import InternalTransfer from "../jsx/pages/Wallets/InternalTransfer";
import InvestmentAuto from "../jsx/pages/Investment/InvestmentAuto";
import Register from "../jsx/pages/GasFreeCampaign/Register";
import Status from "../jsx/pages/GasFreeCampaign/Status";
import TopupInvestment from "../jsx/pages/Investment/TopupInvestment";
import FiatDeposit from "../jsx/pages/Wallets/FiatDeposit";
import CryptoDeposit from "../jsx/pages/Wallets/CryptoDeposit";
import WithdrawalCrypto from "../jsx/pages/Wallets/WithdrawalCrypto";
import WithdrawalFiat from "../jsx/pages/Wallets/WithdrawalFiat";
import bankDepositHistory from "../jsx/pages/Wallets/bankDepositHistory";



const routes =  [
    /// Dashboard
    { url: "", component: Dashboard, name: "dashboard" },
    { url: "dashboard", component: Dashboard, name: "dashboard" },

    { url: "investment/portfolio", component: Portfolio, name: "investment portfolio" },
    { url: "investment/new", component: NewInvestment, name: "new investment" },
    { url: "investment/auto", component: InvestmentAuto, name: "investment auto" },
    { url: "investment/details", component: InvestmentDetails, name: "investment detail" },
    { url: "investment/cTraderAccount", component: TopupInvestment, name: "top up investment" },
    { url: "setting", component: Setting, name: "setting" },
    { url: "setting/change-password", component: ChangePassword, name: "Change Password" },
    // { url: "setting/change-email", component: ChangeEmailAddress, name: "Change Email Address" },
    { url: "setting/change-transaction-password", component: ChangeTransactionPassword, name: "Change Transaction Password" },
    { url: "setting/kyc", component: KYC, name: "kyc" },

    { url: "wallet", component: Wallets, name: "wallet" },
    { url: "wallet/deposit", component: Deposit, name: "deposit" },
    { url: "wallet/withdrawal", component: Withdrawal, name: "withdrawal" },
    { url: "wallet/transactionHistory/:walletType", component: WalletTransactionHistory, name: "wallet transaction history" },
    // { url: "wallet/transfer", component: Transfer, name: "transfer" },
    { url: "wallet/history", component: WalletHistory, name: "history" },
    { url: "wallet/withdrawal/crypto", component: WithdrawalCrypto, name: "withdrawal crypto" },
    { url: "wallet/withdrawal/fiat", component: WithdrawalFiat, name: "withdrawal fiat" },
    // { url: "wallet/withdrawal", component: WithdrawalFiat, name: "withdrawal fiat" },
        // { url: "wallet/withdrawal", component: Withdrawal2, name: "withdrawal" },
    // { url: "wallet/internalTransfer", component: InternalTransfer, name: "internal transfer" },
   
    { url: "wallet/deposit/crypto", component: CryptoDeposit, name: "crypto deposit" },
    { url: "wallet/deposit/fiat", component: FiatDeposit, name: "fiat deposit" },
    { url: "wallet/bankDepositHistory", component: bankDepositHistory, name: "bank deposit history" },



    

    { url: "team", component: Team, name: "team" },
    { url: "team/team-referral-tree", component: TotalTeamInvestment, name: "total team investment" },
    // { url: "team/group-investment-summary", component: GroupInvestment, name: "group investment summary" },
    { url: "team/referral", component: Referral, name: "referral" },
    // { url: "team/performance", component: Performance, name: "performance" },
    { url: "team/BonusTransactionHistory/:type", component: BonusTransactionHistory, name: "bonus transaction history" },

    // { url: "gasfeecampaign/register", component: Register, name: "register" },
    // { url: "gasfeecampaign/status", component: Status, name: "status" },

    { url: "profile", component: Profile, name: "profile" },
    { url: "language", component: Language, name: "language" },

];
export { routes};