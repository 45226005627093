import React,{useState,useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle.js";
import { Row, Col, Table,Pagination,Card, Nav,PaginationItem,PaginationLink,Button } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import ReactPaginate from "react-paginate";
import { Accordion ,Modal } from "react-bootstrap";
import swal from "sweetalert";
import { set } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import LiveSupport from "../../components/Live/LiveSupport.js";
import Loadingv1 from '../../components/Loading/Loadingv1';
import { FaSpinner,FaTimes,FaChevronLeft, FaChevronRight,FaAngleDoubleLeft } from "react-icons/fa";
const historyData = [
    { mt5ID: '23423546', package: 'StrategyX', equity: 2124, capital: 12678, earnings: 2124 },
    { mt5ID: '86425211', package: 'StrategyX', equity: 19654, capital: 2124, earnings: 19654 },
    { mt5ID: '23568314', package: 'StrategyX', equity: 12678, capital: 32245, earnings: 12678 },
    { mt5ID: '23423546', package: 'ProphetAI', equity: 19654, capital: 7427, earnings: 19654 },
    { mt5ID: '86425211', package: 'ProphetAI', equity: 32245, capital: 2124, earnings: 32245 },
    { mt5ID: '73369663', package: 'StrategyX', equity: 7427, capital: 2124, earnings: 7427 },
]

const InvestmentWalletDetails = (props) => {
   const [
      activeaccordionRoundedStylish,
      setActiveaccordionRoundedStylish,
  ] = useState(0);
   const [history , setHistory] = useState([]);
   const productPerpage = 10
   const [pageNumber,setPageNumber] = useState(1)
   const [withdrawInfo, setWithdrawInfo] = useState('');
   const [withdrawalProfitModal, setWithdrawalProfitModal] = useState(false);
   const [pageCount, setPageCount] = useState(1);
   const [withdrawalAmount, setWithdrawalAmount] = useState(null);
   const [errors, setErrors] = useState({ withdrawalAmount: '' });
   const [secondaryPasswordModal, setSecondaryPasswordModal] = useState(false);
   const [secondaryPassword, setSecondaryPassword] = useState(null);
   const [orderID, setOrderID] = useState(null);
   const [unsubscribeModal, setUnsubscribeModal] = useState(false);
   const [withdrawable,setWithdrawable] = useState(false);
   const [showWithdrawButton, setShowWithdrawButton] = useState(false);
   const [showWithdrawCapitalButton, setShowWithdrawCapitalButton] = useState(false);
   const [showUnsubscribeButton, setShowUnsubscribeButton] = useState(false);
   const [withdrawProfit, setWithdrawProfit] = useState(false);
   const [withdrawCapital, setWithdrawCapital] = useState(false);
   const [unsubscribeInfo, setUnsubscribeInfo] = useState('')
   const [unsubscribe, setUnsubscribe] = useState(false)
   const [showPassword, setShowPassword] = useState(false);
   const [loading, setLoading] = useState(false);
   const [loadingPayment, setLoadingPayment] = useState(false);
   const [unsub,setUnsub] = useState('');
   useEffect(() => {
      getHistory();
     
   }, [pageNumber]);

      const changePage = (event) => {
         setPageNumber(event.selected + 1);
   }
   const closeSuccessModal = () => {
      if(withdrawalAmount){
         setWithdrawalProfitModal(false);
         setSecondaryPasswordModal(true);
      }
      else{
         swal(translate(props.lang, 'Error'), translate(props.lang,"Please enter transfer amount"), "error");
      }
   }
   const setModal = (x,id) => {
      fetch(api.product + `/getInvestmentOrder?orderId=${id}`, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/json",
            "Content-Language" : props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
         })
         .then(response => Promise.all([response.ok, response.text()]))
         .then(responseJson => {         
             let result = JSON.parse(responseJson[1]);
             if(responseJson[0]){
               setWithdrawInfo(result)
               setWithdrawalProfitModal(true)
               setOrderID(id)
               setWithdrawProfit(true)
             }else{
                 swal(translate(props.lang, 'Error'), result.message, "error");
             }
         }).catch(error => {
             console.error("error",error);
         });
   }

   const setModalUnsub = (id) => {
      fetch(api.product + `/getInvestmentOrder?orderId=${id}`, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/json",
            "Content-Language" : props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
         })
         .then(response => Promise.all([response.ok, response.text()]))
         .then(responseJson => {         
             let result = JSON.parse(responseJson[1]);
             if(responseJson[0]){
               setUnsubscribeModal(true);
               setUnsubscribeInfo(result)
               setOrderID(id)
             }else{
                 swal(translate(props.lang, 'Error'), result.message, "error");
             }
         }).catch(error => {
             console.error("error",error);
         });
    
   }
   const getHistory = (e) => {
      setLoading(true);
      fetch(api.wallet + `/investmentWalletList?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language" : props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
          let result = JSON.parse(responseJson[1]);
          if(responseJson[0]){
            setHistory(result.investmentWalletList.productOrderDtoList);
            setWithdrawable(result)
            var pageCount = Math.ceil(result.investmentWalletList.totalRecords / productPerpage)
            setPageCount(pageCount);
            result.investmentWalletList.productOrderDtoList.map((item,i) => {
               if(item.showUnsubscribeButton){
                  setShowUnsubscribeButton(true)
               }
               if(item.showWithdrawCapitalButton){
                  setShowWithdrawCapitalButton(true)
               }
               if(item.showWithdrawButton){
                  setShowWithdrawButton(true)
               }
            })
           
            setLoading(false);
         }
      }).catch(error => {
          console.log("Error:", error);
      });
    }

    const setCapitalModal = (id) =>{
         setWithdrawCapital(true)
         setOrderID(id)
         setSecondaryPasswordModal(true)  
         setWithdrawProfit(false)
         setUnsubscribe(false);
    }

    const confirmPassword = () =>{
      if(withdrawProfit){
         setLoadingPayment(true);
         if(secondaryPassword){
            fetch(api.product + `/ctrader/packageWithdrawal?orderId=${orderID}&withdrawAmount=${withdrawalAmount}&transactionPassword=${secondaryPassword}`, {
               method: "POST",
               headers: new Headers({
                  "Content-Type": "application/JSON",
                  "Content-Language" : props.lang,
                  "Authorization": "Bearer " + props.auth.auth.token
               }),
               })
               .then(response => Promise.all([response.ok, response.text()]))
               .then(responseJson => {         
                  let result = JSON.parse(responseJson[1]);
                  if(responseJson[0]){
                     setLoadingPayment(false);
                     swal('Success', result.message, "success");
                     setOrderID('')
                     setSecondaryPasswordModal(false)
                     setWithdrawalAmount('')
                     getHistory();
                     setWithdrawProfit(false);
                  }else{
                     setLoadingPayment(false);
                     swal(translate(props.lang, 'Error'), result.message, "error");               
                  }
               }).catch(error => {
                  console.error("error",error);
               });
              
         }else{
            setLoadingPayment(false);
            swal(translate(props.lang, "Error"), translate(props.lang, "Please enter secondary password."), "error");
         }
    }
    else if(withdrawCapital){
      if(secondaryPassword){
         setLoadingPayment(true);
         let data = {
            "orderId" : orderID,
            "transactionPassword" : secondaryPassword
         }
         fetch(api.product + `/ctrader/withdrawCapital`, {
            method: "POST",
            headers: new Headers({
               "Content-Type": "application/json",
               "Content-Language" : props.lang,
               "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
            })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {         
                let result = JSON.parse(responseJson[1]);
                if(responseJson[0]){
                  swal('Success', result.message, "success");
                  setOrderID('')
                  setSecondaryPasswordModal(false)
                  getHistory();
                  setLoadingPayment(false);
                  setWithdrawCapital(false)
                }else{
                    setLoadingPayment(false);
                    swal(translate(props.lang, 'Error'), result.message, "error");
                 
                }
                
            }).catch(error => {
                console.error("error",error);
            });
           
      }else{
         setLoadingPayment(false);
         swal(translate(props.lang, "Error"), translate(props.lang, "Please enter secondary password."), "error");
      }
    }
    else if(unsubscribe){
      if(secondaryPassword){
         setLoadingPayment(true);
         let data = {
            "orderId" : orderID,
            "transactionPassword" : secondaryPassword
         }
         fetch(api.product + `/unsubscribe`, {
            method: "POST",
            headers: new Headers({
               "Content-Type": "application/json",
               "Content-Language" : props.lang,
               "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
            })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {         
                let result = JSON.parse(responseJson[1]);
                if(responseJson[0]){
                  swal('Success', result.message, "success");
                  setOrderID('')
                  setSecondaryPasswordModal(false)
                  getHistory();
                  setLoadingPayment(false);
                  setUnsubscribe(false)
                }else{
                    setLoadingPayment(false);
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
                
            }).catch(error => {
                console.error("error",error);
            });      
      }else{
         setLoadingPayment(false);
         swal(translate(props.lang, "Error"), translate(props.lang, "Please enter secondary password."), "error");
      }
   }
   }


   const setSuccessUnsub = () => {
      setUnsubscribeModal(false)
      setSecondaryPasswordModal(true)
      setUnsubscribe(true)
      
   }

   const setProfit = (item) => {
      setWithdrawProfit(true);
      setOrderID(item.orderId);
      setWithdrawInfo(item)
      setWithdrawalProfitModal(true);
      setWithdrawCapital(false)
      setUnsubscribe(false);
   }

   const setUnsubModal = (item) => {
      setUnsubscribeModal(true);
      setOrderID(item.orderId);
      setUnsub(item);
      setWithdrawProfit(false);
      setWithdrawCapital(false)
   
   }

   return (
      <>
      <div className="d-none d-md-block">
         
         <Row className="justify-content-center align-items-center mt-3">
            <Col lg={12} md={12} sm={12} xs={12}>
               <div className="card card-bx cardBg h-100">
                  <div className="card-header align-items-center border-bottom-0">
                     <span className="announcement-header px-2 py-2">{translate(props.lang,"investment wallet details")}</span>
                  </div>
                  {loading ? (
                     <Loadingv1/>
                  ):
                  <div className="card-body align-items-center pt-0 pb-3">
                     <Table responsive className="w-100 investHistory">
                           <thead>
                              <tr>
                                 <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "cTrader ID")}</th>
                                 <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "package")}</th>
                                 <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "Capital")}</th>
                                 <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "Earnings")}</th>
                                 <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "")}</th>
                              </tr>
                           </thead>
                           <tbody>
                           {history && history.map((item, i)=>(
                              <tr>
                                 <td className="investHistory-txt text-center font-weight-400 font-12 py-2">{item.cTraderId}</td>
                                 <td className="investHistory-txt text-center font-weight-400 font-12 py-2">{item.productName}</td>
                                 <td className="investHistory-txt text-center font-weight-400 font-12 py-2">{item.capital}</td>
                                 <td className="investHistory-txt text-center font-weight-400 font-12 py-2">{item.earning}</td>
                                 <td className="investHistory-txt text-center font-weight-400 font-12 py-2">
                                    <div className="d-flex">
                                       {item.showWithdrawCapitalButton ? (
                                       <div className="withdrawal-capital-btn btn-responsive text-center py-2 px-2" onClick={(e)=>setCapitalModal(item.orderId)}>
                                          <span className="withdrawal-btn-capital-text">{translate(props.lang,"Withdraw Capital")}
                                          </span>
                                       </div>
                                    ):null}  
                                       {item.showWithdrawButton ? (
                                       <div className="withdrawal-btn btn-responsive text-center py-2 px-2 " onClick={() => setProfit(item)}>
                                       <span className="withdrawal-btn-text">{translate(props.lang,"Transfer")}
                                          </span>
                                       </div>
                                    ):null} 
                                    {item.showUnsubscribeButton ? ( 
                                       <div className="unsubsribe-btn btn-responsive text-center  py-2 px-2 " onClick={(e)=> setUnsubModal(item)}> <span className="unsubsribe-btn-text">{translate(props.lang,"Unsubscribe")}</span></div>
                                   ):null} 
                                    </div></td>
                                  
                              </tr>
                              
                           ))}
                             {history && history.length === 0 ? (
                                 <tr>
                                    <td colspan="6" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                                 </tr>
                               ):null}

                    
                           </tbody>
                     </Table>
                              
                     <Modal className="fade successModel" show={withdrawalProfitModal} centered>
                     <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => { setWithdrawalProfitModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Modal.Body className="py-4 mt-5">
                  <div className="text-center">
                     <span className="withdraw-title">{translate(props.lang,"Transfer")}</span>
                  </div>
                  <div className="px-3">
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "Available Balance")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{withdrawInfo ? withdrawInfo.earning : ""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-3">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "Investment Package")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{withdrawInfo ? withdrawInfo.productName : ""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-3">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "cTrader ID")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{withdrawInfo ? withdrawInfo.cTraderId : ""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center pt-2 mt-4">
                        <span className="withdrawal-amount-title">{translate(props.lang, "transfer amount")}</span>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-2">
                              <div className="input-group">
                              <input
                                 type="text"
                                 className="form-control input"
                                 placeholder={translate(props.lang,"Enter Transfer Amount")}
                                 value={withdrawalAmount}
                                 onChange={(e) => setWithdrawalAmount(e.target.value)}
                              />
                              <button className="showTextBtn px-3">
                                    <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                    <span className=" d-none d-lg-inline-block d-md-inline-block"></span>
                              </button>
                           </div>
                           <span className="error">{errors.withdrawalAmount}</span>
                        </Col>
                     </Row>
                     {/* <span className="withdrawal-req">{translate(props.lang,"*Withdraw will be charged 3% of total amount")}</span> */}
                     <Row className="justify-content-center align-items-center mt-4">    
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <div className="back-home-btn py-3 text-center" onClick={() => setWithdrawalProfitModal(false)} >
                              <span className="back-home-text">{translate(props.lang,"Cancel")}</span>
                           </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <div className="choose-package-btn py-3 text-center" onClick={()=>closeSuccessModal()}>
                              <span className="choose-package-text">{translate(props.lang,"Confirm")}</span>
                           </div>
                        </Col>
                     </Row>
                     {/* <div className="mt-3">
                        <div className="withdraw-tc text-center text-md-start pb-2">{translate(props.lang,"Withdraw T&C")}</div>
                        <div className="withdraw-tcdesc">{translate(props.lang,"CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.")}</div>
                     </div> */}
                  </div>
               </Modal.Body>
            </Modal>


            <Modal className="fade secondaryPasswordModal" show={secondaryPasswordModal} centered>
               <Modal.Header className="px-2 py-4">
               <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => { setSecondaryPasswordModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
               </Modal.Header>
               <Modal.Body className="py-3">
                  <div className="text-center">
                  <span className="secPassword-text">{translate(props.lang,"Transaction Password")}</span>
                  </div>
                  <div className="text-center pt-4">
                  
                        <div className="input-group">
                           <input type={showPassword ? 'text' : 'password'} className="form-control input" placeholder={translate(props.lang,"Enter Transaction Password")} onChange={(e)=>setSecondaryPassword(e.target.value)} />
                           {
                              showPassword ? (
                                 <button className="showTextBtn px-3" onClick={() => setShowPassword(false)}>
                                 <FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                                 </button>
                              ) : (
                                 <button className="showTextBtn px-3" onClick={() => setShowPassword(true)}>
                                 <FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                                 </button>
                              )         
                           }                             
                     
                     </div> 
                  </div>             
                  <div className="text-center pt-4 pb-5">
                     <button className="choose-package-btn py-2 text-center w-100" onClick={(e) => confirmPassword()} disabled={loadingPayment}>
                        <span className="choose-package-text">{loadingPayment ? <FaSpinner className="fa-spin" /> : translate(props.lang,"Confirm")}</span>
                     </button>
                  </div>
               </Modal.Body>
            </Modal>



            <Modal className="fade unsubModal" show={unsubscribeModal} centered>
               <Modal.Header className="py-4">
               <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => { setUnsubscribeModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
               </Modal.Header>
               <Modal.Body className="py-3">
                  <div className="text-center">
                  <span className="unsub-text">{translate(props.lang,"Unsubscribe Package")}</span>
                  </div>
                  <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="unsub-label">{translate(props.lang, "Investment Package")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="unsub-value">{unsub ? unsub.productName :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-3">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="unsub-label">{translate(props.lang, "cTrader ID")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="unsub-value">{unsub ? unsub.cTraderId : ""}</span>
                        </Col>
                     </Row>
                  <div className="text-center pb-5">
                     <Row className="justify-content-center align-items-center mt-4">    
                           <Col lg={6} md={6} sm={6} xs={6}>
                              <div className="back-home-btn py-3 text-center cursor-pointer" onClick={() => setUnsubscribeModal(false)} >
                                 <span className="back-home-text">{translate(props.lang,"Cancel")}</span>
                              </div>
                           </Col>
                           <Col lg={6} md={6} sm={6} xs={6}>
                              <div className="choose-package-btn py-3 text-center" onClick={()=>setSuccessUnsub()}>
                                 <span className="choose-package-text">{translate(props.lang,"Confirm")}</span>
                              </div>
                        </Col>
                     </Row>
                  </div>
               </Modal.Body>
            </Modal>
                     
               
                  </div>
                  
                  }
                  <div className="pb-3">
                     {history && history.length > 0 ? (
                        <ReactPaginate
                           previousLabel={"<"}
                           nextLabel={">"}
                           pageCount={pageCount}
                           onPageChange={changePage}
                           containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                           previousLinkClassName={"pagination__previouslink text-blue-1"}
                           nextLinkClassName={"pagination__nextlink text-blue-1"}
                           disabledClassName={"pagination__link--disabled text-muted"}
                           activeClassName={"pagination__link--active text-white"}

                        />
                     ):null}
               
                </div>
             
               </div>
               
              
            </Col>
         </Row>
         
      </div>
      <div className="d-block d-md-none">
      {loading ? (
                     <Loadingv1/>
      ):
      <>
      <div className="card cardBg">
         <div className="card-body">
            <Row className="justify-content-center align-items-center" id="gas">
               <Col>
                  <span className="announcement-header ms-2">{translate(props.lang, "investment wallet details")}</span>
               </Col>
            
            
               <Accordion
                  className="accordion accordion-bordered rounded-0 deposit-accordion mt-4"
                  defaultActiveKey="0"
            >
                  {history && history.map((d, i) => {
                     return (
                        <div className="accordion-item cardBgMobile" key={i}>
                           <Accordion.Toggle
                              as={Card.Text}
                              eventKey={`${i}`}
                              className={`accordion-header ${activeaccordionRoundedStylish === i ? "" : "collapsed"} accordion-header-primary`}
                              onClick={() => setActiveaccordionRoundedStylish(
                                 activeaccordionRoundedStylish === i ? -1 : i
                              )}
                           >
                              <span className="accordion-header-icon"></span>
                              <Row className="justify-content-start">
                                 <Col xs={5} className="text-start pb-2">
                                    <span className="investmentDetailHeader-label">{translate(props.lang, "cTrader ID")}</span>
                                 </Col>

                                 <Col xs={5} className="text-start">
                                    <span className="investmentDetailHeader-label text-capitalize">{translate(props.lang, "package")}</span>

                                 </Col>


                              </Row>
                              <Row className="justify-content-start">
                                 <Col xs={5} className="text-start pb-2">
                                    <span className="investmentDetailHeader-value">{d.cTraderId}</span>
                                 </Col>

                                 <Col xs={5} className="text-start">
                                    <span className={`investmentDetailHeader-value`}>{d.productName}</span>
                                 </Col>

                              </Row>
                              <Col xs={2}>
                                 <span className="accordion-header-indicator ">{activeaccordionRoundedStylish === i ? (
                                    <img src={require('../../../images/common/up.png').default} className="img-fluid arrow float-end" />
                                 ) : (<img src={require('../../../images/common/down.png').default} className="img-fluid arrow float-end" />)}</span>
                              </Col>
                           </Accordion.Toggle>
                           <Accordion.Collapse
                              eventKey={`${i}`}
                              className="accordion__body"
                           >

                              <div className="accordion-body-text" style={{ paddingTop: 0 }}>

                                 <Row className="justify-content-center align-items-start mt-3">

                                    {/* <Col  xs={6} className="mb-3">
               <span className="investmentDetailBody-label">{translate(props.lang, "Asset")}</span>
               
            </Col>
            <Col xs={6} className="mb-3">
            <span className="investmentDetailBody-value">{d.inAmount}</span>
            
            </Col> */}
                                    <Col xs={6} className="mb-3">
                                       <span className="investmentDetailBody-label">{translate(props.lang, "Capital")}</span>
                                    </Col>
                                    <Col xs={6} className="mb-3 text-end">
                                       <span className="investmentDetailBody-value">{d.capital}</span>

                                    </Col>
                                    <Col xs={6} className="mb-3">
                                       <span className="investmentDetailBody-label">{translate(props.lang, "Earning")}</span>

                                    </Col>
                                    <Col xs={6} className="mb-3 text-end">
                                       <span className="investmentDetailBody-value">{d.earning}</span>


                                    </Col>
                                     {d.showWithdrawCapitalButton ? (
                                       <Col sm={6} xs={6}>

                                          <div className="withdrawal-capital-btn btn-responsive text-center py-2" onClick={(e) => setCapitalModal(d.orderId)}>
                                             <span className="withdrawal-btn-capital-text">{translate(props.lang, "Withdraw Capital")}
                                             </span>
                                          </div>
                                       </Col>
                                    ) : null} 
                                    {d.showWithdrawButton ? (
                                    <Col sm={6} xs={6}>
                                       <div className="withdrawal-btn btn-responsive text-center py-2 ms-2" onClick={() => setProfit(d)}>
                                          <span className="withdrawal-btn-text">{translate(props.lang, "Transfer")}
                                          </span>
                                       </div>
                                    </Col>
                                     ):null}
                                    {d.showUnsubscribeButton ? (
                                    <Col sm={12} xs={12}>
                                       <div className="d-flex justify-content-center">
                                          <div className="unsubsribe-btn btn-responsive text-center  py-2 ms-2 mt-2" onClick={(e) => setUnsubModal(d)}> <span className="unsubsribe-btn-text">
                                             {translate(props.lang, "Unsubscribe")}</span>
                                          </div>
                                       </div>
                                    </Col>
                                     ):null} 


                                 </Row>

                              </div>
                           </Accordion.Collapse>

                        </div>

                     );
                  })}

                  {history && history.length === 0 ? (
                     <div className="ms-2">
                        <span className={`font-weight-400 font-16`}>{translate(props.lang, "No result found")}</span>
                    </div>
                   ):null}

               
               {history && history.length > 0 ? (
               <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                  previousLinkClassName={"pagination__previouslink text-blue-1"}
                  nextLinkClassName={"pagination__nextlink text-blue-1"}
                  disabledClassName={"pagination__link--disabled text-muted"}
                  activeClassName={"pagination__link--active text-white"}

               />
            ):null}                 
               
            </Accordion>
            
               </Row>
            </div>
         </div>
         <div>
     
         </div>
         </>
         }
      </div>

      </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(InvestmentWalletDetails);
