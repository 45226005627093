import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { Accordion, Card } from 'react-bootstrap';
import { url, api } from "../../../utils/api";
import ReactPaginate from "react-paginate"
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import Loadingv1 from '../../components/Loading/Loadingv1';


const InvestmentDetails = (props) => {
   const [
      activeaccordionRoundedStylish,
      setActiveaccordionRoundedStylish,
   ] = useState(0);
   const productPerpage = 10;
   const [totalRecord, setTotalRecord] = useState(0);
   const [result, setResult] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [content, setContent] = useState(null);
   const [loading, setLoading] = useState(false);
   useEffect(() => {
      getPortfolio();
      // getDialCode(selectedCountry);
  }, [pageNumber]);

  const redirectToCtrader = (link) =>{
      window.open(link,'_blank');

  }
  
  const getPortfolio = () => {
      setLoading(true);
      fetch(api.product + `/getInvestmentHistoryList?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language": props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
          .then(response => Promise.all([response.ok, response.text()]))
          .then(responseJson => {
              let result = JSON.parse(responseJson[1]);
              if (responseJson[0]) {
                  setContent(result.investmentHistoryList)  
                  setLoading(false);
                  var pageCount = Math.ceil(result.investmentHistoryList.totalRecords / productPerpage)
                  setPageCount(pageCount);
                }
                
               else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
                  setLoading(false);
              }
          }).catch(error => {
              console.error("error", error);
          });
  }
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
   }
   

   return (
      <>
         <div className="mt-3">
            <Row className="justify-content-center align-items-center">
               <Col lg={12} md={12} xs={12} sm={12}>
                  <div className="card card-black h-100 py-4 px-4">
                     <div className="card-header align-items-center border-bottom-0">
                        <span className={`font-weight-600 font-22 text-capitalize text-blue-1`}>{translate(props.lang, "Investment Details")}</span>
                     </div>
                     <div className="card-body align-items-center pt-3 pb-3">
                        {loading ? (
                           <Loadingv1/>
                        ):
                        <Table responsive className="w-100 investHistory">
                           <thead>
                              <tr>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "cTrader ID")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "investor password")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "package")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "total investment amount (USD)")}</th>
                                 {/* <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "subscription days left")}</th> */}
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "realized p/l % (daily)")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "realized p/l % (monthly)")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "total return")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`} style={{width:"200px"}}>{translate(props.lang, "cTrader Link")}</th>
                                 {/* <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "subscription period")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang , "subscription status")}</th> */}
                              </tr>
                           </thead>
                           <tbody>
                              {content && content.productOrderDtoList.map((item, i) => (
                                 <tr key={i}>
                                    <td className={`investHistory-txt font-weight-400`}>{item.cTraderId}</td>
                                    <td className={`investHistory-txt font-weight-400 text-capitalize`}>{item.investorPassword}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.productName}</td>
                                    <td className={`investHistory-txt font-weight-400 `}>{item.investmentAmount}</td>
                                    {/* <td className={`investHistory-txt font-weight-400 font-weight-400 font-12`}>{}</td> */}
                                    <td className={`investHistory-txt font-weight-400`}>{item.percentage}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.percentageMonthly}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.totalReturn}</td>
                                    <td className={`investHistory-txt  font-weight-400`}> 
                                       {item.link !== ""? (
                                       <a href={item.link} target="_blank" rel="noopener noreferrer" className="investHistory-txt font-weight-400 linkHover" style={{textDecoration:"underline"}}>
                                          {item.link}
                                       </a>
                                       ):null}
                                       
                                       </td>

                                    {/* <td className={`investHistory-txt font-weight-400 font-weight-400 font-12`}>27 Sep 2022 - 26 dec 2022</td>
                                    <td className={`investHistory-txt font-weight-400 font-weight-400 font-12 text-capitalize status ${item.status.toLowerCase()}`}>{item.status}</td> */}
                                 </tr>
                              ))}

                              {content && content.productOrderDtoList.length === 0 ? (
                              <tr>
                                 <td colspan="8" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                              </tr>
                           ) : null}
                           </tbody>
                        </Table>
                        }
                     </div>
                     <div>
                        {content && content.productOrderDtoList.length > 0 ? (
                           <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                              previousLinkClassName={"pagination__previouslink text-blue-1"}
                              nextLinkClassName={"pagination__nextlink text-blue-1"}
                              disabledClassName={"pagination__link--disabled text-muted"}
                              activeClassName={"pagination__link--active text-white"}

                           />
                        ):null}
                  </div>
                  </div>
                 
               </Col>
            </Row>
         </div>
      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(InvestmentDetails);
