import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import $ from 'jquery';
import "../../../css/profile.scss";
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import swal from "sweetalert";
import { FaCopy } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";


const Profile = (props) => {

    const [profile, setProfile] = useState(null);
    // const [formData, setFormData] = useState({
    //     "username":null,
    //     "birthDate":null,
    //     "gender" : null,
    //     "address" :null
    // })
    useEffect(() => {
        getProfileInfo();
    }, []);

   

    const getProfileInfo = () => {
        fetch(api.user + '/viewProfile', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setProfile(result);
                }
               else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }
    const updateProfile = () => {
        let data = {
            "username": profile.fullName,
            "birthDate": profile.birthDate,
            "gender": profile.gender,
            "address": profile.address,
        }
        fetch(api.user + "/editProfile", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    swal('Success', result.message, "success");
                } else {
                    swal('Opps', result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            border: "none",
            boxShadow: state.isFocused ? null : null,
            color: "white",
            padding: "5px"
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            //   background: "linear-gradient(180deg, rgba(20, 73, 78, 0.8) 50%, rgba(20, 73, 78, 0.9) 100%)",
            backdropFilter: "blur(100px)",
            color: "black",
            borderRadius: "14px",
        }),
        menuList: (base) => ({
            ...base,
            padding: 0
        }),
        singleValue: (base) => ({
            ...base,
            color: "white"
        })
    };

    const copyCode = () => {
        let value = props.auth.auth.claims.referralCode;
        var $temp = $("<input>");
        if (value != '') {
           var $temp = $("<input>");
           $("body").append($temp);
           var dummy = $temp.val(value).select();
           dummy.focus();
           document.execCommand("copy");
           $temp.remove();
        }
  
  
        swal(translate(props.lang, "Success"), translate(props.lang, "Copied"), "success");
     }

    return (
        <>
            <div className="my-3">
                <Row className="justify-content-center align-items-center">
                    <div className="d-none d-md-block">
                        <Col lg={11} md={11} xs={11} sm={11}>
                            <div className="card card-black h-100 py-4 px-4">
                                <div className="card-body align-items-center">
                                    <Row className="justify-content-between">
                                        <Col lg={3} className="text-center h-100 pb-5">
                                            <div className="text-center">
                                                <img src={require('../../../images/avatar/empty-user.png').default} className="img-fluid profile-img w-50" />
                                            </div>
                                            <div className="mt-5 text-center">
                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Email")}</span>
                                                <span className={`font-weight-600 font-18 text-blue`}>{props.auth.auth.claims.username}</span>
                                            </div>
                                            {/* <div className="mt-5 text-center">
                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "ID")}</span>
                                                <span className={`font-weight-600 font-18 text-uppercase text-blue`}>{profile ? profile.profileId : ""}</span>
                                            </div> */}
                                        </Col>
                                        <Col lg={8} className="text-center h-100">
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "full name")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.fullName : ""}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Email")}</span>
                                                        <div className="d-flex align-items-center w-100">
                                                            <span className={`font-weight-600 font-18 text-blue me-2`}>{profile ? profile.email : ""}</span><img src={require('../../../images/profile/verified.png').default} className="verified-icon" /> 
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "gender")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.gender !== null ? profile.gender : "-" : "-"}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "phone number")}</span>
                                                        <div className="d-flex align-items-center w-100">
                                                          
                                                            <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.phoneNo !== "" ? profile.phoneNo : "-" : "-"}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-12 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "date of birth")}</span>
                                                        <span className={`font-weight-600 font-16 text-blue`}>{profile ? profile.birthDate : ""}</span>
                                                    </div>
                                                </Col> */}
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "joining date")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.joiningDate : ""}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Referral")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.referralBy : ""}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Referral Code")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.referralCode : ""} <FaCopy color="#000063" className="ms-2 mb-1 cursor-pointer" onClick={()=>copyCode()}/></span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "residential address")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.address !== null ? profile.address : "-" : "-"}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Country")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.country : "-"}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <div className="mt-5 text-start">
                                                        <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "kyc status")}</span>
                                                        <span className={`font-weight-600 font-18 text-blue`}>{profile ? profile.kycStatus : "-"}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className="d-block d-md-none mt-3 mt-md-0">
                        <Col lg={11} md={11} xs={12} sm={12}>
                            <Row className="justify-content-center">
                            <Col lg={11} md={11} xs={12} sm={12}>   
                            <div className="card card-black h-100 py-4 px-4">
                                <div className="card-body align-items-center">
                                    <Row className=" justify-content-between align-items-center">
                                        <Col sm={5} xs={5} className="text-center h-100 pb-5  ">
                                            <div className="text-center">
                                                <img src={require('../../../images/avatar/empty-user.png').default} className="img-fluid profile-img" />
                                            </div>
                                        </Col>
                                        <Col sm={7} xs={7} >
                                            <Row className="align-items-start">
                                                <div className="mb-5">
                                                    <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "Email")}</span>
                                                    <span className={`font-weight-600 font-15  text-blue`}>{props.auth.auth.claims.username}</span>
                                                </div>
                                                {/* <div className="mt-3">
                                                    <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block mb-2`}>{translate(props.lang, "ID")}</span>
                                                    <span className={`font-weight-600 font-15 text-uppercase text-blue`}>{profile ? profile.profileId : ""}</span>
                                                </div> */}
                                            </Row>       
                                        </Col>
                                        <div className="border-bottom mt-2"></div>
                                        <Col lg={8} className="text-center h-100">
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className=" text-start">
                                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "full name")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.fullName : ""}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className=" text-start">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "Email")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                        <div className="d-flex justify-content-end align-items-center w-100 ">
                                                            <span className={`font-weight-600 font-15 text-blue text-elip me-2`}>{profile ? profile.email : ""}</span><img src={require('../../../images/profile/verified.png').default} className="verified-icon" /> 
                                                        </div>
                                                        </Col>                                   
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "gender")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15  text-blue`}>{profile ? profile.gender !== null ?  profile.gender : "-" : "-"}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                    <Col>
                                                            <div className="text-start">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "phone number")}</span>
                                                            </div>
                                                    </Col>
                                                    <Col>
                                                    <div className="d-flex justify-content-end align-items-center">  
                                                            <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.phoneNo !== "" ? profile.phoneNo  : "-" : "-"} </span>
                                                    </div>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                                {/* <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "date of birth")}</span>
                                                        
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="d-flex justify-content-end align-items-center">
                                                            <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.birthDate : ""}</span>
                                                        </div>
                                                    </Col>
                                                    </Row>
                                                </Col> */}
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "joining date")}</span>
                                                            
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.joiningDate : ""}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "Referral")}</span>
                                                            
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.referralBy : ""}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                            <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "Referral Code")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.referralCode : ""} <FaCopy color="#000063" className="cursor-pointer" onClick={()=>copyCode()}/></span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "residential address")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center word-break text-end">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.address !== null ? profile.address : "-" : "-"}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                 <Row className="mt-4 align-items-center">
                                                        <Col>
                                                            <div className="text-start">
                                                                <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "Country")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <span className={`font-weight-600 font-15 text-blue`}>{profile ? profile.country : "-"}</span>
                                                            </div>
                                                        </Col>
                                                </Row>
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Row className="mt-4 align-items-center">
                                                            <Col>
                                                                <div className="text-start">
                                                                    <span className={`font-weight-400 font-16 text-grey-1 text-capitalize d-block`}>{translate(props.lang, "kyc status")}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="d-flex align-items-center justify-content-end text-end">
                                                                    <span className={`font-weight-600 font-15  text-blue`}>{profile ? profile.kycStatus : "-"}</span>
                                                                </div>
                                                            </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </div>
       
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Profile);
