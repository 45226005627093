import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { translate } from "../../../utils/translate";
import "../../../css/team.scss";
import "../../../css/tree.scss"
import { api } from "../../../utils/api";
import { FaUserCircle } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";

const TotalTeamInvestment = (props) => {
  // const [treeData,setTreeData] = useState(null);
  const [parent, setParent] = useState([]);

  useEffect(() => {
    getTreeData();
  }, [])
  const getTreeData = () => {
    fetch(api.team + "/directTeamInvestment", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang,
        "Authorization": "Bearer " + props.auth.auth.token
      }),
    })
    .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
         let result = JSON.parse(responseJson[1]);
         if (responseJson[0]) {
          let tempTree = [{ ...result.selfBos, children: result.TeamBos }]
          setParent(tempTree);
         }
         else{

         }
    
      }).catch(error => {
        console.error("error", error);
      });
  }

    const appendChildren = (uid, level) => {
      // if (level < 3) {
        fetch(api.team + `/directTeamInvestment?memberId=${uid}` , {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
          }),
        })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
         let result = JSON.parse(responseJson[1]);
         if (responseJson[0]) {
              if (result.TeamBos.length > 0)
                findArrayByUsername(parent, result.TeamBos, uid)
            }
          }).catch(error => {
            console.error("error", error);
          });
      }
    // }

    const findArrayByUsername = (mainArr, newArr, uid) => {
      let newGroupMember = findById(mainArr, newArr, uid);
      setParent([...newGroupMember]);
    }

    function findById(array, newArray, id) {

      for (let i = 0; i < array.length; i++) {
        if (array[i].memberId === id) {
          if (array[i].children !== newArray) {
            array[i].children = newArray;
            array[i].noChildren = newArray.length === 0; // Add noChildren property
            break;
          }
        } else {
          if (array[i].children && array[i].children.length > 0) {
            findById(array[i].children, newArray, id);
          }
        }
      }
      return array;
    }


  const renderChildren = (child) => {
    if (child && child.length > 0) {
      return(
        <ul>
          {child.map((item, i)=>(
            <li key={i}>
              {item.noOfUsers>0 ? (<input type="checkbox" id={item.username+i}  />) : null}
              <label for={item.username+i} onClick={() => appendChildren(item.memberId)}>
                <input type="checkbox" id="cb1" /><span className="px-2"><FaUserCircle color="#000063" className="fa-2x"/></span> <span className={`font-weight-700 font-20 text-capitalize text-red-1`}>{item.imageUrl}</span> <br/><span className={`font-weight-400 font-20 text-grey-1 ms-2`}>({translate(props.lang, "cTrader Balance:")} $ {item.salesValue} | {translate(props.lang, "Total Member:")} {item.noOfUsers} | {translate(props.lang, "Total Active Member:")} {item.noOfActiveUsers})</span>
              </label>
              {renderChildren(item.children)}
          </li>
          ))}
        </ul>
      )
    }
  }

  return (
    <>
      <div className="mt-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={12} md={11} xs={11} sm={11}>
            <div className="card card-black h-100 py-4 px-4 mt-3">
              <div className="card-header align-items-center border-bottom-0">
                <span className={`font-weight-600 font-22 text-uppercase text-blue-1`}>{translate(props.lang, "Team Referral Tree")}</span>
              </div>
              <div className="mobile-team-tree">
              {parent ? (
                <ul>
                {parent.map((item, i) => (
                  <li> {item.noOfUsers>0 ? (<input type="checkbox" id="cb1"/>) : null}<label for="cb1"> <span className="px-2"><FaUserCircle color="#000063" className="fa-2x"  /></span> <span className={`font-weight-700 font-20 text-capitalize text-red-1`}>{item.imageUrl}</span><span className={`font-weight-400 font-20 text-grey-1  ms-2`}>({translate(props.lang, "cTrader Balance:")} $ {item.salesValue} | {translate(props.lang, "Total Member:")} {item.noOfUsers} | {translate(props.lang, "Total Active Member:")} {item.noOfActiveUsers})</span></label>
                  {renderChildren(item.children)}
                    {/* <ul>
                      <li><input type="checkbox" id="cb2" /><label for="cb2"><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span><br/><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label>
                        <ul>
                          <li><label><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label></li>
                          <li><label><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label></li>
                        </ul>
                      </li>
                      <li><input type="checkbox" id="cb3" /><label for="cb3"><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span><br/><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label>
                        <ul>
                          <li><label><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span> <br/><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label></li>
                          <li><input type="checkbox" id="cb6" /><label for="cb6"><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span><br/> <span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label>
                            <ul>
                              <li><label><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span> <br/><span className={`font-weight-400 font-18 text-grey-1 text-capitalize`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label></li>
                              <li><label><span className="px-2"><FaUserCircle color="#000063" /></span> <span className={`font-weight-700 font-18 text-capitalize text-red-1`}>lorem ipsum</span> <br/><span className={`font-weight-400 font-18 text-grey-1 text-capitaliz`}>({translate(props.lang, "Total Sales:")} $ 323,367 | {translate(props.lang, "Total Member:")} 4245 | {translate(props.lang, "Total Active Member:")} 3091)</span></label></li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                    </ul> */}
                  </li>
                ))}
                </ul>         
                ):
                <div className="text-center">
                  <img src={require('../../../images/common/noResult.png').default} className="img-fluid noResult" />
                  
                </div>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
};

export default connect(mapStateToProps)(TotalTeamInvestment);
