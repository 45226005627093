import React,{useState,useEffect } from "react";
import { connect } from 'react-redux';
import { Link,useParams } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";

const historyData = [
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 1530, destination: '12234...2343f7', txId: '759181...29f79', status: 'completed' },
    { time: '2022-09-28 15:50', type: 'withdrawal', asset: 'eth', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'failed' },
]

const BonusTransactionHistory = (props) => {
   const [history , setHistory] = useState(null);
   const productPerpage = 10;
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const changePage = (event) => {
      setPageNumber(event.selected);
   }
   const { type } = useParams();

   useEffect(() => {
      getHistory();
   }, [props.auth]);
   
   const getHistory = (e) => {
      let type_name = type.toUpperCase();
      fetch(api.team + `/bonusTransactionHistoryList?pageNo=${pageNumber}&pageSize=${productPerpage}&walletType=usdt&bonusType=${type_name}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language" : props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
      .then(response => response.json())
      .then(responseJson => {
         setHistory(responseJson.transactionHistoryList.walletTrxDtoList);
      }).catch(error => {
          console.log("Error:", error);
      });
    }
   return (
      <>
      <Row className="justify-content-center align-items-center">
         <Col lg={12} md={12} sm={12} xs={12}>
            <div className="card card-bx cardBg h-100">
               <div className="card-header align-items-center border-bottom-0">
                  <span className="announcement-header">{translate(props.lang, props.match.params.type.replace(/_/g, ' ') +" transaction history")}</span>
               </div>
               <div className="card-body align-items-center pt-0 pb-3">
                  <Table responsive className="w-100 walletHistory">
                        <thead>
                           <tr>
                              <th className="table_header">{translate(props.lang, "time")}</th>
                              <th className="table_header">{translate(props.lang, "type")}</th>
                              <th className="table_header">{translate(props.lang, "amount")}</th>
                              <th className="table_header">{translate(props.lang, "destination")}</th>
                              <th className="table_header">{translate(props.lang, "txid")}</th>
                              <th className="table_header">{translate(props.lang, "status")}</th>
                           </tr>
                        </thead>
                        <tbody>
                        {history && history.map((item, i)=>(
                           <tr>
                              <td className="table_content text-center">{item.transactionDate}</td>
                              <td className="table_content text-center">{item.transactionType}</td>
                              <td className="table_content text-center">{item.outAmount}</td>
                              <td className="table_content text-center">{item.destination}</td>
                              <td className="table_content text-center">{item.txId}</td>
                              <td className= {`table_content text-center status ${item.status}`}>{item.status}</td>
                           
                           </tr>
                        ))}
                        </tbody>
                  </Table>
               </div>
            </div>
         </Col>
      </Row>
      </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(BonusTransactionHistory);
