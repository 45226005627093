import React, { useState } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import QRCode from "react-qr-code";
import $ from 'jquery';
import { FaCopy } from "react-icons/fa";
import "../../../css/team.scss";
import { translate } from "../../../utils/translate";
import LiveSupport from "../../components/Live/LiveSupport";

const Referral = (props) => {
   const [success, setSuccess] = useState(null);

   const copyQRCode = () => {
      let value = window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode;
      var $temp = $("<input>");
      if (value != '') {
         var $temp = $("<input>");
         $("body").append($temp);
         var dummy = $temp.val(value).select();
         dummy.focus();
         document.execCommand("copy");
         $temp.remove();
      }


      setSuccess(translate(props.lang, "Copied"));
      setTimeout(() => {
         setSuccess("");
      }, 1000);
   }


   const downloadImage = () => {
      const svg = document.getElementById("qrcode");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = function () {
         canvas.width = img.width;
         canvas.height = img.height;
         ctx.drawImage(img, 0, 0);
         const pngFile = canvas.toDataURL("image/png");

         const downloadLink = document.createElement("a");
         downloadLink.download = "qrcode";
         downloadLink.href = `${pngFile}`;
         downloadLink.click();
      };

      img.src = "data:image/svg+xml;base64," + btoa(svgData);
   }

   return (
      <>
         <div className="mt-3">
            <Row className="justify-content-center align-items-center">
               <Col lg={11} md={12} xs={12} sm={12} className="mt-3 mt-md-0">
                  <div className="referral-card py-5 px-4 px-lg-5">
                     <Row className="align-items-center">
                        <div className="d-none d-md-block">
                           <Row className="align-items-center">
                              <Col lg={3} md={5} sm={8} xs={8} className="text-center">
                                 <div className="referral-qr-container p-3 w-75" onClick={()=>downloadImage()}>
                                    <QRCode
                                       id="qrcode"
                                       size={128}
                                       fgColor="#012060"
                                       bgColor="transparent"
                                       style={{ height: "auto", width: "100%" }}
                                       value={window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}
                                       viewBox={`0 0 128 128`}
                                    />
                                 </div>
                              </Col>
                              <Col lg={9} md={7} sm={12} xs={12} className="text-start">
                              <div>
                                 <span className="font-weight-600 font-22 text-blue-1 text-uppercase">{translate(props.lang, "share QR code")}</span>
                              </div>
                              <div className="mt-5">
                                 <span className="font-weight-400 font-18 text-grey-1 text-uppercase">{translate(props.lang, "Referral Link")}</span>
                              </div>
                              <div>
                                 <div className="input-group-referral mt-3 py-2 px-2">
                                    <div className="w-75 ms-3">
                                       <span className="font-weight-400 font-16 text-grey-1">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                    </div>
                                    <div className="w-25">
                                       <div className="px-3 text-end cursor-pointer" onClick={copyQRCode}>
                                          <FaCopy color="#012060" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mt-2">
                                    <span className="font-weight-400 font-12 text-success">{success}</span>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                        </div>
                     
                        <div className="d-block d-md-none">
                              <Col lg={3} md={3} sm={12} xs={12} className="text-center">
                                 <Row className="justify-content-center">
                                    <Col lg={3} md={3} sm={8} xs={8}>
                                       <div className="referral-qr-container p-3" onClick={()=>downloadImage()}>
                                          <QRCode
                                             id="qrcode"
                                             size={128}
                                             fgColor="#012060"
                                             bgColor="transparent"
                                             style={{ height: "auto", width: "100%" }}
                                             value={window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}
                                             viewBox={`0 0 128 128`}
                                          />
                                       </div>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col lg={9} md={9} sm={12} xs={12} className="text-center text-md-start">
                           <div className="mt-5">
                              <span className="font-weight-600 font-22 text-blue-1 text-capitalize">{translate(props.lang, "share QR code")}</span>
                           </div>
                           <div className="mt-4">
                              <span className="font-weight-400 font-16 text-grey-1 text-capitalize">{translate(props.lang, "Referral Link")}</span>
                           </div>
                           <div>
                              <div className="input-group-referral py-2 px-2">
                                 <div className="w-75 ms-5">
                                    <span className="font-weight-400 font-16 text-grey-1 overflow-wrap-anywhere">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                 </div>
                                 <div className="w-25">
                                    <div className="px-3 text-end cursor-pointer" onClick={copyQRCode}>
                                       <FaCopy color="#012060" />
                                    </div>
                                 </div>
                              </div>
                              <div className="mt-2">
                                 <span className="font-weight-400 font-12 text-success">{success}</span>
                              </div>
                           </div>
                        </Col>
                        </div>
                        
                     </Row>
                  </div>
               </Col>
            </Row>
         </div>

      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(Referral);
