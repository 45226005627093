import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import "../../../css/setting.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { translate } from "../../../utils/translate";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";

const ChangeEmailAddress = (props) => {
  const [showSentVerification, setshowSentVerification] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [transactionPassword, setTransactionPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loadingOtp, setLoadingOtp] = useState(false);
  let errorsObj = { verificationCode: '', newEmail: '', transactionPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitForm = () => {
    let check = true;
    const errorObj = { ...errorsObj };
    if (!newEmail) {
      errorObj.newEmail = translate(props.lang, "This field is required");
      check = false;
    }

    if (!verificationCode) {
      errorObj.verificationCode = translate(props.lang, "This field is required");
      check = false;
    }
    if (!transactionPassword) {
      errorObj.transactionPassword = translate(props.lang, "This field is required");
      check = false;
    }
    setErrors(errorObj);
    if (check) {
      setLoading(true);
      let data = {
        "newEmailAddress": newEmail,
        "emailVerifyCode": verificationCode,
        "transactionPassword" : transactionPassword,

      };
      fetch(api.user + '/changeEmail/new', {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Content-Language": props.lang,
          "Authorization": "Bearer " + props.auth.auth.token
        }),
        body: JSON.stringify(data)
      })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
          setLoading(false);
          let result = JSON.parse(responseJson[1]);
          if (responseJson[0]) {
            swal('Success', result.message, "success");
          } else {
            swal('Opps', result.message, "error");

          }

        }).catch(error => {
          console.error("error", error);
        });
    }
  }

  const sendOTP = () => {
    const errorObj = { ...errorsObj };
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let check = true;
    if (!newEmail) {
      errorObj.newEmail = translate(props.lang, "This field is required");
      check = false;
    } else if (!reg.test(newEmail)) {
      errorObj.newEmail = translate(props.lang, "Invalid email format");
      check = false;
    }
    setErrors(errorObj);
    if (check) {
      fetch(api.user + `/email/generateChangeEmailOtp?email=${newEmail}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Content-Language": props.lang,
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
          let result = JSON.parse(responseJson[1]);
          if (responseJson[0]) {
            setshowSentVerification(true);
            swal(translate(props.lang, 'Success'), result.message, "success");
          } else {
            swal(translate(props.lang, 'Error'), result.message, "error");
          }
        }).catch(error => {
          console.error("error", error);
        });
    }
  }

  return (
    <>
    <div className="">
      <Row className="justify-content-center align-items-center">
        <Col lg={12} md={11} xs={11} sm={11}>
          <Link to="/setting" className="d-flex align-items-center">
            <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
          </Link>
          <div className="card card-black h-100 py-4 px-4 mt-3">
            <div className="card-header align-items-center border-bottom-0 text-center text-md-start">
              <span className={`headerText`}>{translate(props.lang, "Set Email Address")}</span>
            </div>
            <div className="card-body align-items-center">
              <Row className="justify-content-center align-items-start ">
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                  <span className={`font-weight-500 font-16 text-grey-1 text-capitalize`}>{translate(props.lang, "New Email Address")}</span>
                  <div className={`input-group ${errors.newEmail ? 'errorBorder' : ''}`}>
                    <input
                      type="text"
                      className="form-control input-transparent font-weight-400 font-16"
                      placeholder={translate(props.lang, "Enter New Email Address")}
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <div className='mx-2 d-flex justify-content-center align-items-center'>
                      <img src={require('../../../images/kyc/edit.png').default} className="icon2" />
                    </div>
                  </div>
                  <span className="error">{translate(props.lang, errors.newEmail)}</span>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                  <span className={`font-weight-500 font-16 text-grey-1 text-capitalize`}>{translate(props.lang, "Transaction Password")}</span>
                  <div className={`input-group ${errors.transactionPassword ? 'errorBorder' : ''}`}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control input-transparent font-weight-400 font-16"
                      placeholder={translate(props.lang, "Enter Your Transaction Password")}
                      value={transactionPassword}
                      onChange={(e) => setTransactionPassword(e.target.value)}
                    />
                    <div className="px-3" onClick={() => setShowPassword(!showPassword)}>
                      {
                        showPassword ? (<FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />) : (<FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />)
                      }
                    </div>

                  </div>
                  <span className="error">{translate(props.lang, errors.transactionPassword)}</span>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                  <span className={`font-weight-500 font-16 text-grey-1 text-capitalize`}>{translate(props.lang, "Verification Code")}</span>
                  <div className={`input-group ${errors.verificationCode ? 'errorBorder' : ''}`}>
                    <input
                      type="text"
                      className="form-control input-transparent font-weight-400 font-16"
                      placeholder={translate(props.lang, "Enter Verification Code")}
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <div className='mx-0 mx-md-2 d-flex justify-content-center align-items-center'>
                      <div className="requestCodeBtn px-3 py-1 me-2 " disabled={loadingOtp} onClick={(e) => sendOTP(e)}>
                        <span className="requestCodeBtnText">
                          {
                            !showSentVerification ? translate(props.lang, "Send") : translate(props.lang, "Resend")
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="error">{translate(props.lang, errors.verificationCode)}</span>
                </Col>
              </Row>
              <div className="text-center form-group mb-3">
                <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={submitForm} disabled={loading}>
                  {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>

    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(ChangeEmailAddress);
