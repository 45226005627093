const ZH_CN = {
    "Loading": "加载中",
    "Welcome Back": "欢迎回来",
    "email address": "电子邮件地址",
    "Enter Your Email Address": "输入您的电子邮箱地址",
    "password": "密码",
    "Enter Your Password": "输入您的密码",
    "Forgot Password?": "忘记密码？",
    "Login": "登录",
    "Don’t have an account yet?": "还没有账号？",
    "Sign up now": "立即注册",
    "This field is required": "请填写此处",
    "Create Personal Account": "创建个人账号",
    "Username": "用户名",
    "Email": '电子邮件',
    "Verification Code": "验证码",
    "Enter Code": "输入验证码",
    "Send": "发送",
    "Resend": "重发",
    "Country": "国家",
    "SELECT COUNTRY": '选择国家',
    "Referral Code": '推荐码',
    "Enter Referral Code": '输入推荐码',
    "Sign Up": "注册",
    "Confirm Password": "确认密码",
    "Confirm Your Password": '确认您的密码',
    "Invalid email format": "电子邮件格式无效",
    "Success": "成功",
    "Error": "错误",
    "no space allowed at the begining": "开头不允许有空格",
    "Username cannot less than 6 letters": "用户名不能少于6个字母",
    "Password cannot less than 6 characters": "密码不能少于6个字符",
    "Password is not match": '密码不匹配',
    "Already have an account?": "已有帐号？",
    "Login now": "现在登录",
    "Forgot Password": "忘记密码",
    "Enter Your Verification Code": "输入您的验证码",
    "Submit": '提交',
    "By clicking ”Submit” button, you agree to our Privacy and Terms of Use.": "点击“提交”按钮，即表示您同意我们的隐私和使用条款。",
    "Back To Login": "回到登入",
    "Confirm password is not match with password": "确认密码与密码不匹配",
    "Back": "返回",
    "thank you!": "谢谢!",
    "dashboard": "首页",
    "Investment Portfolio": "投资配套",
    "Overview": "概述",
    "New Investment": "新投资",
    "Investment Details": "投资详情",
    "Wallet": '钱包',
    "Deposit": '存款',
    "Withdrawal": '提款',
    "Transaction History": "交易记录",
    "Team": "团队",
    "Total Team Investment": '团队总投资',
    "Group Investment Summary": "团队投资概要",
    "Referral": "推荐",
    "Profile": "个人资料",
    "Settings": '设置',
    "KYC": 'KYC',
    "Change Password": "更改密码",
    "Set Email Address": "设置电子邮件地址",
    "Transaction Password": "交易密码",
    "Copied": "已复制",
    "Referral Link": "推荐链接",
    "Logout": "登出",
    "cTrader ID": "cTrader 账号",
    "investor password": "投资者密码",
    "package": '配套',
    "total investment amount (USD)": "投资总额（美元）",
    "subscription days left": '剩余订阅天数',
    "realized p/l % (daily)": "已实现盈/亏%（每日）",
    "realized p/l % (monthly)": "已实现盈/亏%（每月）",
    "total return": '总回报',
    "subscription period": '订阅期',
    "subscription status": "订阅状态",
    "New Email Address": "新电子邮件地址",
    "Enter New Email Address": "输入新的电子邮件地址",
    "Update": '更新',
    "current password": "当前密码",
    "Enter your current password": "输入您的当前密码",
    "new password": "新密码",
    "Enter Your New Password": "输入您的新密码",
    "Confirm New Password": "确认新密码",
    "confirm your new password": "确认您的新密码",
    "Please fill in all required fields with valid information.": "请使用有效信息填写所有必填字段。",
    "New Password cannot less than 6 characters": "新密码不能少于6个字符",
    "Confirm password does not match with password": '确认密码与新密码不符',
    "new transaction password": "新交易密码",
    "Confirm New Transaction Password": "确认新的交易密码",
    "fullname as per ic": "姓名（根据身份证）",
    "gender": "性别",
    "Male": "男性",
    "Female" :"女性",
    "ic/ passport no.": "身份证/护照号码",
    "mobile no.": "手机号码",
    "residential address": "居住地址",
    "postcode": "邮政编码",
    "joining date": "加入日期",
    "kyc status": "KYC状态",
    "Upload IC/ Passport": "上传身份证/护照",
    "Upload Photo with Holding of IC/ Passport": "上传持有身份证/护照的照片",
    "Upload Proof of Address (Utility Bill/ Phone Bill/ Bank Slip)": "上传地址证明（公用事业账单/电话账单/银行单据）",
    "full name": "姓名",
    "phone number": "手机号码",
    "date of birth": "出生日期",
    "my group brokers": "我的团队经纪人",
    "share QR code": "分享二维码",
    "group leader": "组长",
    "total investment quantity": "投资总额",
    "team leadership level": "团队领导层",
    "No. Of People": "人数",
    "Actual rebate lot": "实际返利手数",
    "status": "状态",
    "No result found": "没有任何记录",
    "Total Sales:": "总销售额:",
    "Total Member:": "会员总数:",
    "Total Active Member:": "活跃会员总数:",
    "My Rank": '我的排名',
    "total group members": '团队成员总数',
    "total active members": "活跃会员总数",
    "profit sharing": "利润分享",
    "investment wallet details" : "投资组合详情",
    "my qr code" : "我的二维码",
    "Inbox" : "收件箱",
    "profit/ loss (day)" : "损益（天）",
    "USD Wallet" : "usd 钱包",
    "Winning rate" : "胜利率",
    "Weekly Transaction" : "每周交易",
    "Automated Trading Strategies" : "自动交易策略",
    "Gas Fee" : "气体费",
    "Choose Package" : "选配套",
    "investment amount" : "投资金额",
    "Next" : "继续",
    "Confirm" : "确认",
    "VIEW DETAILS" : "查看详情",
    "cTrader ID" : "cTrader ID",
    "cTrader Password" : "cTrader 密码",
    "investment package" : '投资组合',
    "GF Wallet" : 'GF 钱包',
    "Bonus Wallet" : "红利钱包",
    "Rewards" : "奖励",
    "Investment Wallet" : '投资钱包',
    "Capital" : "资本",
    "Earning" : "收入",
    "Withdraw Capital" : "撤资",
    "Withdraw Profit" : "提取利润",
    "Unsubscribe" : "退订",
    "Withdraw T&C" : '撤回条款和条件',
    "CRM wallet is allow to withdraw commission and bonus once per week." : 'CRM 钱包允许每周提取一次佣金和奖金。',
    "C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees." : "一旦合同期结束，C Trader 钱包将能够在赚取 ROI 的情况下取款，否则只允许在没有 ROI 的情况下取款投资基金，并收取 3% 的手续费。",
    "Termination" : "终止",
    "If investor terminate contract before period end, just able to withdraw invest capital without ROI." : "如果投资者在期末之前终止合同，则只能在没有投资回报率的情况下撤回投资资金。",
    "withdrawal request submitted" : "提款请求已提交",
    "Enter Transaction Password" : '输入交易密码',
    "address" : "地址",
    "coin" : "币种",
    "Enter Transaction Password" : '输入交易密码',
    "Network" : "网络",
    "wallet address" : "钱包地址",
    "Descriptions" : "描述",
    "Crypto & eWallet is a channel where deposits are performed via cryptocurrencies." : 'Crypto & eWallet 是一个通过加密货币进行存款的渠道。',
    "If you already have existing holds of cryptocurrency, you will be able to send the cryptocurrencies directly as a source of deposit." : "如果您已经持有现有的加密货币，您将能够直接将加密货币作为存款来源发送。",
    "On the other hand, if you do not hold any cryptocurrency, you will be able to buy cryptocurrencies with your local currency and it will be deposited to account." : '另一方面，如果您没有持有任何加密货币，您将能够用您的当地货币购买加密货币并将其存入账户。',
    "Your Wallet will be reflected with the equivalent amount in USD for the amount of cryptocurrency at time of received." : "您的钱包将以等值的美元金额反映在收到时的加密货币数量。",
    "Terms & Conditions" : "条款和条件",
    "All transaction fees are borne by client, unless otherwise stated." : "所有交易费用均由客户承担，除非另有说明。",
    "All transaction receipts are suggested to keep by client, has the right to request as proof." : "所有交易单据建议客户保留，有权要求作为证明。",
    "The deposit amount will be shown in your wallet right after the broker system acknowledges." : "经纪人系统确认后，存款金额将立即显示在您的钱包中。",
    "The application for deposits/ withdrawals of cryptocurrency will be processed within 15 minutes, unless any unforeseen circumstances." : '除非有任何不可预见的情况，否则加密货币的存款/取款申请将在 15 分钟内处理。',
    "withdraw from" : "提款从",
    "balance" : "余额",
    "withdrawal type" : '取款类型',
    "wallet address" : "钱包地址",
    "PLEASE SELECT" : "请选择",
    "Please Enter Your Wallet Address" : "请输入您的钱包地址",
    "withdraw amount" : "取款金额",
    "Enter Your Withdraw Amount" : "输入您的提款金额",
    "Enter Your Transaction Password" : "输入您的交易密码",
    "email verify code" : "邮箱验证码",
    "Enter Your Email Verify Code" : "输入您的电子邮件验证码",
    "Send OTP To Email" : "发送OTP到电子邮件",
    "*Withdraw will be charged 5 USDT per transaction" : "*每笔提款将收取5 USDT",
    "Internal Transfer" : "内部转账",
    "Transfer From" : "转移从",
    "Transfer To" : "转移至",
    "Total amount:" : "总金额：",
    "amount" : "金额",
    "Enter Amount" : "输入金额",
    "USDT Balance" : "USDT余额",
    "GF Balance" : "GF余额",
    "Complete" : "完成",
    "Wallet Transaction History Details" : "钱包交易历史详情",
    "Filter:" : "筛选:",
    "Back" : "返回",
    "Enter Verification Code" : "输入验证码",
    "Enter Your New Transaction Password" : "输入您的新交易密码",
    "fullname" : "全名",
    "Enter Your Fullname" : "输入您的全名",
    "ic/ passport no." : "身份证号码",
    "Enter Phone Number" : "输入电话号码",
    "Enter Residential Address" : "输入居住地址",
    "Enter Your Postcode" : '输入您的邮政编码',
    "Gas Fee Campaign" : "气体费用活动",
    "Register" : "登记",
    "Trade Frequency" : "交易频率",
    "Winning Ratio" : "胜率",
    "Trading Pairs" : "交易对",
    "Amount of Investment" : "投资金额",
    "Leverage" : "杠杆作用",
    "Variable Spreads" : "可变点差",
    "Forecast Return" : "预测回报",
    "Recommended Capital" : "推荐资金",
    "Gas Fee Not Enough" : "气体费不够",
    "Not Enough Balance" : "余额不足",
    "Top Up Now" : "立刻充值",
    "* Investing an amount below the recommended threshold may increase the risk of liquidation" : "* 投资低于建议阈值的金额可能会增加清算风险",
    "Please Enter Investment Amount With Minimum 100 USD." : "请输入投资金额，最低 100 美元.",
    "Booking of slots for sharing" :  "预订共享插槽",
    "Name" : "姓名",
    "Commission" : "佣金",
    "Gas Fee Rewards" : "气体费用奖励",
    "Free Gas Fee Campaign" : "免费气体费用活动",
    "Get your free gas fee by sharing to your friends. T&C provided." : "通过分享给你的朋友来获得你的免费气体费。提供条款和条件。",
    "Join Now" : "立刻充值加入",
    "Unsubscribe Package" : "退订配套",
    "Enter Transaction Password" : "输入二级密码",
    "Transaction Password" : "二级密码",
    "Send OTP To Email" : "发送OTP至电子邮件",
    "quick links" : "快速链接",
    "Total Profit" : "利润总额",
    "total investment" : "总投资额",
    "team bonus" : "团队奖金",
    "number of investment package" : "投资配套数量",
    "investment package" : "投资配套",
    "wallet" : "钱包",
    "account balance" : '账户余额',
    "announcements" : "公告",
    "Investment" : "投资",
    "Portfolio" : "文件夹",
    "Transfer" : "转帐",
    "Verification" : "验证",
    "personal performance data" : "个人表现数据",
    "Inbox" : "收件箱",
    "Coming Soon" : "即将推出",
    "Investment Amount (USD)" : "投资金额（美元）",
    "realized p/l %" : '已实现盈亏 %',
    "Gas Fee *Required" : "气体费用 *必填",
    "Subscribe Now" : "立即订阅",
    "Back to Home" : "返回主页",
    "Earnings" : "收益",
    "Type" : "类型",
    "Asset" : "资产",
    "Amount" : "金额",
    "destination" : "终点",
    "Enter Your Name" : "输入您的名字",
    "Select Date And Time" : "选择日期和时间",
    "Remark" : "备注",
    "Date And Time" : "日期和时间",
    "Select Gender" : "选择性别",
    "Enter Your IC / Passport No." : "输入您的身份证/护照号码",
    "withdrawal amount" : "提款金额",
    "Investment Package" : "投资配套",
    "Available Balance" : "可用余额",
    "Cancel" : "取消",
    "CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees." :"CRM 钱包允许每周提取一次佣金和奖金 C Trader 钱包将能够在合同期结束后提取并获得投资回报率，否则只允许提取投资基金而没有投资回报率，并收取 3% 的手续费.",
    "Please enter secondary password" : "请输入二级密码",
    "Please enter withdrawal amount" : "请输入取款金额",
    "Enter Withdrawal Amount" : "输入取款金额",
    "Please Enter Investment Amount" : "请输入投资金额",
    "Please enter secondary password." : "请输入二级密码.",
    "Please select package." : '请选择配套.',
    "Oops" : "哎呀",
    "Only allow format jpg or png" : "只允许格式为 jpg 或 png",
    "Unable Transfer To Same Wallet" : "无法转移到同一个钱包",
    "No notification yet" : "暂无通告",
    "Revenue" : "收入",
    "*Required" : '*必填',
    "Click Me for Customer Support" : "点击我获取客户支援",
    "Gas Fee Wallet" :  "气体费用钱包",
    "Top Up Successful" : "充值成功",
    "Top Up Amount" :   "充值金额",
    "Top-UP" : "充值",
    "Current Investment Amount" : "当前投资金额",
    "Top Up Investment Amount" : "充值投资金额",
    "Confirmation" : "确认",
    "Top Up Investment" : "充值投资",
    "day" : "天",
    "Connect": "连接",
    "cTrader Link" : "cTRADER 链接",
    "From" : "从",
    "To" : "至",
    "Personal Perfromance" : "个人表现",
    "Time" : "时间",
    "Total image size cannot more than 25 MB" : "图片总大小不能超过 25 MB",
    "Total image size cannot less than 50 KB" : "图片总大小不能少过 50 KB",
    "Only Numbers are allowed in this field" : "此处仅允许使用数字",
    "Crypto Deposit" : "加密货币存款",
    "Fiat Deposit": "法币存款",
    "Order Number" : "用户订单号",
    "Payment Currency" : "支付货币",
    "Deposit Amount" : "存款数额",
    "Enter Deposit Amount" : "输入存款数额",
    "Payment Amount" : "付款数额",
    "Enter Your Payment Amount" : "输入您的付款数额",
    "Exchange Rate" : "兑换汇率",
    "Converted Amount" : "兑换数额",
    "Select Payment Currency" : "选择支付货币",
    "Select Type Of Coin" : "选择币种类型",
    "Select Type Of Network" : "选择网络类型",
    "Deposit Address" : "充值地址",
    "Minimum Deposit" : "最小充币数量",
    "Please Select Network!" : "请选择网络!",
    "Bank Type" : "银行类型",
    "Select Bank Type" : "选择银行类型",
    "Please convert to payment amount from deposit amount" : "请将存款金额换算为付款金额",
    "language" : "语言",
    "transfer amount" : "转账数额",
    "Enter Transfer Amount" : "输入转账数额",
    "Please enter transfer amount" : "请输入转账数额",
    "Withdrawal Method (country)" : "提款方式（国家）",
    "Fiat Withdrawal" : "法币提款",
    "bank account number" : "银行账户",
    "bank name" : "银行名",
    "account holder name" : "账户持有人姓名",
    "swift code" : "swift代码",
    "bank address" : "银行地址",
    "Enter Bank Account Number" : "输入银行账户",
    "Enter Bank Name" : "输入银行名称",
    "Enter Account Holder Name" : "输入账户持有人姓名",
    "Enter Swift Code" : "输入 Swift 代码",
    "Enter Bank Address" : "输入银行地址",
    "*Minimum withdrawal: 150 USDT. Withdrawal will be charged 35U processing fee." : "*最低提款：150 USDT。提现将收取35U手续费。",
    "Crypto Withdrawal" : "加密货币提款",
    "Wallet Balance" : "钱包余额",
    "*Deposit will be charged 3.5% per transaction.": "**每笔交易将收取3.5%的押金.",
    "lot rebate" : "点差返佣",
    "Total Profit/Loss" : "盈利/亏损",
    "cTrader Deposit" : "cTRADER 存款",
    "cTrader Account" : "cTrader 账号",
    "Commission Wallet" : "佣金钱包",
    "*Minimum withdrawal : 100USD" : "*最低提款：100美元",
    "Total Team Deposit" : "团队存款总额",
    "Step into a World of Trading Excellence" : "步入卓越交易的世界",
    "cTrader Login ID" : "cTRADER 登录 ID",
    "Top Up" : "充值",
    "USDT Wallet" : "USDT钱包",
    "cTrader Balance" : "cTRADER 余额",
    "Copy Link" : "复制链接",
    "*Minimum amount: 100 USD" : "*最低金额：100 美元",
    "Activate" : "激活",
    "Activation Successful" : "激活成功",
    "Team Referral Tree" : "团队推荐图表",
    "Bank Deposit History" : "银行存款记录",
    "Currency Code" : "货币代码",
    "USD Amount" : "USD 金额",
    "Fiat Amount" : "法币金额",
    "TRX Date Time" : "TRX 时间",
    "Currency" : "货币",
    "cTrader Balance:" : "cTrader余额:",
    "Total cTrader Balance" : "cTrader余额总数",
    "Trading Account" : "交易账号",
    "Please select leverage" : "请选择杠杆作用"

 
}

export default ZH_CN; 