// const url =  "http://170.187.230.118:8080/rest"
// const url = "http://170.187.230.118:8085/rest";
const url = "https://cgiapi.omc-group.co/rest";
// const url = "https://api.crownglobalinternational.com/rest";


// const web_user = "leader";
// const web_user = "investor";

const api = {
    authenticate: url + '/user/authenticate',
    investment : url + '/product/getInvestmentList',
    package : url + '/product/getPackagePriceList',
    country : url + '/user/getRegisterCountries',
    phoneCode : url + '/user/getCountryPhoneCode',
    user : url + '/user',
    wallet : url + '/wallet',
    product : url + '/product',
    team : url + '/team',
    depositList: url + '/wallet/depositTypeList',
    walletList: url + '/wallet/walletList',
    kyc : url + '/pub/memberKYCUpload.php',
    event : url + '/event',
    getCtraderOverview :url + '/product/thGroupInvestmentOverview',
    purchaseInvestment : url + '/product/purchaseTHGroupInvestment',
    
    
}

export { url, api };