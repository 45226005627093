import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { Accordion, Card } from 'react-bootstrap';
import { url, api } from "../../../utils/api";
import ReactPaginate from "react-paginate"
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import Loadingv1 from '../../components/Loading/Loadingv1';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FaSpinner,FaTimes,FaChevronLeft, FaChevronRight,FaAngleDoubleLeft } from "react-icons/fa";
import { set } from "date-fns";
import { numberWithCommas } from "../../../utils/function";
import Select from "react-select";
const customStyles = {
   control: (base, state) => ({
       ...base,
       background: "transparent",
       border: "none",
       boxShadow: state.isFocused ? null : null,
       color: "#000063",
       marginBottom: "10px"

   }),
   menu: (base) => ({
       ...base,
       borderRadius: 0,
       marginTop: 0,
       //   background: "linear-gradient(180deg, rgba(20, 73, 78, 0.8) 50%, rgba(20, 73, 78, 0.9) 100%)",
       backdropFilter: "blur(100px)",
       color: "black",
       borderRadius: "14px",
       backgroundColor:"#EBEBEB",
       left: 3,
       marginTop: 3
   }),
   menuList: (base) => ({
       ...base,
       padding: 0
   }),
   singleValue: (base) => ({
       ...base,
       color: "#000063",
       top: window.innerWidth > 667 ? "58%" : "50%",
       lineHeight:"30px"
   })
};
const Option = [
   { value: "1:100", label: '1:100' },
   { value: "1:200", label: '1:200' },
   { value: "1:300", label: '1:300' },
   { value: "1:400", label: '1:400' },
   { value: "1:500", label: '1:500' },
   { value: "1:600", label: '1:600' },
   { value: "1:700", label: '1:700' },
   { value: "1:800", label: '1:800' },
   { value: "1:900", label: '1:900' },
   { value: "1:1000", label: '1:1000' },
]
const TopupInvestment = (props) => {
   const [
      activeaccordionRoundedStylish,
      setActiveaccordionRoundedStylish,
   ] = useState(0);
   const productPerpage = 10;
   const [totalRecord, setTotalRecord] = useState(0);
   const [wallet,setWallet] = useState(null);
   const [result, setResult] = useState([]);
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [content, setContent] = useState(null);
   const [loading, setLoading] = useState(false);
   const [successData, setSuccessData] = useState('');
   const [confirmationModal, setConfirmationModal] = useState(false);
   const [secondaryPasswordModal, setSecondaryPasswordModal] = useState(false);
   const [secondaryPassword, setSecondaryPassword] = useState(null);
   const [showPassword, setShowPassword] = useState(false);
   const [loadingPayment, setLoadingPayment] = useState(false);
   const [successModal, setSuccessModal] = useState(false);
   const [packageSelected, setPackageSelected] = useState('')
   const [investAmountModal, setInvestAmountModal] = useState('');
   const [errors, setErrors] = useState({ selectedInvestAmount: '' , leverage : ''});
   const [selectedInvestAmount, setSelectedInvestAmount] = useState(null);
   const [productName, setProductName] = useState(null);
   const [orderId, setOrderId] = useState(null);
   const [withdrawalProfitModal, setWithdrawalProfitModal] = useState(false);
   const [withdrawalAmount, setWithdrawalAmount] = useState(null);
   const [withdrawInfo, setWithdrawInfo] = useState('');
   const [actionType, setActionType] = useState('');
   const [leverageModal,setLeverageModal] = useState(false); 
   const [leverage, setLeverage] = useState('');
   const [leverageOption, setLeverageOption] = useState('');
   useEffect(() => {
      getOverview();
      getLeverage();
  }, [pageNumber]);

  const closeSuccessModal = () => {
   if(withdrawalAmount){
      setWithdrawalProfitModal(false);
      setActionType('Withdrawal')
      setSecondaryPasswordModal(true);
   }
   else{
      swal(translate(props.lang, 'Error'), translate(props.lang,"Please enter withdrawal amount"), "error");
   }
}

const openWithdrawalModal = (orderID) =>{
   setOrderId(orderID);
   setWithdrawalProfitModal(true);
}



  const openPopUp = (type, orderId) =>{
    setOrderId(orderId)
    setInvestAmountModal(true); 
    setActionType(type)
  }

  const confirmPassword = () => {
   setLoadingPayment(true);
   if (secondaryPassword) {
      if(actionType === "Withdrawal"){
         fetch(api.product + `/ctrader/packageWithdrawal?orderId=${orderId}&transactionPassword=${secondaryPassword}&withdrawAmount=${withdrawalAmount}`, {
            method: "POST",
            headers: new Headers({
               "Content-Type": "application/json",
               "Content-Language" : props.lang,
               "Authorization": "Bearer " + props.auth.auth.token
            }),
            })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {    
                let result = JSON.parse(responseJson[1]);
                if(responseJson[0]){
                  getOverview();
                  setLoadingPayment(false);
                  setSecondaryPasswordModal(false);
                  swal(translate(props.lang, 'Success'), result.message, "success");

                }
                else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
                  setLoadingPayment(false);
               }
            }).catch(error => {
                console.error("error",error);
            });
         }
      
      else{
      let data;
      let request;
         if(actionType === "Activate"){
            data = {
               "investmentAmount": selectedInvestAmount,
               "transactionPassword": secondaryPassword,
           }
           request = api.purchaseInvestment;
         } 
         else{
            data = {
               "orderId": orderId,
               "transactionPassword": secondaryPassword,
               "amount" : selectedInvestAmount
           }
           request = api.product + `/ctrader/topupCapital`
         }
       
         fetch(request, {
            method: "POST",
            headers: new Headers({
               "Content-Type": "application/json",
               "Content-Language" : props.lang,
               "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
            })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {    
                let result = JSON.parse(responseJson[1]);
                if(responseJson[0]){
                  getOverview();
                  setLoadingPayment(false);
                  setSecondaryPasswordModal(false);
                  swal(translate(props.lang, 'Success'), result.message, "error");
                  setWithdrawalAmount('');
                 
                }
                else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
                  setLoadingPayment(false);
               }
            }).catch(error => {
                console.error("error",error);
            });
         }
   } 
   else {
      // setSecondaryPasswordModal(false)
      // setFailModal(true);
      setLoadingPayment(false);
      swal(translate(props.lang,"Oops"), translate(props.lang,"Please enter secondary password."), "error");
   }
}
  

  const getOverview = () => {
      setLoading(true);
      fetch(api.getCtraderOverview, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language": props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
          .then(response => Promise.all([response.ok, response.text()]))
          .then(responseJson => {
              let result = JSON.parse(responseJson[1]);
              if (responseJson[0]) {
                  setContent(result)  
                  setLoading(false);
                }
                
               else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
                  setLoading(false);
              }
          }).catch(error => {
              console.error("error", error);
          });
  }
   const openSecondaryPasswordModal = () =>{
      if(parseFloat(selectedInvestAmount) >= 100){
         setInvestAmountModal(false);
         setSecondaryPasswordModal(true);
      }
      else{
         swal(translate(props.lang, "Error"), translate(props.lang,"Please Enter Investment Amount With Minimum 100 USD."), "error");
      }
     }
 
   const openLeverageModal = (orderId) =>{
      setLeverageModal(true);
      setOrderId(orderId)

   }
   const getLeverage = () =>{
      fetch(api.product + '/getLeverageList', {
         method: "GET",
         headers: new Headers({
             "Content-Type": "application/JSON",
             "Content-Language" : props.lang,
             "Authorization": "Bearer " + props.auth.auth.token
         }),
     })
     .then(response => response.json())
     .then(responseJson => {
         setLeverageOption(responseJson.leverageList);
     }).catch(error => {
         console.log("Error:", error);
     });
   }
      

   const submit = (e) =>{
      if(leverage !== ''){
         setLoading(true);
         let data = {
             "orderId": orderId,
             "leverage": leverage.value,
         }
         fetch(api.product + '/ctrader/setLeverage', {
             method: "POST",
             headers: new Headers({
                 "Content-Type": "application/json",
                 "Content-Language": props.lang,
                 "Authorization": "Bearer " + props.auth.auth.token
             }),
             body: JSON.stringify(data)
         })
             .then(response => Promise.all([response.ok, response.text()]))
             .then(responseJson => {
                 let result = JSON.parse(responseJson[1]);
                 if (responseJson[0]) {
      
                    
                     setLeverageModal(false);
                     setLoading(false);
                     getOverview();
                     swal(translate(props.lang, 'Success'), result.message, "success");
                 }
                 else {
                     setLoading(false);
                     swal(translate(props.lang, 'Error'), result.message, "error");
                 }
 
             }).catch(error => {
                 console.log("Error:", error);
             });
      }
      else{
         swal(translate(props.lang, 'Error'), translate(props.lang,"Please select leverage"), "error");
      }
     
    
      //     method: "POST",
      //     headers: new Headers({
      //         "Content-Type": "application/json",
      //         "Content-Language": props.lang,
      //         "Authorization": "Bearer " + props.auth.auth.token
      //     }),
      //     body: JSON.stringify(data)
      // })
      //     .then(response => Promise.all([response.ok, response.text()]))
      //     .then(responseJson => {
      //         let result = JSON.parse(responseJson[1]);
      //         if (responseJson[0]) {
              
      //             setLeverageModal(false);
      //             setLoading(false);
      //         }
      //         else {
      //             setLoading(false);
      //             swal(translate(props.lang, 'Error'), result.message, "error");
      //         }

      //     }).catch(error => {
      //         console.log("Error:", error);
      //     });
   }

   return (
      <>
           <Row className="justify-content-center justify-content-md-start mt-3 pb-5">
            <Col lg={6} md={12} sm={12} xs={12}  className="wallet mb-3 mt-3 mt-md-0">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     <Row className="align-items-center">
                        <Col lg={12} md={6} xs={12}  sm={12}>
                       
                           {/* <div className="d-none d-md-block"> */}
                            
                           {/* </div> */}

                           {/* <div className="d-block d-md-none"> */}
                           <Row className="align-items-center">
                              <Col lg={12} md={12} sm={5} xs={5}>
                                 <div className="d-flex align-items-center  justify-content-center justify-content-md-start">
                                    <div className="mb-0 mb-md-4">
                                       <img src={require('../../../images/wallet/usdtWallet.png').default} className="img-fluid card-icon" />
                                    </div>
                                 </div>
                              
                              </Col>
                              <Col lg={12} md={12} sm={7} xs={7}>
                              <div className="ms-0 ms-md-2 mb-2 mt-2 mt-md-0">
                                    {/* <span className="wallet-name text-capitalize">{translate(props.lang,"USDT")}</span> */}
                                    <div>
                                       <span className="wallet-name">{translate(props.lang,"USD Wallet")}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2">
                                       <div>
                                          <span className="big-number">{content ? numberWithCommas(content.usdtBalance) : 0}</span>
                                       </div>
                                       <div className="mb-0 mb-md-2 ms-0 ms-md-2 mt-0 mt-md-3">
                                          <span className="currency"> USD</span>     
                                       </div>          
                                  </div>
                              </div> 
                              </Col>
                           </Row>
                           {/* </div> */}
                        </Col>
                     </Row>
                  </div>
               </div>
            </Col>
         
            <Col lg={6} md={12} sm={12} xs={12}  className="wallet mb-3">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     <Row className="align-items-center">
                              <Col lg={12} md={12} sm={5} xs={5}>
                                 <div className="d-flex align-items-center  ms-2 justify-content-center justify-content-md-start">
                                    <div className="mb-0 mb-md-4">
                                       <img src={require('../../../images/wallet/cTraderBalance.png').default} className="img-fluid card-icon" />
                                    </div>
                                 </div>
                              
                              </Col>
                              <Col lg={12} md={12} sm={7} xs={7}>
                                 <div className="ms-0 ms-md-2 mb-2 mt-2 mt-md-0">
                                          <span className="wallet-name" style={{textTransform:"none"}}>{translate(props.lang,"cTrader Balance")}</span>
                                          
                                          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2">
                                             <div>
                                                <span className="big-number">{content ? numberWithCommas(content.cTraderBalance) : 0}</span>
                                             </div>
                                             <div className="mb-0 mb-md-2 ms-0 ms-md-2 mt-0 mt-md-3">
                                                <span className="currency">USD</span>
                                             </div>
                                          </div>         
                                 </div> 
                              </Col>
                           </Row>
                     </div>
               </div>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}  className="wallet mb-3">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     
                     <Row className="align-items-center px-2 py-3">
                           <Col lg={6} md={6} sm={12} xs={12}>
                                
                                 <span className="cTraderID-Title">{translate(props.lang,"cTrader Login ID")}</span>
                                 <div>
                                    <span className="cTraderID">{content ? Object.keys(content.investmentDetails).length !== 0 ?  content.investmentDetails.cTraderId  : "-" :  "-"}</span>
                                 </div>
                                 
                           </Col>
                           <Col lg={6} md={6} sm={12} xs={12} className="mt-3 mt-md-0 order-3 order-md-2">
                                 {content  ? (
                                 <>
                                 {Object.keys(content.investmentDetails).length !== 0 ? (
                                 <Row className="justify-content-start justify-content-md-end">
                                    <Col lg={6} md={6} xs={6} sm={6}>
                                       <div className="">
                                          <div className="choose-package-btn py-3 text-center" onClick={()=>openPopUp("Purchase",content.investmentDetails.orderId)}>
                                             <span className="choose-package-text">{translate(props.lang,"Top Up")}</span>
                                          </div>
                                       </div>
                                    </Col>
                                    <Col>
                                       <div className="">
                                          <div className="withdrawal-btn py-3 text-center" onClick={()=>openWithdrawalModal(content.investmentDetails.orderId)}>
                                             <span className="withdrawal-btn-text-topUp">{translate(props.lang,"Withdrawal")}</span>
                                          </div>
                                       </div>
                                    </Col>
                                 </Row>
                                 ):
                                 <Row className="justify-content-start justify-content-md-end">
                                    <Col lg={6} md={6} xs={6} sm={6}>
                                       <div className="">
                                          <div className="choose-package-btn py-3 text-center" onClick={()=>openPopUp("Activate", "")}>
                                             <span className="choose-package-text">{translate(props.lang,"Activate")}</span>
                                          </div>
                                       </div>
                                    </Col>   
                                            
                                 
                                 </Row>
                                 }
                                 </>
                                 ):null}
                           </Col>
                           <Col lg={6} md={6} sm={12} xs={12} className="mt-3 order-2 order-md-3">
                                 
                                 {content  ? (
                                 <>
                                 {Object.keys(content.investmentDetails).length !== 0 ? (
                                 <>
                                    <div>
                                       <span className="cTraderID-Title">{translate(props.lang,"Leverage")}</span>
                                    </div>
                                    <div onClick={()=>openLeverageModal(content.investmentDetails.orderId)}>
                                    <span className="cTraderID">{content ? Object.keys(content.investmentDetails).length !== 0 ?  content.investmentDetails.leverage ?  content.investmentDetails.leverage : "-": "-" :  "-"}</span> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mb-3 ms-2 cursor-pointer">
                                       <path d="M23.0272 1.25981C22.3995 0.44736 21.5652 0 20.6779 0C19.7906 0 18.9562 0.44736 18.3289 1.25981L6.73828 16.2666C6.6427 16.3904 6.57404 16.5485 6.54327 16.7254L5.60852 22.1205H0.72583C0.325012 22.1205 0 22.5413 0 23.0602C0 23.5792 0.325012 24 0.72583 24H6.18933C6.24408 24 6.297 23.9915 6.34827 23.9768L11.0821 22.602C11.2189 22.5624 11.3406 22.473 11.4362 22.3493L23.027 7.34291C23.6545 6.53045 24 5.45034 24 4.30124C24 3.15238 23.6545 2.07227 23.0272 1.25981ZM7.14844 21.8187L7.67102 18.8033L9.47736 21.1421L7.14844 21.8187ZM10.9233 20.3559L8.27802 16.9312L18.1273 4.17891L20.7726 7.60369L10.9233 20.3559ZM22.0007 6.01387L21.7991 6.27489L19.1537 2.84987L19.3553 2.58885C19.7086 2.13154 20.1782 1.87953 20.6779 1.87953C21.1776 1.87953 21.6473 2.13154 22.0007 2.58885C22.3541 3.04641 22.5483 3.6545 22.5483 4.30124C22.5483 4.94822 22.3539 5.55631 22.0007 6.01387Z" fill="url(#paint0_linear_2088_13955)"/>
                                       <defs>
                                       <linearGradient id="paint0_linear_2088_13955" x1="-0.0245249" y1="12.0916" x2="23.9755" y2="12.0916" gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#AF0C15"/>
                                       <stop offset="1" stop-color="#000063"/>
                                       </linearGradient>
                                       </defs>
                                       </svg>
                                 </div>
                                 </>
                                 ):null}
                                 </>
                                 ):null}
                              
                                
                                 
                           </Col>
                     </Row>
                  
                     </div>
               </div>
            </Col>
            <Modal className="fade investModal" show={investAmountModal} onHide={()=>{setInvestAmountModal(false);{setSelectedInvestAmount('')}}} centered>
               <Modal.Body>
                  <div className="py-4">
                    <div className="position-absolute smallCloseBtnModal cursor-pointer mt-2 me-1">
                        <div onClick={() => {setInvestAmountModal(false);{setSelectedInvestAmount('')}}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Col lg={12} md={12} sm={12} xs={12} className="text-center pb-4">
                        <Modal.Title>{translate(props.lang, "investment amount")}</Modal.Title>
                     </Col>
                     <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="pb-3">
                           <div className={`input-group py-1 ${errors.selectedInvestAmount ? 'errorBorder' : ''}`}>
                              <input
                                 type="text"
                                 className="form-control input-blue font-weight-600 font-32"
                                 value={selectedInvestAmount}
                                 onChange={(e) => setSelectedInvestAmount(e.target.value)}
                              />
                               <div className="px-3">
                                 <span className="invest-currency">USD</span>
                              </div>
                              <div className="px-3">
                                 <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                              </div>
                           </div>
                        
                           <div>
                              <span className="error">{translate(props.lang, errors.selectedInvestAmount)}</span>
                           </div>
                        </Col>
                        <div className="pb-3">
                           <span className="font-weight-400 font-16 text-black-2">{translate(props.lang,"*Minimum amount: 100 USD")}</span>
                        </div>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                           <div className={`choose-package-btn py-3 text-center`} onClick={(e) => openSecondaryPasswordModal()}>
                              <span className="choose-package-text">{translate(props.lang, "Next")}</span>
                           </div>
                        </Col>
                     </Row>

                  </div>
               </Modal.Body>
            </Modal>
              


            <Modal className="fade successModel" show={successModal} onHide={() => {setSuccessModal(false);setSelectedInvestAmount('')}} centered>
            <div className="position-relative">
            <Modal.Header className="px-2 py-4">            
               </Modal.Header>
                <Modal.Body className="py-5">
                    <div className="text-center payment-success-container">
                        <img src={require('../../../images/investment/payment_success.png').default} className="img-fluid payment-success-img" />
                    </div>
                    <div className="text-center pt-4 mb-2">
                     {actionType === "Activate" ? (
                        <span className="withdraw-subscript-text">{translate(props.lang, "Activation Successful")}</span>
                     ):
                        <span className="withdraw-subscript-text">{translate(props.lang, "Top Up Successful")}</span>
                     }
                    </div>
                    
                    <div className="px-3">
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="choose-package-btn py-3 text-center" onClick={() =>{setSuccessModal(false);setSelectedInvestAmount('')}}>
                                    <span className="choose-package-text">{translate(props.lang, "Confirm")}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                  </Modal.Body>
               </div>
               </Modal>

               <Modal className="fade successModel" show={withdrawalProfitModal} centered>
                     <div className="position-absolute smallCloseBtnModal cursor-pointer mt-2 me-1">
                        <div onClick={() => { setWithdrawalProfitModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Modal.Body className="py-5">
                  <div className="text-center">
                     <span className="withdraw-title">{translate(props.lang,"Withdrawal")}</span>
                  </div>
                  <div className="px-3">
                     {/* <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "Available Balance")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{withdrawInfo ? withdrawInfo.earning : ""}</span>
                        </Col>
                     </Row> */}
                     {/* <Row className="justify-content-center align-items-center mt-3">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "Investment Package")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{withdrawInfo ? withdrawInfo.productName : ""}</span>
                        </Col>
                     </Row> */}
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "cTrader ID")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{content ? Object.keys(content.investmentDetails).length !== 0 ?  content.investmentDetails.cTraderId  : "-" :  "-"}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center pt-2 mt-4">
                        <span className="withdrawal-amount-title">{translate(props.lang, "withdrawal amount")}</span>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                              <div className="input-group">
                              <input
                                 type="text"
                                 className="form-control input"
                                 placeholder={translate(props.lang,"Enter Withdrawal Amount")}
                                 value={withdrawalAmount}
                                 onChange={(e) => setWithdrawalAmount(e.target.value)}
                              />
                              <button className="showTextBtn px-3">
                                    <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                                    <span className=" d-none d-lg-inline-block d-md-inline-block"></span>
                              </button>
                           </div>
                           <span className="error">{errors.withdrawalAmount}</span>
                        </Col>
                     </Row>
                     {/* <span className="withdrawal-req">{translate(props.lang,"*Withdraw will be charged 3% of total amount")}</span> */}
                     <Row className="justify-content-center align-items-center mt-5">    
                        <Col lg={6} md={6} sm={6} xs={6} className="px-1">
                           <div className="back-home-btn py-3 text-center" onClick={() => setWithdrawalProfitModal(false)} >
                              <span className="back-home-text">{translate(props.lang,"Cancel")}</span>
                           </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="px-1">
                           <div className="choose-package-btn py-3 text-center" onClick={()=>closeSuccessModal()}>
                              <span className="choose-package-text">{translate(props.lang,"Confirm")}</span>
                           </div>
                        </Col>
                     </Row>
                     {/* <div className="mt-3">
                        <div className="withdraw-tc text-center text-md-start pb-2">{translate(props.lang,"Withdraw T&C")}</div>
                        <div className="withdraw-tcdesc">{translate(props.lang,"CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.")}</div>
                     </div> */}
                  </div>
               </Modal.Body>
            </Modal>


            <Modal className="fade successModel" show={leverageModal} centered>
                     <div className="position-absolute smallCloseBtnModal cursor-pointer mt-2 me-1" >
                        <div onClick={() => { setLeverageModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Modal.Body className="py-5">
                  <div className="text-center">
                     <span className="withdraw-title">{translate(props.lang,"Leverage")}</span>
                  </div>
                  <div className="px-3">
                     <Row className="justify-content-center align-items-center mt-4 pt-2">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="withdraw-label">{translate(props.lang, "Trading Account")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="withdraw-value">{content ? Object.keys(content.investmentDetails).length !== 0 ?  content.investmentDetails.cTraderId  : "-" :  "-"}</span>
                        </Col>
                     </Row>
                    
                    
                     <Row className="justify-content-center align-items-center">
                       
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                        <div className={`input-border mt-1 ${errors.span ? 'errorBorder' : ''}`} id="leverage">
                              <Select
                                  options={leverageOption && leverageOption.map((item, i) => ({ value: item.key, label: item.value }))}
                                 onChange={(e) => setLeverage(e)}
                                 placeholder={translate(props.lang, "PLEASE SELECT")}
                                 className="form-control input-transparent font-weight-400 font-18"
                                 styles={customStyles}
                                                       
                              />
                            </div>
                           <span className="error">{errors.leverage}</span>
                        </Col>
                     </Row>
                     {/* <span className="withdrawal-req">{translate(props.lang,"*Withdraw will be charged 3% of total amount")}</span> */}
                     <Row className="justify-content-center align-items-center mt-5">    
                     
                        <Col lg={12} md={12} sm={12} xs={12} className="px-1">
                           <div className="choose-package-btn py-3 text-center" onClick={(e)=>submit(e)}>
                                 <span className="choose-package-text">{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang,"Confirm")}</span>
                           </div>
                        </Col>
                     </Row>
                     {/* <div className="mt-3">
                        <div className="withdraw-tc text-center text-md-start pb-2">{translate(props.lang,"Withdraw T&C")}</div>
                        <div className="withdraw-tcdesc">{translate(props.lang,"CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.")}</div>
                     </div> */}
                  </div>
               </Modal.Body>
            </Modal>
            <Modal className="fade investModal" show={investAmountModal} onHide={()=>{setInvestAmountModal(false);{setSelectedInvestAmount('')}}} centered>
               <Modal.Body>
                  <div className="py-4">
                    <div className="position-absolute smallCloseBtnModal cursor-pointer mt-2 me-1">
                        <div onClick={() => {setInvestAmountModal(false);{setSelectedInvestAmount('')}}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Col lg={12} md={12} sm={12} xs={12} className="text-center pb-4">
                        <Modal.Title>{translate(props.lang, "investment amount")}</Modal.Title>
                     </Col>
                     <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="pb-3">
                           <div className={`input-group py-1 ${errors.selectedInvestAmount ? 'errorBorder' : ''}`}>
                              <input
                                 type="text"
                                 className="form-control input-blue font-weight-600 font-32"
                                 value={selectedInvestAmount}
                                 onChange={(e) => setSelectedInvestAmount(e.target.value)}
                              />
                               <div className="px-3">
                                 <span className="invest-currency">USD</span>
                              </div>
                              <div className="px-3">
                                 <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                              </div>
                           </div>
                        
                           <div>
                              <span className="error">{translate(props.lang, errors.selectedInvestAmount)}</span>
                           </div>
                        </Col>
                        <div className="pb-3">
                           <span className="font-weight-400 font-16 text-black-2">{translate(props.lang,"*Minimum amount: 100 USD")}</span>
                        </div>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                           <div className={`choose-package-btn py-3 text-center`} onClick={(e) => openSecondaryPasswordModal()}>
                              <span className="choose-package-text">{translate(props.lang, "Next")}</span>
                           </div>
                        </Col>
                     </Row>

                  </div>
               </Modal.Body>
            </Modal>

            <Modal className="fade secondaryPasswordModal" show={secondaryPasswordModal} centered>
               <Modal.Body>
               <div className="py-4">
               <div className="position-absolute smallCloseBtnModal cursor-pointer mt-2 me-1">
                        <div onClick={() => {setSecondaryPasswordModal(false);{setSelectedInvestAmount('')}}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                  <div className="text-center mt-2">
                     <span className="secPassword-text">{translate(props.lang,"Transaction Password")}</span>
                  </div>
                  <div className="text-center pt-4">
                  
                        <div className="input-group">
                           <input type={showPassword ? 'text' : 'password'} className="form-control input" placeholder={translate(props.lang,"Enter Transaction Password")} onChange={(e)=>setSecondaryPassword(e.target.value)} />
                           {
                              showPassword ? (
                                 <button className="showTextBtn px-3" onClick={() => setShowPassword(false)}>
                                 <FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                                 </button>
                              ) : (
                                 <button className="showTextBtn px-3" onClick={() => setShowPassword(true)}>
                                 <FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                                 </button>
                              )         
                           }                             
                     
                     </div> 
                  </div>             
                  <div className="text-center mt-5">
                     <button className="choose-package-btn py-3 text-center w-100" onClick={(e) => confirmPassword()} disabled={loadingPayment}>
                        <span className="choose-package-text">{loadingPayment ? <FaSpinner className="fa-spin" /> : translate(props.lang,"Confirm")}</span>
                     </button>
                  </div>
                  </div>
               </Modal.Body>
            </Modal>
        
        
          
         </Row>
      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(TopupInvestment);
