
import React, { useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FaAngleDown } from "react-icons/fa";
import "../../../css/authheader.scss";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { faBell, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const lang = [
    { name: 'English', code: 'EN', img: require("../../../images/language/en.png").default },
    { name: '中文（简体）', code: 'ZH', img: require("../../../images/language/cn.png").default },
    // { name: 'Japanese', code: 'JA' },
    // { name: 'Korean', code: 'KR' },
    // { name: 'Vietnamese', code: 'VI' },
]

const AuthHeader = (props) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [language, setLanguage] = useState('');
    const changeLanguage = (language) => {
        setLanguage(language);
        dispatch({
            type: 'UPDATE_LANGUAGE',
            lang: language.code
        });
        localStorage.setItem('cgi-lang', language.code);
        setChecked(false);
    }

    const openLang = (e) => {
        setChecked(e.target.checked);
    }
    
   useEffect(() => {
    let cookie = localStorage.getItem('cgi-lang');
    if(cookie){
        let language = lang.filter(item => item.code === cookie);
        if(language){
            setLanguage(language[0]);
        }
    }
    else{
        setLanguage(lang[0n])
    }
    // getUserInfo();
    // getDialCode(selectedCountry);
 }, []);

    return (
        <div className="authHeader" id="auth">
            <Row className='justify-content-end justify-content-md-center me-1'>
                <Col lg={10} md={10} sm={4} xs={5}>
                    <Row className="align-items-center justify-content-end mt-2">
                        <Col xl={8} lg={8} md={8} sm={12} xs={12} className="text-center px-0">
                        <div className="m-0" id="languageDropdown">
                                <Dropdown
                                    as="li"
                                    className="nav-item notification_dropdown d-md-flex justify-content-end"
                                >
                                    <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link d-sm-flex pd box-none ai-icon i-false c-pointer"
                                    >
                                   <div className="language-bar px-4 py-2">
                                        {/* {quicklinks.map((item,i) => 
                                        (language === item.name ? (
                                            <img alt="images" width={25} src={item.img}/>
                                        ):null
                                        ))} */}
                                         <img alt="images" width={25} src={language.img}/>
                                        &nbsp;
                                        <span className="langmain-text">{language.name}</span>
                                        <img alt="images"  src={require("../../../images/common/arrowdown.png").default} className="ms-2"/>
                                    </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                    align="right"
                                    className="dropdown-menu dropdown-menu-end" style={{backgroundColor: "transparent"}}
                                >
                                <PerfectScrollbar className="widget-media dz-scroll bgGlass px-3 py-2">
                                    <ul className="timeline">
                                    {lang.map((item,i)=> (
                                    <li>
                                        <div
                                        className="timeline-panel"
                                        onClick={() => changeLanguage(item)}
                                        >
                                        <div className="flag">
                                            <img
                                            alt="images"
                                            src={
                                                item.img
                                            }
                                            />
                                        </div>
                                        <div className="media-body ms-2 pt-1">
                                            <h6 className="mb-0 lang-text">{item.name}</h6>
                                        </div>
                                        </div>
                                    </li>
                                    ))}

                                    </ul>
                                </PerfectScrollbar>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};
export default connect(mapStateToProps)(AuthHeader);