import React, { useState, useEffect,useCallback } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import "../../../css/wallet.scss";
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import { set } from "date-fns";
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import Loadingv1 from "../../components/Loading/Loadingv1";
import Select from "react-select";
import swal from "sweetalert";

const FiatDeposit = (props) => {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    let errorsObj = { paymentCurrency: '', depositAmount: '', paymentAmount: '', bankType: '' };
    const [paymentCurrency, setPaymentCurrency] = useState('');
    const [depositAmount, setDepositAmount] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [exchangeRate, setExchangeRate] = useState(0)
    const [bankList, setBankList] = useState([]);
    const [bankCode, setBankCode] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [chargeFee, setChargeFee] = useState('');
    const [showTimeout, setShowTimeout] = useState(0);
    const [existLocalStorage, setExistLocalStorage] = useState(false);
    useEffect(() => {
        getDepositInfo();
        const timerEndTime = localStorage.getItem('timerEndTime');
        if (timerEndTime) {
            const endTime = parseInt(timerEndTime, 10);
            startTimer(endTime);
        }
    }, [props.auth]);

    


    const startTimer = (endTime) => {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeRemaining = endTime - currentTime;

        if (timeRemaining > 0) {
            setShowTimeout(timeRemaining);
            setLoadingPayment(true); // Disable the "Confirm" button
            const timerInterval = setInterval(() => {
                const updatedTimeRemaining = localStorage.getItem('timerEndTime') - Math.floor(Date.now() / 1000);
                if (updatedTimeRemaining > 0) {
                    setShowTimeout(updatedTimeRemaining);
                } else {
                    setShowTimeout(0);
                    setLoadingPayment(false); // Enable the "Confirm" button
                    localStorage.removeItem('timerEndTime'); // Remove the timer end time
                    clearInterval(timerInterval);
                }
            }, 1000);
        }
    };



    const getDepositInfo = (e) => {
        setLoading(true)
        fetch(api.depositList, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setPaymentCurrency(result.currencyList)       
                    setChargeFee(result.depositCharge.value)       
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }
   

   
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "transparent",
          border: "none",
          boxShadow: state.isFocused ? null : null,
          color: "white",
          height: "3.5rem",
          padding: "7px"
        }),
        menu: (base) => ({
          ...base,
          borderRadius: 0,
          marginTop: 0,
          backdropFilter: "blur(100px)",
          color: "black",
          borderRadius: "14px",
        }),
        menuList: (base) => ({
          ...base,
          padding: 0
        }),
        singleValue: (base) => ({
          ...base,
          color: "#1b1b1b",
        })
      };

    const submit = (e) =>{
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        var onlyNumber = /^\d+$/;
        if (selectedCurrency === '') {
            errorObj.paymentCurrency = translate(props.lang, "This field is required");
            error = true;
        }
        if (depositAmount === '') {
            errorObj.depositAmount = translate(props.lang, "This field is required");
            error = true;
        }
        else if (!onlyNumber.test(depositAmount)) {
            errorObj.depositAmount = 'Only Numbers are allowed in this field';
            error = true;
        }
        if (bankCode === '') {
            errorObj.bankType = translate(props.lang, "This field is required");
            error = true;
        }

        if(paymentAmount === ''){
            errorObj.paymentAmount = translate(props.lang, "Please convert to payment amount from deposit amount");
            error = true;
        }
    
        setErrors(errorObj);
        if (error) return;
        setLoadingPayment(true);
        let data = {
            "walletType" : 60,
            "usdtAmount" : depositAmount,
            "currencyCode" : selectedCurrency,
            "bankCode" : bankCode
        }
        fetch(api.wallet + '/bankDeposit', {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
            let result = JSON.parse(responseJson[1]);
            if (responseJson[0]) {
               window.open(result.walletDeposit.returnUrl);
               setShowTimeout(300);     
               const endTime = Math.floor(Date.now() / 1000) + 300; // Set the timer end time (300 seconds in the future)
               localStorage.setItem('timerEndTime', endTime.toString()); // Store the timer end time in localStorage
               startTimer(endTime);

            }
            else {
                swal(translate(props.lang, 'Error'), result.message, "error");
                setLoadingPayment(false)

    
            }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const setValue = (e) => {
        setSelectedCurrency(e.value);
        setExchangeRate(e.rate);
        fetch(api.wallet + `/getBankTypeList?currencyCode=${e.value}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                  setBankList(result.bankList);
                }
                else {
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const convert = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        var onlyNumber = /^\d+$/;
        if (selectedCurrency === '') {
            errorObj.paymentCurrency = translate(props.lang, "This field is required");
            error = true;
        }
        if (depositAmount === '') {
            errorObj.depositAmount = translate(props.lang, "This field is required");
            error = true;
        }
        else if (!onlyNumber.test(depositAmount)) {
            errorObj.depositAmount = 'Only Numbers are allowed in this field';
            error = true;
        }
        setErrors(errorObj);
        if (error) return;
        setPaymentAmount(((exchangeRate * depositAmount) + ((exchangeRate * depositAmount) * chargeFee)).toFixed(2));
    }   

    return (
        <>
            {loading ? (
                <Loadingv1 />
            ) :
                <>
                    <Col lg={12} md={11} xs={12} sm={12}>
                        <Link to="/wallet/deposit" className="d-flex align-items-center mt-4 mt-md-0">
                            <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
                        </Link>
                        <div className="card card-black h-100 py-4 px-4 mt-3">
                            <div className="card-header align-items-center border-bottom-0 d-flex justify-content-center justify-content-md-start ">
                                <span className={`headerText`}>{translate(props.lang, "Fiat Deposit")}</span>
                            </div>
                            <div className="card-body align-items-center ">
                                <Row className="justify-content-center h-100">
                                    
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4" id="selectWithdrawal">
                                        <div className="form-group">
                                            <label className="mb-2">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Payment Currency")}</span>
                                            </label>
                                            <div className={`input-border ${errors.paymentCurrency ? 'errorBorder' : ''}`}>
                                                <Select
                                                    placeholder={translate(props.lang, "Select Payment Currency")}
                                                  
                                                    styles={customStyles}
                                                    options={paymentCurrency && paymentCurrency.map((item, i) => ({ value: item.middle, label: item.left, rate :item.right }))}
                                                    onChange={(e)=>setValue(e)}
                                                />
                                            </div>
                                            <span className="error">{translate(props.lang, errors.paymentCurrency)}</span>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4" id="selectWithdrawal">
                                        <div className="form-group">
                                            <label className="mb-2">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Bank Type")}</span>
                                            </label>
                                          
                                            <div className={`input-border ${errors.bankType ? 'errorBorder' : ''}`}>
                                                <Select
                                                    placeholder={translate(props.lang, "Select Bank Type")}
                                                   
                                                    styles={customStyles}
                                                    options={bankList && bankList.map((item, i) => ({ value: item.key, label: item.value}))}
                                                    onChange={(e)=>setBankCode(e.value)}
                                                />
                                             
                                            </div>
                                            <span className="error">{translate(props.lang, errors.bankType)}</span>
                                        </div>               
                                    </Col>
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4" >
                                        <div className="form-group">
                                            <label className="mb-2">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Deposit Amount")}</span>
                                            </label>
                                          
                                            <div className={`input-group ${errors.depositAmount ? 'errorBorder' : ''}`}>
                                            <input
                                                type="text"
                                                className="form-control input-transparent font-weight-400 font-16"
                                                placeholder={translate(props.lang,"Deposit Amount")}
                                                onChange={(e)=>setDepositAmount(e.target.value)}
                                            />
                                            <div className="px-3">
                                                <span className="currency">USD</span>
                                            </div>
                                                <div className="px-3 cursor-pointer" onClick={(e)=>convert(e)}>
                                                    <img src={require('../../../images/wallet/convert.png').default} className="img-fluid" style={{width:"30px"}}/>
                                                </div>
                                            </div>
                                            <span className="error">{translate(props.lang, errors.depositAmount)}</span>
                                        </div>               
                                    </Col>
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4">
                                        <div className="form-group">
                                            <label className="mb-2 ">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Payment Amount")}</span>
                                            </label>
                                               <div className={`input-group disabled`}>
                                                <input
                                                    type="text"
                                                    className="form-control input-transparent font-weight-400 font-16"
                                                    readOnly={true}
                                                    autoComplete="new-password"
                                                    onChange={(e)=>setPaymentAmount(e.target.value)}
                                                    value={paymentAmount}
                                                  
                                                />
                                                 <div className="px-4">
                                                    <span className="currency">{selectedCurrency}</span>
                                                </div>
                                            </div>
                                            <span className="error">{translate(props.lang, errors.paymentAmount)}</span>
                                        </div>
                                    </Col>
                                    {paymentAmount !== '' ? (
                                    <Col>
                                        <div className="py-3">
                                            <div>
                                                <span>{translate(props.lang,"Exchange Rate")} : 1 USD = {exchangeRate} {selectedCurrency}</span>
                                            </div>
                                            <div>
                                                <span>{translate(props.lang,"Converted Amount")} =  {selectedCurrency} {paymentAmount}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    ):null}
                                    <Row className="justify-content-end align-items-center mt-4">
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                            <button className="btn primary-btn btn-block font-weight-400 font-16" onClick={(e)=>submit(e)} disabled={loadingPayment}>
                                            {showTimeout > 0 ? <span>{showTimeout}</span> : <span>{translate(props.lang, "Confirm")}</span>}
                                            </button>
                                        </Col>
                                        {/* <Col>
                                            <div className="text-center mt-3">
                                                <span className={`font-weight-400 font-13 text-red-1`}>{translate(props.lang, "*Deposit will be charged 3.5% per transaction.")}</span>
                                            </div>
                                        </Col> */}
                                    </Row>
                                    
                                  
                                    {/* <div className="it-txt text-end d-none d-md-block">{translate(props.lang,"Total amount:")} 1000.00</div> */}

                                </Row>

                            </div>
                        </div>
                    </Col>
                    
                </>
            }
        </>

    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(FiatDeposit);
