import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { translate } from "../../../utils/translate";
function RegisterSuccess(props) {
  return (
    <div className="authincation h-100 p-meddle emailVerify">
      <div className="container h-100">
        <Row className="justify-content-center h-100 align-items-center">
          <Col lg={6} md={6} sm={11} xs={11}>
            <div className="authincation-content">
              <div className="row no-gutters justify-content-center">
                <div className="success-content">
                  <div className="auth-form">
                    <img src={require('../../../images/common/verify.png').default} className="img-fluid w-50" alt="logo" />
                    <div className="mt-4 text-center">
                      <h4 className={`font-weight-700 font-36 text-capitalize mb-4 title-gradient`}>{translate(props.lang, "thank you!")}</h4>
                    </div>
                    <Link to="/login">
                      <span className={`font-weight-400 font-16 text-capitalize text-grey-1`}>{translate(props.lang, "Back")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
};

export default connect(mapStateToProps)(RegisterSuccess);

