import { connect } from 'react-redux';
import "../../../css/loading.scss";
import { translate } from "../../../utils/translate";

const Loadingv1 = (props) => {
    return (
        <div className="loading2">
            <div className="preloader-container">
                <div className="preloader"></div>
                <div className="mt-3 text-center">
                    <span className="loading-text">{translate(props.lang, "Loading")}</span>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        errorMessage: auth.errorMessage,
        successMessage: auth.successMessage,
        showLoading: auth.showLoading,
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(Loadingv1);