import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter, useLocation, useHistory, Redirect } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";
import './css/custom.scss';
import './css/custom1.scss';

import ThankYou from './jsx/pages/Auth/ThankYou';
import Loadingv2 from './jsx/components/Loading/Loadingv2';

const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Auth/Login')), 500);
    });
});
const Registration = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Auth/Registration')), 500);
    });
});
const ForgotPassword = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Auth/ForgotPassword')), 500);
    });
});

function App(props) {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // let data = {
        //     "claims": {
        //         "iss": "tgc-server",
        //         "exp": 1667622342,
        //         "sub": "ZITING991210@GMAIL.COM",
        //         "jti": "qB_H-9zJMYGLLZERnr-xVQ",
        //         "userAgent": "PostmanRuntime/7.29.2",
        //         "id": "ff80808182a5ad6e0182a5c545fb00a2",
        //         "type": "MEMBER",
        //         "localeCode": "zh",
        //         "deviceName": "DEFAULT",
        //         "referralCode": "qqhz58sd",
        //         "username": "ziting991210@gmail.com",
        //         "accesses": [
        //             "ACT_ROLE_MEMBER",
        //             "ROLE_USER"
        //         ],
        //         "tokenExpiryDatetime": "2022-11-05T12:25:42+0800"
        //     },
        //     "messages": [],
        //     "notifToken": null,
        //     "language": "zh",
        //     "notifAccId": null,
        //     "token": "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0Z2Mtc2VydmVyIiwiZXhwIjoxNjY3NjIyMzQyLCJzdWIiOiJaSVRJTkc5OTEyMTBAR01BSUwuQ09NIiwianRpIjoicUJfSC05ekpNWUdMTFpFUm5yLXhWUSIsInVzZXJBZ2VudCI6IlBvc3RtYW5SdW50aW1lLzcuMjkuMiIsImlkIjoiZmY4MDgwODE4MmE1YWQ2ZTAxODJhNWM1NDVmYjAwYTIiLCJ0eXBlIjoiTUVNQkVSIiwibG9jYWxlQ29kZSI6InpoIiwiZGV2aWNlTmFtZSI6IkRFRkFVTFQiLCJyZWZlcnJhbENvZGUiOiJxcWh6NThzZCIsInVzZXJuYW1lIjoieml0aW5nOTkxMjEwQGdtYWlsLmNvbSIsImFjY2Vzc2VzIjpbIkFDVF9ST0xFX01FTUJFUiIsIlJPTEVfVVNFUiJdfQ.ikspzCXVOjLYCbe_XkbLchykMsHttlvEcb2spGParl4",
        //     "refreshToken": "3k22xrFPYpM3yWBaZXruXUWZ4RbUSkuNcLSK4tc9egsgEmF6r7",
        //     "status": "ACTIVE",
        //     "expireDate": new Date( new Date().getTime() + 3600 * 1000 )
        // }
        // localStorage.setItem('cgi-crm', JSON.stringify(data));
        if (localStorage.getItem('cgi-lang')) {
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: localStorage.getItem('cgi-lang'),
            });
        }
        checkAutoLogin(dispatch, props.history, location.pathname);
    }, [dispatch, props.history, location.pathname]);


    let routes = (
        <Switch>
            <Route path='/thankyou' component={ThankYou} />
            <Route path='/login' component={Login} />
            <Route path='/register' component={Registration} />
            <Route path='/forgot-password' component={ForgotPassword} />
            {props.isAuthenticated ? <Redirect to="/dashboard" /> : null}
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <Loadingv2 />
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <Loadingv2 />
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }

};

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        isAuthenticated: isAuthenticated(state),
        token: auth
    };
};

export default withRouter(connect(mapStateToProps)(App));

