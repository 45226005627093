import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import "../../../css/team.scss";
import "../../../css/tree.scss"
import { api } from "../../../utils/api";
import { Carousel } from 'react-bootstrap'
import $ from 'jquery';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import { replaceUrl } from "../../../utils/replaceUrl";


const groupCategory = ["IB", "SIB", "MIB"];

const Team = (props) => {
  const [teamInfo, setTeamInfo] = useState(null)

  useEffect(() => {
    getTeamInfo();
  }, []);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const getTeamInfo = (e) => {
    fetch(api.team + '/teamInfo', {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        "Content-Language": props.lang,
        "Authorization": "Bearer " + props.auth.auth.token
      }),
    })
    .then(response => Promise.all([response.ok, response.text()]))
    .then(responseJson => {         
        let result = JSON.parse(responseJson[1]);
        if(responseJson[0]){
          setTeamInfo(result);
        }
        else{
          swal(translate(props.lang, 'Error'), result.message, "error");
      }
      }).catch(error => {
        console.log("Error:", error);
      });
  }
  return (
    <>
      <div className="mt-3">
        {/* <div className="d-none d-md-block">
        <Row className="align-items-center">
      
        <Col lg={4}>
          <div className="card card-pool text-center">
            <Row className="align-items-center h-100">
            <Col>
              <div>
                <img src={require('../../../images/team/march.png').default} className="img-fluid" />
              </div>
              <div>
                <img src={require('../../../images/team/888.png').default} className="img-fluid"  />  
              </div>
              <div>
                <img src={require('../../../images/team/poolbonus.png').default} className="img-fluid" />
              </div>
            </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8}>
          <div className="card card-pool-red text-center">
              <Row className="align-items-center">
                  <Col lg={5} md={5}>
                    <div className="text-start ms-5">
                      <img src={require('../../../images/team/teamGift.png').default} className="img-fluid"  />  
                    </div>
                  </Col>
                  <Col lg={7} md={7}>
                  <div className="text-start">
                    <div>
                      <img src={require('../../../images/team/April.png').default} className="img-fluid" />
                    </div>
                    <div>
                      <img src={require('../../../images/team/888.png').default} className="img-fluid" />  
                    </div>
                    <div>
                      <img src={require('../../../images/team/poolbonus.png').default} className="img-fluid" alt="my rank" />
                    </div>
                  </div>
                  </Col>
              </Row>
          </div>
        </Col>
        </Row>
        </div>
          <div className="d-block d-md-none pb-5">
            <Carousel indicators id="carousel">
                           <Carousel.Item>
                           <Slider {...carouselSettings}>
                           <div>
                           <img
                              src={require('../../../images/team/bgMobile.png').default}
                              className='d-block w-100 dashbaord-carousel-img'
                              alt={`Slide`}
                           />
                           </div>
                           <div>
                             <img
                              src={require('../../../images/team/redBgMobile.png').default}
                              className='d-block w-100 dashbaord-carousel-img'
                              alt={`Slide`}
                           />
                           </div>
                           </Slider>
                           </Carousel.Item>
              </Carousel>
              </div>
        */}
        <Row className="align-items-start ">
          {/* <Col lg={4} md={6} sm={12} xs={12} className="">
            <div className="card card-black h-auto">
              <div className="card-body align-items-center">
                  <Row className="align-items-center  ms-1">
                    <Col lg={4} md={4} sm={4} xs={4} className="px-2">
                      <div className="mb-2">
                        <img src={teamInfo ? replaceUrl(teamInfo.rankImage) : ""} className="img-fluid teamicon" alt="my rank" />
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={8} xs={8}>
                      <div className="mt-0 mt-md-2">
                        <span className="text-blue-1 font-weight-600 font-20 text-capitalize">{translate(props.lang, "My Rank")}</span>
                      </div>
                      <div className="mt-0 mt-md-2">
                        <span className={`font-32 font-weight-400 text-uppercase text-black-1`}>{teamInfo ? teamInfo.rank: ""}</span>
                      </div>
                    </Col>
                  </Row>
                  </div>
              </div>
           
          </Col> */}
          <Col lg={4} md={6} sm={12} xs={12} className="mt-3 mt-md-0 mb-3">
          <div className="card card-black h-auto">
              <div className="card-body align-items-center">
                      <Row className="align-items-center ms-1 py-2 py-md-0">
                      <Col lg={4} md={4} sm={4} xs={4} className="">
                        <div className="text-center text-md-start">
                          <img src={require('../../../images/team/totalGroupMember.png').default} className="img-fluid team card-icon" alt="group member" />
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={8} xs={8}>
                      <div className="mt-0 mt-md-4">
                          <span className="teamTitle">{translate(props.lang, "total group members")}</span>
                        </div>
                        <div className="mt-2">
                          <span className={`font-32 font-weight-400 text-uppercase text-black-1`}>{teamInfo ? teamInfo.totalGroupMember : 0}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
              </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
          <div className="card card-black h-auto">
              <div className="card-body align-items-center">
                      <Row className="align-items-center  ms-1 py-2 py-md-0">
                      <Col lg={4} md={4} sm={4} xs={4} className="">
                        <div className="text-center text-md-start">
                        <img src={require('../../../images/team/totalActiveMember.png').default} className="img-fluid card-icon" alt="active member" />
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={8} xs={8}>
                      <div className="mt-0 mt-md-4">
                          <span className="teamTitle">{translate(props.lang, "total active members")}</span>
                        </div>
                        <div className="mt-2">
                          <span className={`font-32 font-weight-400 text-uppercase text-black-1`}>{teamInfo ? teamInfo.totalActiveMember : 0}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
              </div>  
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mb-3">
            <div className="card card-black h-auto">
              <div className="card-body align-items-center">
                  <Row className="align-items-center  ms-1 py-2 py-md-0">
                    <Col lg={4} md={4} sm={4} xs={4} className="">
                      <div className="text-center text-md-start">
                      <img src={require('../../../images/team/totalTeamDeposit.png').default} className="img-fluid card-icon" alt="team deposit" />
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={8} xs={8}>
                      <div className="mt-0 mt-md-4">
                        <span className="teamTitle">{translate(props.lang, "Total cTrader Balance")}</span>
                      </div>
                      <div className="mt-2">
                      <span className={`font-32 font-weight-400 text-uppercase text-black-1`}>{teamInfo ? teamInfo.totalTeamDeposit : 0}</span>
                      </div>
                    </Col>
                  </Row>
                  </div>
              </div>
           
          </Col>

          {/* <Col lg={3} md={6} sm={12} xs={12}>
          <div className="card card-black h-auto">
              <div className="card-body align-items-center">
                      <Row className="align-items-center ms-1">
                      <Col lg={4} md={4} sm={4} xs={4} className="px-2">
                        <div className="mb-2">
                          <img src={require('../../../images/team/profitSharing.png').default} className="img-fluid" alt="active member" />
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={8} xs={8}>
                        <div className="mt-0 mt-md-2">
                          <span className="text-blue-1 font-weight-600 font-20 text-capitalize">{translate(props.lang, "profit sharing")}</span>
                        </div>
                        <div className="mt-0 mt-md-2">
                          <span className={`font-32 font-weight-400 text-uppercase text-black-1`}>{teamInfo ? teamInfo.profitSharing : 0}</span>
                        </div>
                      </Col>
                    </Row>
                </div>
              </div>
          </Col> */}
          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <div className="card card-black h-auto">
              <div className="card-header align-items-center border-bottom-0 mt-4 ms-2 ms-md-0">
                <span className={`font-weight-600 font-22 text-blue-1 text-capitalize`}>{translate(props.lang, "my group brokers")}</span>
              </div>
              <div className="card-body align-items-center">
                <Row className="align-items-center">
                {
                  teamInfo && teamInfo.rankList.map((item, i) => (
                    // <div key={i} className="py-3 border-bottom d-flex">
                    //   <div className="d-flex align-items-center">
                    //     <div className="level-dot"></div>
                    //   </div>
                    //   <div className="w-100 px-4">
                    //     <span className={`font-weight-500 font-18 text-blue-1`}>{item}</span>
                    //   </div>
                    //   <div className="w-25 text-right">
                    //     <span className="font-weight-400 font-18 text-grey-1">0</span>
                    //   </div>
                    // </div>
                    <Col lg={4} md={4} xs={12} sm={12} className="pb-3 pb-md-0">
                      <div className="input-grey py-4">
                      <Row className="align-items-center">
                        <Col lg={6} md={6} xs={6} sm={6}>
                          <div className="ms-4">
                              <span className={`font-weight-500 font-36 text-blue-1`}>{item.left}</span>
                            
                            <div className="mt-3">
                              <span className="font-weight-400 font-24 text-grey-1">{item.middle}</span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6} md={6} xs={6} sm={6}>
                          <div className="text-end me-4">
                             
                              <img src={replaceUrl(item.right)} className="img-fluid team-icon" alt="active member" />
                          </div>
                        </Col>
                      </Row> 
                    </div>
                   </Col>
                  ))
                }
                </Row>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>

     

    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
};

export default connect(mapStateToProps)(Team);
