import React, { useState, useEffect,useContext } from "react";

import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FaBell, FaChevronDown, FaCopy } from 'react-icons/fa'
import $ from 'jquery';
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/empty-user.png";
// import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import { routes } from "../../../utils/route";
import { translate } from "../../../utils/translate";
import "../../../css/header.scss";
import { Row, Col, Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import AuthHeader from "./AuthHeader";
const language = [
  { name: 'English', code: 'EN', img: require("../../../images/language/en.png").default },
  { name: '中文（简体）', code: 'ZH_CN', img: require("../../../images/language/cn.png").default },
  // { name: 'Japanese', code: 'JA' },
  // { name: 'Korean', code: 'KR' },
  // { name: 'Vietnamese', code: 'VI' },
]

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [path, setPath] = useState("");
  const [header, setHeader] = useState("");
  const [success, setSuccess] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(ThemeContext);
  useEffect(() => {
    let currentRoute = [];
    let pathname = window.location.pathname.slice(1);
    currentRoute = routes.filter((i) => i.url === pathname);
    if (currentRoute.length > 0) {
      setHeader(translate(props.lang, currentRoute[0].name));
    }

    history.listen((location) => {
      setPath(location.pathname);
      currentRoute = routes.filter((i) => i.url === location.pathname.slice(1));
      if (currentRoute.length > 0) {
        setHeader(translate(props.lang, currentRoute[0].name));
      }
    });
    if(path === "/profile" || path === "/email-inbox"){
      setProfileModal(false);
    }
  
  }, [history, props.lang, path]);

  function copyToClipBoard() {
    var $temp = $("<input>");
    let value = window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode;
    if (value != '') {
      var $temp = $("<input>");
      $("body").append($temp);
      var dummy = $temp.val(value).select();
      dummy.focus();
      document.execCommand("copy");
      $temp.remove();
    }

    setSuccess(translate(props.lang, "Copied"));
    setTimeout(() => {
      setSuccess("");
    }, 1000);
  }

  const copyToClipBoardCode = () => {
    let value = props.auth.auth.claims.referralCode;
    var $temp = $("<input>");
    if (value != '') {
      var $temp = $("<input>");
      $("body").append($temp);
      var dummy = $temp.val(value).select();
      dummy.focus();
      document.execCommand("copy");
      $temp.remove();
    }
    
    
    setSuccess(translate(props.lang, "Copied"));
    setTimeout(() => {
      setSuccess("");
    }, 1000);
  }


  const closeProfileModal = () => {
    setProfileModal(false)

  }
  const changeLanguage = (language) => {
    dispatch({
      type: "UPDATE_LANGUAGE",
      lang: language,
    });
    localStorage.setItem("cgi-lang", language);
  };

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between header-right-container">
            <div className="header-left">
             
             
              {/* <div
                  className="ms-3 mt-3"
                  onClick={() => {
                    // setToggle(!toggle);
                    // openMenuToggle();
                  }}
              >
          
            <div className={`hamburger d-block d-md-none`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div> */}
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {header} */}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <div className="d-none d-md-block mt-md-0 mt-lg-2">
                <AuthHeader />
              </div>
          
              <div className="d-none d-md-block">
                <Dropdown as="li" className="nav-item dropdown header-profile ">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer" 
                  >
                    <div className="profile-img" >
                      <img
                        src={require("../../../images/avatar/empty-user.png").default} 
                        className="img-fluid"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="right"
                    className="mt-3 dropdown-menu dropdown-menu-end bgprofileSetting" 
                  >
                    <div className="d-flex flex-row align-items-center">
                      <div className="mx-1 w-25">
                        {props.auth.auth ? (
                          props.auth.auth.profilePicture ? (
                            <img alt="images" width={50} src={props.auth.profile.profilePicture} className="img-fluid dropDownProfile" />
                          ) : (
                            <img alt="images" src={avatar} className="img-fluid dropDownProfile" />
                          )
                        ) : (
                          <img alt="images"  src={require("../../../images/avatar/empty-user.png").default} className="img-fluid dropDownProfile" />
                        )}
                      </div>
                      <div className="w-75 mx-2">
                        <span className="profileName">{props.auth.auth.claims.username}</span><br />
                        {/* <span className="profileID">{props.auth.auth.claims.id}</span> */}
                      </div>
                    </div>
                    <hr />
                    <Link to="/profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="link-label ms-2">{translate(props.lang, "Profile")} </span>
                    </Link>
                    {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
                      <svg
                        id="icon-inbox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                        <polyline points="22,6 12,13 2,6" />
                      </svg>
                      <span className="link-label ms-2">{translate(props.lang,"Inbox")} </span>
                    </Link> */}
                    <LogoutPage />
                    <hr />
                    <div className="pb-3">
                      <span className="referral-label">{translate(props.lang, "Referral Code")}</span>
                      <div className="dropdown-borderbox px-1 py-2">
                        <Row className="align-items-center">
                          <Col lg={9} md={9} sm={8} xs={9}>
                            <span className="dropdown-referral-code ps-1">{props.auth.auth.claims.referralCode}</span>
                          </Col>
                          <Col lg={3} md={3} sm={4} xs={3}>
                            <div onClick={() => copyToClipBoardCode()} className="cursor-pointer text-center">
                              <FaCopy color="#012060" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="pb-3">
                      <span className="referral-label">{translate(props.lang, "Referral Link")}</span>
                      <div className="dropdown-borderbox px-1 py-2">
                        <Row className="align-items-center">
                          <Col lg={9} md={9} sm={8} xs={9} >
                            <span className="dropdown-referral-link">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                          </Col>
                          <Col lg={3} md={3} sm={4} xs={3}>
                            <div onClick={(e) => copyToClipBoard()} className="cursor-pointer text-center">
                              <FaCopy color="#012060" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  
                    {success ? (
                      <div>
                        <span className="text-success font-13 font-weight-400">{success}</span>
                      </div>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-block d-md-none">
                <Dropdown as="li" className="nav-item dropdown header-profile ">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer" 
                  >
                    <div className="profile-img"  onClick={(e)=>setProfileModal(true)}>
                      <img
                        src={require("../../../images/avatar/empty-user.png").default} 
                        className="img-fluid"
                      />
                    </div>
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu
                    align="right"
                    className="mt-3 dropdown-menu dropdown-menu-end bgprofileSetting" 
                  >
                  
                    {success ? (
                      <div>
                        <span className="text-success font-13 font-weight-400">{success}</span>
                      </div>
                    ) : null}
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </ul>
          </div>
        </nav>
      </div>
     
      
          <Modal className="fade profileModal px-4" onHide={closeProfileModal} show={profileModal} centered>
                <Modal.Body className="py-3">
                    <div className="profileIcon-div">
                          {props.auth.auth ? (
                            props.auth.auth.profilePicture ? (
                              <img alt="images" width={50} src={props.auth.profile.profilePicture} className="img-fluid dropDownProfile" />
                            ) : (
                              <img alt="images" src={avatar} className="img-fluid dropDownProfile" />
                            )
                          ) : (
                            <img alt="images"  src={require("../../../images/avatar/empty-user.png").default} className="img-fluid dropDownProfile" />
                          )}
                            <Button
                            variant=""
                            className="btn-close d-block mr-0 ml-auto profileCloseBtn"
                            onClick={() => setProfileModal(false)} 
                        >
                        </Button>
                    </div>
                    <div>
                      
                        <div className="mx-2 mt-5 text-center">
                          <span className="profileName">{props.auth.auth.claims.username}</span><br />
                          {/* <span className="profileID">{props.auth.auth.claims.id}</span> */}
                        </div>
                      </div>
                      <div className="d-flex mt-4">
                        <Link to="/profile" className="dropdown-item ai-icon text-center">
                          <svg
                            id="icon-user1"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          <div className="mt-2">
                            <span className="link-label ms-2">{translate(props.lang, "Profile")} </span>
                          </div>
                        </Link>
                    
                      {/* <Link to="/email-inbox" className="dropdown-item ai-icon text-center">
                        <svg
                          id="icon-inbox"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                          <polyline points="22,6 12,13 2,6" />
                        </svg>
                        <div className="mt-2">
                          <span className="link-label ms-2">{translate(props.lang,"Inbox")} </span>
                        </div>
                      </Link> */}
                      <LogoutPage />
                      </div>
                      <hr />
                      <div className="pb-3">
                        <span className="referral-label">{translate(props.lang, "Referral Code")}</span>
                        <div className="dropdown-borderbox px-1 py-2">
                          <Row className="align-items-center">
                            <Col lg={9} md={9} sm={8} xs={9}>
                              <span className="dropdown-referral-code ps-1">{props.auth.auth.claims.referralCode}</span>
                            </Col>
                            <Col lg={3} md={3} sm={4} xs={3}>
                              <div onClick={() => copyToClipBoardCode()} className="cursor-pointer text-center">
                                <FaCopy color="#012060" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="pb-3">
                        <span className="referral-label">{translate(props.lang, "Referral Link")}</span>
                        <div className="dropdown-borderbox px-1 py-2">
                          <Row className="align-items-center">
                            <Col lg={9} md={9} sm={8} xs={9} >
                              <span className="dropdown-referral-link">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                            </Col>
                            <Col lg={3} md={3} sm={4} xs={3}>
                              <div onClick={(e) => copyToClipBoard()} className="cursor-pointer text-center">
                                <FaCopy color="#012060" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Modal.Body>
          </Modal>
      
    
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(Header);
