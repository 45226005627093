import React, { useState,useRef  } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import "../../../css/setting.scss";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import LiveSupport from "../../components/Live/LiveSupport";
// import { DatePicker, MuiPickersUtilsProvider,DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


const Register = (props) => {
    let errorsObj = { name: '', dateTime: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [name, setName] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [loading, setLoading] = useState(false);
    const datePickerRef = useRef(null);

    const handleIconClick = () => {
      if (datePickerRef.current) {
        datePickerRef.current.open();
      }
    };

    const changePassword = () => {
        let check = true;
        const errorMsg = { ...errorsObj };
        if (!name) {
            errorMsg.name =translate(props.lang, "This field is required"); 
            check = false;
        }
        if (!dateTime) {
            errorMsg.dateTime = translate(props.lang, "This field is required");
            check = false;
        } 
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let data = {
              "name" : name,
              "registerDate" : dateTime
            };

            fetch(api.event + '/registerGasFreeEvent', {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0]) {
                        setName('');
                        setDateTime(null);
                        swal(translate(props.lang, 'Success'), result.message, "success");
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");

                    }
                }).catch(error => {
                    console.error("error", error);
                });
        } else {
            swal(translate(props.lang, 'Error'), translate(props.lang, 'Please fill in all required fields with valid information.'), "error");
        }

    }

    function onDateChange(e) {
        const formattedDate = dayjs(e).format('YYYY-MM-DD HH:mm:ss');
        setDateTime(formattedDate);
      }

      
    return (
        <>
            <div className="">
                <Row className="justify-content-center align-items-center">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        
                        <div className="card card-black h-100 py-4 px-4 mt-3">
                            <div className="card-header align-items-center border-bottom-0 text-center text-md-start">
                                <span className={`headerText`}>{translate(props.lang, "Register")}</span>
                            </div>
                            <div className="card-body align-items-center">
                                <Row className="justify-content-center align-items-start ">
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "Name")}</span>
                                        <div className={`input-group mt-2 ${errors.name ? 'errorBorder' : ''}`}>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder={translate(props.lang, "Enter Your Name")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.name)}</span>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "Booking of slots for sharing")}</span>
                                        <div className={`input-group mt-2 ${errors.dateTime ? 'errorBorder' : ''}`}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                   autoOk
                                                   clearable={false}
                                                   format="dd/MM/yyyy HH:mm:ss"
                                                   value={dateTime ? new Date(dateTime) : null}
                                                   placeholder={translate(props.lang, "Select Date And Time")}
                                                   onChange={(e) => onDateChange(e)}
                                                   className="form-control Disablehover"             
                                                   ref={datePickerRef}                
                                                   inputProps={{
                                                    className: 'custom-input',
                                                  }}
                                                  
                                                                                                
                                                />
                                            </MuiPickersUtilsProvider> */}
                                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker 
                                                    className="form-control custom-datetime-picker"  
                                                    onChange={(e) => onDateChange(e)}  
                                                    format="DD/MM/YYYY hh:mm A"
                                                    slotProps={{
                                                        textField: {placeholder : translate(props.lang, "Select Date And Time")},
                                                        
                                                    }}
                                                   
                                                />             
                                            </LocalizationProvider>

                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.dateTime)}</span>
                                    </Col>
                                </Row>
                                <div className="text-center form-group mt-3">
                                    <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={changePassword} disabled={loading}>
                                        {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Register")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(Register);
