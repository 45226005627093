import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from "../../../utils/translate";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { url, api } from "../../../utils/api";
import ComingSoon from "../ComingSoon";
import { Line } from "react-chartjs-2";
import moment from "moment";
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import ReactPaginate from "react-paginate"
var today = new Date()




var options = {
    legend: {
       labels: {
          usePointStyle: true,
          fontColor: "black"
       }
    },
    scales: {
        yAxes: [{
            ticks: {
                fontColor: "black",
               
            },
           
        }],
        xAxes: [{
            ticks: {
                fontColor: "black",
            }
            
        }]
    }
    
 };
const Portfolio = (props) => {
    const [content, setContent] = useState(null);
    const [loading,setLoading] = useState(false);
    const [chart, setChart] = useState(null)
    const [data,setData] = useState([]);
    const productPerpage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [windowSize, setWindowSize] = useState(getWindowSize())
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    useEffect(() => {
        getPortfolio();
        // getDialCode(selectedCountry);
    }, [pageNumber,props.lang]);
    const changePage = (event) => {
        setPageNumber(event.selected + 1);
     }

    const getPortfolio = () => {
        fetch(api.product + `/investmentOverview?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setContent(result)
                    setChart(result.profitLossChart)
                    let chart = {labels: [], datasets: [{ label: translate(props.lang,"Revenue"), data : [], fill: true,  backgroundColor: "rgba(75,192,192,0.2)",pointStyle: 'line', borderColor: "rgba(75,192,192,1)" }]}
                    let month = today.getMonth() + 1;
                    result.profitLossChart.map((item,i) => {
                        // if(i<month){
                       chart.labels.push(moment(item.key).format("DD/MM/YYYY"))
                        chart.datasets.map((x,y)=>{
                                x.data.push(item.value)
                        })
                        })
                        var pageCount = Math.ceil(result.totalRecord / productPerpage)
                        setPageCount(pageCount);
                        setData(chart)
                }
                else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }
    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-3 pb-3 mt-0-md pb-0">
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-bx cardBg pb-2">
                            <div className="card-header align-items-center border-bottom-0">
                                <span className="announcement-header">{translate(props.lang, "profit/ loss (day)")}</span>
                            </div>
                            <div className="card-body align-items-center pt-0 pb-3">
                                {!loading ? (
                                <Col lg={8} className="mx-auto">
                                     <div className="d-none d-md-block">
                                        <Line data={data} height={windowSize.innerWidth > 668 && windowSize.innerWidth < 991 ? 350 : 150} options={options} />
                                    </div>
                                    <div className="d-block d-md-none">
                                        <Line height={300} data={data} options={options} />
                                    </div>
                                    </Col>
                                ):null}
                            </div>
                        </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="card card-black h-100 py-4 px-4">
                        <div className="card-header align-items-center border-bottom-0">
                            <span className={`font-weight-600 font-20 text-capitalize text-blue-1`}>{translate(props.lang,"Investment Details")}</span>
                        </div>
                        <div className="card-body align-items-center pt-0 pb-3">
                            <Table responsive className="w-100 transparent-bg">
                                <thead>
                                    <tr>
                                        <th className={`font-weight-600 font-16 text-capitalize text-blue-1 `}>{translate(props.lang,"cTrader ID")}</th>
                                        <th className={`font-weight-600 font-16 text-capitalize text-blue-1`}>{translate(props.lang, "package")}</th>
                                        <th className={`font-weight-600 font-16 text-capitalize text-blue-1`}>{translate(props.lang, "Investment Amount (USD)")}</th>
                                        {/* <th className={`font-weight-600 font-16 text-capitalize text-blue-1`}>{translate(props.lang, "subscription period")}</th> */}
                                        <th className={`font-weight-600 font-16 text-capitalize text-blue-1`}>{translate(props.lang, "realized p/l %")}</th>
                                        <th className={`font-weight-600 font-16 text-capitalize text-blue-1`}>{translate(props.lang, "subscription status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content && content.investmentDetails.map((item, i) => (
                                        <tr>
                                            <td className={`font-weight-400 font-16 text-grey-1`}>{item.cTraderId}</td>
                                            <td className={`font-weight-400 font-16 text-grey-1`}>{item.productName}</td>
                                            <td className={`font-weight-400 font-16 text-grey-1`}>{item.packagePrice}</td>
                                            {/* <td className={`font-weight-400 font-14 text-grey-1`}>{item.period}</td> */}
                                            <td className={`font-weight-400 font-16 text-grey-1`}>{item.percentage}</td>
                                            <td className={`font-weight-400 font-16 status ${item.status.toLowerCase()}`}>{item.status}</td>
                                        </tr>
                                    ))}
                                {content && content.investmentDetails.length === 0 ? (
                                    <tr>
                                        <td colspan="6">{translate(props.lang, "No result found")}</td>
                                    </tr>
                                ) : null}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                        {content && content.investmentDetails.length > 0 ? (
                           <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                              previousLinkClassName={"pagination__previouslink text-blue-1"}
                              nextLinkClassName={"pagination__nextlink text-blue-1"}
                              disabledClassName={"pagination__link--disabled text-muted"}
                              activeClassName={"pagination__link--active text-white"}

                           />
                        ):null}
                  </div>
                    </div>
                    
                    </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Portfolio);
