import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import "../../../css/setting.scss";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import LiveSupport from "../../components/Live/LiveSupport";


const ChangePassword = (props) => {
    let errorsObj = { oldPassword: '', newPassword: '', confirmPassword: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const changePassword = () => {
        let check = true;
        const errorMsg = { ...errorsObj };
        if (!oldPassword) {
            errorMsg.oldPassword = translate(props.lang, "This field is required");
            check = false;
        }
        if (!newPassword) {
            errorMsg.newPassword = translate(props.lang, "This field is required");
            check = false;
     
        } else {
            if (newPassword.length < 6) {
                errorMsg.newPassword =  translate(props.lang, "New Password cannot less than 6 characters");
                check = false;
            }
        }

        if (!confirmPassword) {
            errorMsg.confirmPassword = translate(props.lang, 'This field is required');
            check = false;
        }
        if (newPassword && confirmPassword) {
            if (newPassword != confirmPassword) {
                errorMsg.confirmPassword = translate(props.lang, "Confirm password does not match with password");
                check = false;
            }
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let data = {
                "oldPassword": oldPassword,
                "newPassword": newPassword,
                "confirmNewPassword": confirmPassword,
            };

            fetch(api.user + '/changePassword', {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0]) {
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                        swal(translate(props.lang, 'Success'), result.message, "success");
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");

                    }
                }).catch(error => {
                    console.error("error", error);
                });
        } else {
            swal(translate(props.lang, 'Error'), translate(props.lang, 'Please fill in all required fields with valid information.'), "error");
        }

    }
    return (
        <>
            <div className="">
                <Row className="justify-content-center align-items-center">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <Link to="/setting" className="d-flex align-items-center mt-4 mt-md-0">
                            <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
                        </Link>
                        <div className="card card-black h-100 py-4 px-4 mt-3">
                            <div className="card-header align-items-center border-bottom-0 text-center text-md-start">
                                <span className={`headerText`}>{translate(props.lang, "Change Password")}</span>
                            </div>
                            <div className="card-body align-items-center">
                                <Row className="justify-content-center align-items-start ">
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "current password")}</span>
                                        <div className={`input-group mt-2 ${errors.oldPassword ? 'errorBorder' : ''}`}>
                                            <Input
                                                type={showPassword ? 'text' : 'password'}
                                                name="oldPassword"
                                                placeholder={translate(props.lang, "Enter Your Password")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                            <div className="px-3" onClick={() => setShowPassword(!showPassword)}>
                                                {
                                                    showPassword ? (<FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />) : (<FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />)
                                                }
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.oldPassword)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "new password")}</span>
                                        <div className={`input-group mt-2 ${errors.newPassword ? 'errorBorder' : ''}`}>
                                            <Input
                                                type={showNewPassword ? 'text' : 'password'}
                                                name="newPassword"
                                                placeholder={translate(props.lang, "Enter Your New Password")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            <div className="px-3" onClick={() => setShowNewPassword(!showNewPassword)}>
                                                {
                                                    showNewPassword ? (<FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />) : (<FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />)
                                                }
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.newPassword)}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-5">
                                        <span className={`font-weight-500 font-18 text-grey-1 text-capitalize`}>{translate(props.lang, "Confirm New Password")}</span>
                                        <div className={`input-group mt-2 ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                                            <Input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name="confirmPassword"
                                                placeholder={translate(props.lang, "Confirm New Password")}
                                                className="form-control input-transparent font-weight-400 font-16"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <div className="px-3" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {
                                                    showConfirmPassword ? (<FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />) : (<FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />)
                                                }
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.confirmPassword)}</span>
                                    </Col>
                                </Row>
                                <div className="text-center form-group mb-3">
                                    <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={changePassword} disabled={loading}>
                                        {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(ChangePassword);
