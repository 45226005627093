import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button } from 'reactstrap';
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { translate } from "../../../utils/translate";
import { numberWithCommas } from "../../../utils/function";
import { url, api } from "../../../utils/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import "../../../css/investment.scss";
import LiveSupport from '../../components/Live/LiveSupport';
import { FaSpinner,FaTimes,FaChevronLeft, FaChevronRight,FaAngleDoubleLeft } from "react-icons/fa";
import Loadingv1 from '../../components/Loading/Loadingv1';

const color = ["#ff4c4c", "#3195D3", "#25C6C6", "#DDB590"]

const InvestmentAuto = (props) => {
   const history = useHistory();
   const [selectedGod, setSelectedGod] = useState('0');
   const [selectedInvestAmount, setSelectedInvestAmount] = useState(null);
   const [investAmountModal, setInvestAmountModal] = useState(null);
   const [packageSelectModal, setPackageSelectModal] = useState(false);
   const [transferModel, setTransferModel] = useState(false);
   const [selectedWallet, setSelectedWallet] = useState(null);
   const [successModal, setSuccessModal] = useState(false);
   const [failModal, setFailModal] = useState(false);
   const [content, setContent] = useState(null);
   const [loading, setLoading] = useState(false);
   const [packagePrice, setPackagePrice] = useState(null);
   const [wallet, setWallet] = useState(null);
   const [secondaryPasswordModal, setSecondaryPasswordModal] = useState(false);
   const [secondaryPassword, setSecondaryPassword] = useState(null);
   const [productID, setProductID] = useState(null);
   const [deductAmount, setDeductAmount] = useState(null);
   const [errors, setErrors] = useState({ selectedInvestAmount: '' });
   const [minMaxInvestmentAmount, setMinMaxInvestmentAmount] = useState({ min: 0, max: 0 });
   const [gf, setGF] = useState(0);
   const [usdt, setUSDT] = useState(0);
   const [selectedInvestment, setSelectedInvestment] = useState(0);
   const [showPassword, setShowPassword] = useState(false);
   const [packageSelected, setPackageSelected] = useState('');
   const [currentIndex, setCurrentIndex] = useState(0);
   const [gasfeeModal, setGasFeeModal] = useState(false);
   const [gasfeeData,setGasFeeData] = useState('')
   const [loadingPayment, setLoadingPayment] = useState(false);
   const [successData,setSuccessData] = useState('');
   const [strategyId, setStrategyId] = useState('');



   useEffect(() => {
      getInvestmentList();
      // getPackagePrice();

   }, []);
   const TopUpGasFee = () => {
      history.push('/wallet/internalTransfer')
   }

   const backToInvestmentSelect = () => {
      setGasFeeModal(false)
      setPackageSelectModal(true)
   }
  
   const getInvestmentList = () => {
      setLoading(true);
      fetch(api.investment, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {
            setLoading(false);
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0]) {
            let gf = result.walletList.filter(item => item.walletType === 'GF');
            let usdt = result.walletList.filter(item => item.walletType === 'USDT');
            if (gf.length > 0) {
               setGF(gf[0].walletBalance);
            }

            if (usdt.length > 0) {
               setUSDT(usdt[0].walletBalance);
            }
            setContent(result.investmentList[1]);
            setWallet(result.walletList)
            // console.log(result.investmentList[1].strategyList[0].productId,"productId")
            setProductID(result.investmentList[1].productId)
            setStrategyId(result.investmentList[1].strategyList[0].strategyId)
         }
            else {
               swal(translate(props.lang, 'Error'), result.message, "error");
            }
         }).catch(error => {
            console.log("Error:", error);
         });
   }
   const getPackagePrice = () => {
      setLoading(true);
      fetch(api.package, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
         .then(response => response.json())
         .then(responseJson => {
            setLoading(false);
            setPackagePrice(responseJson);
         }).catch(error => {
            console.log("Error:", error);
         });
   }

   const openModal = (e) => {
      if (selectedInvestment !== '') {
         openPackageModal();   
      } else {
         swal(translate(props.lang, "Error"), translate(props.lang,"Please select package."), "error");
      }
   }

   const closeModal = () => {
      const errorObj = { ...errors };
      errorObj.selectedInvestAmount = "";
      setInvestAmountModal(false);
      setErrors(errorObj);
   }

   const selectInvestment = (i, productId, strategyId) => {
      if (i === selectedInvestment) {
         // setSelectedInvestment('')
         // setStrategyId('')
      } else {
         setSelectedInvestment(i)
      } setStrategyId(strategyId)
   }

   const openPackageModal = () => {
      const errorObj = { ...errors };
         setTimeout(() => {
            fetch(api.product + `/getInvestment?productId=${productID}&strategyId=${strategyId}`, {
               method: "GET",
               headers: new Headers({
                  "Content-Type": "application/JSON",
                  "Content-Language" : props.lang,
                  "Authorization": "Bearer " + props.auth.auth.token
               }),
         })
         .then(response => Promise.all([response.ok, response.text()]))
         .then(responseJson => {
               setLoading(false);
               let result = JSON.parse(responseJson[1]);
               if(responseJson[0]) {
                  closeModal();
                  setPackageSelectModal(true);
                  setPackageSelected(result.investment)
               }
               else {
                  swal(translate(props.lang, 'Error'), result.message, "error");
               }
         }).catch(error => {
               console.log("Error:", error);
         });
            setPackageSelectModal(true);
         }, 500);
   }



   const selectWallet = (i) => {
      if (i === selectedWallet) {
         setSelectedWallet(null)
      } else {
         setSelectedWallet(i)
      }
   }

   const confirmPayment = () => {
      fetch(api.product + `/getGasFeeAmount?productId=${productID}&strategyId=${packageSelected.strategyId}`, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/json",
            "Content-Language" : props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
         })
         .then(response => Promise.all([response.ok, response.text()]))
         .then(responseJson => {    
             let result = JSON.parse(responseJson[1]);
             if(responseJson[0]){
               if(result.sufficientGas){
                  setPackageSelectModal(false);
                  setInvestAmountModal(true)
               }
               else{
                  setGasFeeData(result)
                  setPackageSelectModal(false);
                  setGasFeeModal(true)
               }
             }else{
               setLoading(false);
               swal(translate(props.lang,'Opps'), result.message, "error");
             }
         }).catch(error => {
             console.error("error",error);
         });
      
   }


   const closeSuccessModal = () => {
      setSuccessModal(false);
   }

   const confirmPassword = () => {
      setLoadingPayment(true);
      if (secondaryPassword) {
            let data = {
               "productId": productID,
               "investmentAmount": selectedInvestAmount,
               "transactionPassword": secondaryPassword,
               "strategyId": packageSelected.strategyId
           }
            fetch(api.product + `/purchaseInvestment`, {
               method: "POST",
               headers: new Headers({
                  "Content-Type": "application/json",
                  "Content-Language" : props.lang,
                  "Authorization": "Bearer " + props.auth.auth.token
               }),
               body: JSON.stringify(data)
               })
               .then(response => Promise.all([response.ok, response.text()]))
               .then(responseJson => {    
                   let result = JSON.parse(responseJson[1]);
                   if(responseJson[0]){
                     setLoadingPayment(false);
                     setSecondaryPasswordModal(false)
                     // setTransferModel(true)
                     setSelectedInvestAmount('');
                     setSecondaryPassword('');
                     setSuccessData(result.product)
                     setSuccessModal(true);
                     window.open(result.product.embeddedLink,'_blank');
                     // setTimeout(() => {
                     //    setTransferModel(false)
                       
                     // }, 2000);
                   }else{
                     swal(translate(props.lang,'Oops'), result.message, "error");
                     setLoadingPayment(false);
                   }
               }).catch(error => {
                   console.error("error",error);
               });
        
      } else {
         // setSecondaryPasswordModal(false)
         // setFailModal(true);
         setLoadingPayment(false);
         swal(translate(props.lang,'Oops'), translate(props.lang,"Please enter secondary password."), "error");
      }
   }

   const backHome = () => {
      history.push('/dashboard')
   }

   const closeFailModal = () => {
      setFailModal(false);
   }

   const prevSlide = (i,item) => {
      if(currentIndex === 0 ){
         setCurrentIndex(2);
         setSelectedInvestment(2);
         setStrategyId(item[2].strategyId)
      }else{
         setCurrentIndex(currentIndex-1);
         setSelectedInvestment(currentIndex-1);
         setStrategyId(item[currentIndex-1].strategyId)
      }
    }
  
    const nextSlide = (i,item) => {
      if(currentIndex === content.strategyList.length-1 ){
         setCurrentIndex(0);
         setSelectedInvestment(0);
         setStrategyId(item[0].strategyId)
      }else{
         setCurrentIndex(currentIndex + 1);
         setSelectedInvestment(currentIndex + 1);
         setStrategyId(item[currentIndex+1].strategyId)
      }
    }
    const OpenPasswordModal = () => {
      if(selectedInvestAmount > 0){
         setSecondaryPasswordModal(true);
         setInvestAmountModal(false);
      }
      else{
         swal(translate(props.lang, "Error"), translate(props.lang,"Please Enter Investment Amount."), "error");
      }
      
   }
   return (
      <>
         <div className="investment pb-5 pb-md-0">
            <Row className="align-items-center">
               <Col lg={3} md={6} sm={12} xs={12} className="mt-1">
                    <Link to="/investment/new" className="d-flex align-items-center">
                        <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-20 text-blue">{translate(props.lang, "Back")}</span>
                    </Link>
                    <div className="InvestmentManualBtn py-2 mt-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <span className="font-weight-400 font-20 text-capitalize text-white">{content ? content.strategyList[0].tradeType : ""}</span>
                        </div>
                    </div>
                    <div className="mt-2"> 
                        <span className="font-weight-700 font-40 InvestmentManualTradeType">{content ? content.productName : ""}</span>
                    </div>
               </Col>
               
            </Row>
            <Row className="justify-content-start pb-5 mt-5">
               {
                  content && content.strategyList.map((item, i) => (
                     <Col lg={4} md={6} key={i} onClick={() => selectInvestment(i, item.productId, item.strategyId)} className="d-none d-md-block">
                        <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={11} xs={11} className="pb-4 pb-md-0">
                        <div className={`card package-card py-4 py-md-5 ${selectedInvestment === i ? "active" : ""}`}>
                           <div className="card-body justify-content-center align-items-center">
                               
                              <div className="text-center">
                                 <span className={`font-weight-700 font-35 text-uppercase packageName`}>{item.strategyId}</span>
                              </div>
                              <div className="text-center">
                                 <span className={`font-weight-400 font-16 text-black-1`}>{item.productToTrade}</span>
                              </div>
                           </div>
                          
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Amount of Investment")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.amountOfInvestment}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Trading Pairs")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.tradingPair}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Winning Ratio")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.minWinning + "-" + item.maxWinning + "%" }</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Trade Frequency")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.minWeeklyTransaction + "-" + item.maxWeeklyTransaction}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                              <div className="text-start">
                                       <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Gas Fee")}</span>
                                       <div>
                                          <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"*Required")}</span>
                                       </div>
                                    </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.gasFeeDesc}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Forecast Return")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.forecastReturn}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Recommended Capital")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{item.suggestedCapital}</span>
                                 </div>
                              </Col>
                           </Row>
                        </div>
                        </Col>
                        </Row>
                     </Col>
                  ))
               }
                 <div className="d-block d-md-none position-relative">
                  {loading ? (
                     <Loadingv1 />
                  ):
                 <>
                        <div onClick={() => prevSlide(currentIndex,content.strategyList)} className="position-absolute prevArrow d-block d-md-none">
                           <FaChevronLeft size={30} color="#000063" />
                        </div>
                        <div onClick={() => nextSlide(currentIndex,content.strategyList)} className="position-absolute nextArrow d-block d-md-none">
                           <FaChevronRight size={30} color="#000063"/>
                        </div>
                     <Col lg={6} md={6}>    
                        <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={11} xs={11} className="pb-4 pb-md-0">
                        <div className={`card package-card py-4 py-md-5 active`}>
                              <div className="card-body justify-content-center align-items-center">
                                 <div className="text-center">
                                    <span className={`font-weight-700 font-35 text-uppercase packageName`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].strategyId : "" : ""}</span>
                                 </div>
                                 <div className="text-center">
                                    <span className={`font-weight-400 font-16 text-black-1`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].productToTrade : "" : ""}</span>
                                 </div>
                              </div>
                              <div className="tradeTypebox col-5 col-md-3 py-3">
                                 <div className="text-center">
                                    <span className="font-weight-500 font-15 text-capitalize text-white">{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].tradeType : "" : ""}</span>
                                 </div>
                              </div>
                              <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Amount of Investment")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].amountOfInvestment : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Trading Pairs")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].tradingPair : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Winning Ratio")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].minWinning + "-" + content.strategyList[currentIndex].maxWinning + "%": ""  : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Trade Frequency")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].minWeeklyTransaction + "-" + content.strategyList[currentIndex].maxWeeklyTransaction : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Gas Fee *Required")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                 <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].gasFeeDesc : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Forecast Return")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].forecastReturn : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           <Row className="my-2 descriptionBox mx-0 py-2 align-items-center">
                              <Col>
                                 <div className="text-start">
                                    <span className={`font-weight-500 font-16 text-capitalize text-white`}>{translate(props.lang,"Recommended Capital")}</span>
                                 </div>
                              </Col>
                              <Col>
                                 <div className="text-end">
                                    <span className={`font-weight-600 font-16 text-capitalize text-white break-white-space`}>{content ? content.strategyList[currentIndex] ? content.strategyList[currentIndex].suggestedCapital : "" : ""}</span>
                                 </div>
                              </Col>
                           </Row>
                           </div>
                        </Col>
                        </Row>
                     </Col>
                     </>
                     }
                  </div>
               <Col lg={12} md={12} xs={12} sm={12} className="text-center mt-4">
                  <Row className="justify-content-center align-items-center">
                     <Col lg={12} md={4} sm={11} xs={11}>
                        <div className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={(e)=>openModal()}>
                           {translate(props.lang, "Subscribe Now")}
                        </div>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Modal className="fade investModal" show={investAmountModal} onHide={()=>{setInvestAmountModal(false);setSelectedInvestAmount('')}} centered>
               <Modal.Body>
                  <div className="py-4">
                    <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => {setInvestAmountModal(false);setSelectedInvestAmount('')}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     <Col lg={12} md={12} sm={12} xs={12} className="text-center pb-4">
                        <Modal.Title>{translate(props.lang, "investment amount")}</Modal.Title>
                     </Col>
                     <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="pb-3">
                           <div className={`input-group py-2 ${errors.selectedInvestAmount ? 'errorBorder' : ''}`}>
                              <input
                                 type="text"
                                 className="form-control input-blue font-weight-600 font-36"
                                 value={selectedInvestAmount}
                                 onChange={(e) => setSelectedInvestAmount(e.target.value)}
                              />
                               <div className="px-3">
                                 <span className="invest-currency">USD</span>
                              </div>
                              <div className="px-3">
                                 <img src={require('../../../images/wallet/pencil.png').default} className="img-fluid" />
                              </div>
                           </div>
                        
                           <div>
                              <span className="error">{translate(props.lang, errors.selectedInvestAmount)}</span>
                           </div>
                        </Col>
                        <div className="mt-2 pb-3">
                           <span className="font-weight-400 font-16 text-black-2">{translate(props.lang,"* Investing an amount below the recommended threshold may increase the risk of liquidation")}</span>
                        </div>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                           <div className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={(e) => OpenPasswordModal()}>
                              {translate(props.lang, "Next")}
                           </div>
                        </Col>
                     </Row>

                  </div>
               </Modal.Body>
            </Modal>

            <Modal className="fade packageModal" size="md" show={packageSelectModal} onHide={()=> setPackageSelectModal(false)} centered>
               <Modal.Header className="px-2 py-4">
                  <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => { setPackageSelectModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                     
               </Modal.Header>
               <Modal.Body>
               <Row className="justify-content-center justify-content-md-start align-items-center">
                     <Col lg={5} md={4} sm={8} xs={8} >
                     <div className="choose-package-btn py-2 text-center ms-3">
                           <span className="choose-package-text">{packageSelected ? packageSelected.productName : ""}</span>
                     </div>
                     </Col>
                     <Col lg={10} md={10} sm={10} xs={10} className="px-2">
                        <div className="text-center text-md-start mt-2 pb-4">
                         
                           <div>
                              <span className="investment-amount">{packageSelected ? packageSelected.investmentAmount : ""}</span>
                           </div>
                           <div className="ms-0 ms-md-4">
                              <span className="investment-product">{packageSelected ? packageSelected.strategyId : ""}</span>
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <div className="px-4">
                     <Row className="align-items-start">
                        <Col lg={12} md={12} sm={12} xs={12}>
                           <div className="mt-3 mb-3">
                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Trading Pairs")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedInvestment ? content.investmentList[selectedInvestment].tradingPair : null}</span> */}
                                    <span className="investment-value">{packageSelected ? packageSelected.tradingPair : ""}</span>
                                 </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Winning Ratio")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedInvestment ? content.investmentList[selectedInvestment].tradingPair : null}</span> */}
                                    <span className="investment-value">{packageSelected ? packageSelected.minWinning + "-" + packageSelected.maxWinning + "%" : ""} </span>
                                 </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Trade Frequency")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedInvestment ? content.investmentList[selectedInvestment].tradingPair : null}</span> */}
                                    <span className="investment-value"> {packageSelected ? packageSelected.minWeeklyTransaction + "-" + packageSelected.maxWeeklyTransaction + " "  : ""} </span>
                                 </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Gas Fee")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedGod ? content.investmentList[selectedInvestment].subscribeTerm : null}</span> */}
                                    <span className="investment-value">{packageSelected ? packageSelected.gasFeeDesc : ""}</span>
                                 </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Forecast Return")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedGod ? content.investmentList[selectedInvestment].subscribeTerm : null}</span> */}
                                    <span className="investment-value">{packageSelected ? packageSelected.forecastReturn : ""}</span>
                                 </Col>
                              </Row>

                              <Row className="justify-content-center align-items-center mb-4">
                                 <Col lg={6} md={6} sm={6} xs={6}>
                                    <span className="investment-label">{translate(props.lang, "Recommended Capital")}</span>
                                 </Col>
                                 <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                                    {/* <span className="investment-value">{content && selectedGod ? content.investmentList[selectedInvestment].subscribeTerm : null}</span> */}
                                    <span className="investment-value">{packageSelected ? packageSelected.suggestedCapital : ""}</span>
                                 </Col>
                              </Row>
                              
                           </div>
                           <div className="mt-5">
                                 <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                                       <div className="choose-package-btn py-3 text-center" onClick={() => confirmPayment()}>
                                          <span className="choose-package-text">{translate(props.lang, "Confirm")}</span>
                                       </div>
                                       {/* <div className="text-center mt-3" onClick={(e) => backToInvestmentSelect(e)}> 
                                             <span className="backInvestment">{translate(props.lang, "Back")}</span>
                                       </div> */}
                                    </Col>
                                 </Row>
                           </div>

   
                        </Col>
                        {/* <Col lg={5} xl={5} md={5} sm={12} xs={12} className="order-1 order-lg-2 order-md-2">
                        <div>
                           <img src={content && selectedGod ? content.investmentList[selectedGod].imageUrl : null} className="img-fluid god-img" />
                        </div>
                     </Col> */}
                     </Row>
                  </div>
               </Modal.Body>
            </Modal>

            <Modal className="fade secondaryPasswordModal" show={secondaryPasswordModal} onHide={() => { setSecondaryPasswordModal(false);setSelectedInvestAmount('')}} centered>
               <Modal.Body>
                  <div className="py-4">
                  <div className="position-absolute smallCloseBtnModal mt-2 me-1">
                        <div onClick={() => { setSecondaryPasswordModal(false);setSelectedInvestAmount('')}} >
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                  <div className="text-center pb-2">
                     <span className="secPassword-text">{translate(props.lang, "Transaction Password")}</span>
                  </div>
                  <div className="text-center pt-4 pb-2">
                        <div className="input-group">
                           <input type={showPassword ? 'text' : 'password'} className="form-control input" placeholder={translate(props.lang,"Enter Transaction Password")} onChange={(e) => setSecondaryPassword(e.target.value)} />
                           {showPassword ? (
                              <button className="showTextBtn px-3" onClick={() => setShowPassword(false)}>
                                 <FontAwesomeIcon icon={solid('eye-slash')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                              </button>
                           ) : (
                              <button className="showTextBtn px-3" onClick={() => setShowPassword(true)}>
                                 <FontAwesomeIcon icon={solid('eye')} color="#CCCCCC" />
                                 <span className="showPasswordText d-none d-lg-inline-block d-md-inline-block"></span>
                              </button>
                           )}
                        </div>
                  </div>
              
                  <Col lg={12} md={12} sm={12} xs={12} className="pt-4 pb-2">
                     <button className="choose-package-btn py-2 text-center w-100" onClick={() => confirmPassword()} disabled={loadingPayment}>
                        <span className="choose-package-text">{loadingPayment ? <FaSpinner className="fa-spin" /> : translate(props.lang,"Confirm")}</span>
                     </button>
                  </Col>
                  </div>

               </Modal.Body>
            </Modal>




            <Modal className="fade successModel" show={successModal} centered>
               <Modal.Header className="px-2 py-4">
                  {/* <Row className="justify-content-center align-items-center">
                     <Col lg={11} md={11} sm={11} xs={11}>
                        <Button
                           variant=""
                           className="btn-close d-block mr-0 ml-auto"
                           onClick={() => setSuccessModal(false)}
                        >
                        </Button>
                     </Col>
                  </Row> */}
               </Modal.Header>
               <Modal.Body className="py-5 px-2 px-lg-4 px-md-4">
                  <div className="text-center payment-success-container">
                     <img src={require('../../../images/investment/payment_success.png').default} className="img-fluid payment-success-img" />
                  </div>
                  {/* <div className="text-center pt-4">
                  <span className="success-subscript-text">Subscription Successful</span>
               </div> */}
                  <div className="px-3">
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "investment package")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           {/* <span className="investment-value">${selectedInvestAmount ? numberWithCommas(selectedInvestAmount): 0}</span> */}
                           <span className="investment-value">{successData ? successData.productName :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "investment amount")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           {/* <span className="investment-value">{content && selectedGod ? content.investmentList[selectedGod].productName : null}</span> */}
                           <span className="investment-value"> {successData ? "USD " + successData.investmentAmount :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Automated Trading Strategies")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.tradeType :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Trading Pairs")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.tradingPair :""}</span>
                        </Col>
                     </Row>
                  <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Winning Ratio")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           {/* <span className="investment-value">46654336</span> */}
                           <span className="investment-value">{successData ? successData.minWinning + "-" + successData.maxWinning + "%" :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Trade Frequency")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.minWeeklyTransaction + "-" + successData.maxWeeklyTransaction :""}</span>
                        </Col>
                     </Row>
                    
                     
                    
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Gas Fee")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.gasFeeDesc :""}</span>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "cTrader ID")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.loginId :""}</span>
                        </Col>
                     </Row>

                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "cTrader Password")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           <span className="investment-value">{successData ? successData.password :""}</span>
                        </Col>
                     </Row>

                     <Row className="justify-content-center align-items-center mt-5">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <div className="back-home-btn py-3 text-center cursor-pointer" onClick={() => backHome()}>
                              <span className="back-home-text">{translate(props.lang, "Back to Home")}</span>
                           </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <div className="choose-package-btn py-3 text-center" onClick={() => closeSuccessModal()}>
                              <span className="choose-package-text">{translate(props.lang, "Confirm")}</span>
                           </div>
                        </Col>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-3">
                        <Col lg={12} md={6} sm={6} xs={6} className="text-center">
                           <Link to="/investment/details">
                              <span className="investment-value view-details">{translate(props.lang, "VIEW DETAILS")} <img src={require('../../../images/investment/right-double-chevron.png').default} className="img-fluid ms-1 mb-1 mb-0-md" /></span>
                           </Link>
                        </Col>
                     </Row>
                  </div>
               </Modal.Body>
            </Modal>

            <Modal className="fade failModel" show={failModal} centered>
               <Modal.Header className="px-2 py-4">
                  <Row className="justify-content-center align-items-center">
                     <Col lg={11} md={11} sm={11} xs={11}>
                        <Button
                           variant=""
                           className="btn-close d-block mr-0 ml-auto"
                           onClick={() => setFailModal(false)}
                        >
                        </Button>
                     </Col>
                  </Row>
               </Modal.Header>
               <Modal.Body className="py-5">
                  <div className="text-center payment-success-container">
                     <img src={require('../../../images/investment/payment_fail.png').default} className="img-fluid payment-success-img" />
                  </div>
                  <div className="text-center pt-4">
                     <span className="fail-subscript-text">{translate(props.lang, "Subscription unSuccessful")}</span>
                  </div>
                  <div className="px-3">
                     <Row className="justify-content-center align-items-center mt-5">
                        <Col lg={12} md={12} sm={12} xs={12}>
                           <div className="choose-package-btn py-3 text-center" onClick={() => closeFailModal()}>
                              <span className="choose-package-text">{translate(props.lang,"Confirm")}</span>
                           </div>
                        </Col>
                     </Row>
                  </div>
               </Modal.Body>
            </Modal>
            <Modal className="fade successModel" show={gasfeeModal} centered>
               <Modal.Header className="px-2 py-4">
                  <div className="position-absolute smallCloseBtnModal mt-2 me-1" style={{zIndex:"999"}}>
                        <div onClick={() => { setGasFeeModal(false)}}>
                           <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M26 3L3 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                              <path d="M3.00928 3L26.0093 26" stroke="#000063" strokeWidth="5" strokeLinecap="round" />
                            </svg>              
                        </div>
                     </div>
                  
               </Modal.Header>
               <Modal.Body className="py-5 px-2 px-lg-4 px-md-4">
                  <div className="text-center payment-success-container">
                     <img src={require('../../../images/investment/warning.png').default} className="img-fluid payment-success-img" />
                  </div>
                  <div className="text-center pt-4">
                  <span className="gasfee-failsubscript-text">{translate(props.lang,"Gas Fee Not Enough")}</span>
                  </div>
                  <div className="px-3 mt-5">
                     <Row className="justify-content-center align-items-center mt-4">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="investment-label">{translate(props.lang, "Gas Fee Wallet")}</span>
                        </Col>
                       
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           {/* <span className="investment-value">${selectedInvestAmount ? numberWithCommas(selectedInvestAmount): 0}</span> */}
                           <span className="investment-value">{gasfeeData ? gasfeeData.gasFeeWallet : ""}</span>
                        </Col>
                     </Row>
                     <Row className="my-4">
                        <div className="dividerNotEnough"></div>
                     </Row>
                     <Row className="justify-content-center align-items-center mt-2">
                        <Col lg={6} md={6} sm={6} xs={6}>
                           <span className="warning-label">{translate(props.lang, "Not Enough Balance")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="text-right">
                           {/* <span className="investment-value">{content && selectedGod ? content.investmentList[selectedGod].productName : null}</span> */}
                           <span className="warning-value">{gasfeeData ? gasfeeData.gasFeeNeeded : ""}</span>
                        </Col>
                     </Row>
                    
                    
                   

                     <Row className="justify-content-center align-items-center mt-5">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                           <div className="choose-package-btn py-3 text-center" onClick={() => TopUpGasFee()}>
                                 <span className="choose-package-text">{translate(props.lang, "Top Up Now")}</span>
                           </div>
                           
                        </Col>
                     </Row>
                  </div>
               </Modal.Body>
            </Modal>
         </div>
      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(InvestmentAuto);
