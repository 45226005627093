import React,{useState,useEffect } from "react";
import { connect } from 'react-redux';
import { Link,useParams } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import ReactPaginate from "react-paginate"

const historyData = [
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 1530, destination: '12234...2343f7', txId: '759181...29f79', status: 'COMPLETED' },
    { time: '2022-09-28 15:50', type: 'withdrawal', asset: 'eth', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'FAILED' },
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 1530, destination: '12234...2343f7', txId: '759181...29f79', status: 'COMPLETED' },
    { time: '2022-09-28 15:50', type: 'Profit Sharing', asset: 'eth', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'FAILED' },
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 1530, destination: '12234...2343f7', txId: '759181...29f79', status: 'COMPLETED' },
    { time: '2022-09-28 15:50', type: 'Direct Sponsor', asset: 'eth', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'FAILED' },
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 6765, destination: '12234...2343f7', txId: '759181...29f79', status: 'COMPLETED' },
    { time: '2022-09-28 15:50', type: 'withdrawal', asset: 'eth', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'FAILED' },
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'btc', amount: 23423546, destination: '12234...2343f7', txId: '759181...29f79', status: 'COMPLETED' },
    { time: '2022-09-28 15:50', type: 'deposit', asset: 'eth', amount: 1530, destination: '864f2...652h78', txId: '375211...55h24t', status: 'FAILED' },
]

const Wallet = (props) => {
   const [history , setHistory] = useState(null);
   const productPerpage = 10;
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
   }
   const { walletType } = useParams();

   useEffect(() => {
      getHistory();
      setPageCount(2);
   }, [props.auth]);
   
   const getHistory = (e) => {
      fetch(api.wallet + `/walletTransactionHistoryList?pageNo=${pageNumber}&pageSize=${productPerpage}&walletType=${walletType}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language" : props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
      .then(response => response.json())
      .then(responseJson => {
         setHistory(responseJson.transactionHistoryList.walletTrxDtoList);
      }).catch(error => {
          console.log("Error:", error);
      });
    }
   return (
      <>
      <Row className="justify-content-center align-items-center mt-5 mt-md-0">
         <Col lg={12} md={12} sm={12} xs={12}>
            <div className="card card-bx cardBg h-100">
               <div className="card-header align-items-center border-bottom-0">
                  <span className="announcement-header">{translate(props.lang, props.match.params.walletType +" transaction history")}</span>
               </div>
               <div className="card-body align-items-center pt-0 pb-3">
                  <Table responsive className="w-100 investHistory">
                        <thead>
                           <tr>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "time")}</th>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "type")}</th>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "amount")}</th>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "destination")}</th>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "txid")}</th>
                              <th className="investHistory-th font-weight-600 font-16 text-capitalize text-white">{translate(props.lang, "status")}</th>
                           </tr>
                        </thead>
                        <tbody>
                        {/* {history && history.map((item, i)=>( */}
                        {historyData.map((item, i)=>(
                           <tr>
                              <td className="investHistory-txt text-center font-weight-400 font-12">{item.time}</td>
                              <td className="investHistory-txt text-center font-weight-400 font-12">{item.type}</td>
                              <td className="investHistory-txt text-center font-weight-400 font-12">{item.amount}</td>
                              <td className="investHistory-txt text-center font-weight-400 font-12">{item.destination}</td>
                              <td className="investHistory-txt text-center font-weight-400 font-12">{item.txId}</td>
                              <td className= {`investHistory-txt text-center font-weight-400 font-12 status ${item.status}`}>{item.status}</td>
                           
                           </tr>
                        ))}
                        </tbody>
                  </Table>
               </div>
            </div>
            {historyData.length>0? (
                <div key={walletType}>
                  <ReactPaginate
                  previousLabel = {"<"}
                  nextLabel = {">"}
                  pageCount = {10}
                  onPageChange ={changePage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__previouslink"}
                  nextLinkClassName={"pagination__nextlink"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                
                  />       
                  </div>                 
               ) : null}
         </Col>
      </Row>
      </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Wallet);
