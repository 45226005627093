import { url } from "./api";


function replaceUrl(x) {
    const parseUrl = new URL(x)
    if(parseUrl.origin === "http://170.187.230.118:8085"){
        return url + parseUrl.pathname;
    }
    else{
       return x
    }
}

export { replaceUrl }