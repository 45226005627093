import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../../css/navHeader.scss";

const NavHader = () => {
  	const [toggle, setToggle] = useState(false);
  	const { navigationHader, openMenuToggle, background } = useContext(ThemeContext);

	return (
    <div className="nav-header">
		<Link to="/dashboard" className="brand-logo">
			<Fragment>
				{toggle ? (
					<img src={require('../../../images/logo/logo.png').default} className="img-fluid header-logo-r d-none d-md-block" />
				) : (
					<img src={require('../../../images/logo/logo_cgi-3x.png').default} className="img-fluid header-logo d-none d-md-block" />
				)}
				
			</Fragment>
      	</Link>

      <div
        className="nav-control"
        onClick={() => {
          // setToggle(!toggle);
          // openMenuToggle();
        }}
      >
          <div className="d-flex w-100 align-items-end">
            <div className="d-block d-md-none ms-5 col-12">
              <img src={require('../../../images/logo/mobilelogo.png').default} className="img-fluid mobile-logo" />
            </div>
            <div className={`hamburger`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
           
        </div>
        </div>
       
          
            
    </div>
  );
};

export default NavHader;
