const EN = {
    "Loading": "Loading",
    "Welcome Back": "Welcome Back",
    "email address": "email address",
    "Enter Your Email Address": "Enter Your Email Address",
    "password": "password",
    "Enter Your Password": "Enter Your Password",
    "Forgot Password?": "Forgot Password?",
    "Login": "Login",
    "Don’t have an account yet?": "Don’t have an account yet?",
    "Sign up now": "Sign up now",
    "This field is required": "This field is required",
    "Create Personal Account": "Create Personal Account",
    "Username": "Username",
    "Email": 'Email',
    "Verification Code": "Verification Code",
    "Enter Code": "Enter Code",
    "Send": "Send",
    "Resend": "Resend",
    "Country": "Country",
    "SELECT COUNTRY": 'Select Country',
    "Referral Code": 'Referral Code',
    "Enter Referral Code": 'Enter Referral Code',
    "Sign Up": "Sign Up",
    "Confirm Password": "Confirm Password",
    "Confirm Your Password": 'Confirm Your Password',
    "Invalid email format": "Invalid email format",
    "Success": "Success",
    "Error": "Error",
    "no space allowed at the begining": "No space allowed at the begining",
    "Username cannot less than 6 letters": "Username cannot less than 6 letters",
    "Password cannot less than 6 characters": "Password cannot less than 6 characters",
    "Password is not match": 'Password is not match',
    "Already have an account?": "Already have an account?",
    "Login now": "Login now",
    "Forgot Password": "Forgot Password",
    "Enter Your Verification Code": "Enter Your Verification Code",
    "Submit": 'Submit',
    "By clicking ”Submit” button, you agree to our Privacy and Terms of Use.": "By clicking ”Submit” button, you agree to our Privacy and Terms of Use.",
    "Back To Login": "Back To Login",
    "Confirm password is not match with password": "Confirm password is not match with password",
    "Back": "Back",
    "thank you!": "thank you!",
    "dashboard": "Dashboard",
    "Investment Portfolio": "Investment Portfolio",
    "Overview": "Overview",
    "New Investment": "New Investment",
    "Investment Details": "Investment Details",
    "Wallet": 'Wallet',
    "Deposit": 'Deposit',
    "Withdrawal": 'Withdrawal',
    "Transaction History": "Transaction History",
    "Team": "Team",
    "Total Team Investment": 'Total Team Investment',
    "Group Investment Summary": "Group Investment Summary",
    "Referral": "Referral",
    "Profile": "Profile",
    "Settings": 'Settings',
    "KYC": 'KYC',
    "Change Password": "Change Password",
    "Set Email Address": "Set Email Address",
    "Transaction Password": "Transaction Password",
    "Copied": "Copied",
    "Referral Link": "Referral Link",
    "Logout": "Logout",
    "cTrader ID": "cTrader ID",
    "investor password": "investor password",
    "package": 'package',
    "total investment amount (USD)": "total investment amount (USD)",
    "subscription days left": 'subscription days left',
    "realized p/l % (daily)": "realized p/l % (daily)",
    "realized p/l % (monthly)": "realized p/l % (monthly)",
    "total return": 'total return',
    "subscription period": 'subscription period',
    "subscription status": "subscription status",
    "New Email Address": "New Email Address",
    "Enter New Email Address": "Enter New Email Address",
    "Update": 'Update',
    "current password": "current password",
    "Enter your current password": "Enter your current password",
    "new password": "new password",
    "Enter Your New Password": "Enter Your New Password",
    "Confirm New Password": "Confirm New Password",
    "confirm your new password": "confirm your new password",
    "Please fill in all required fields with valid information.": 'Please fill in all required fields with valid information.',
    "New Password cannot less than 6 characters": "New Password cannot less than 6 characters",
    "Confirm password does not match with password": "Confirm password does not match with password",
    "new transaction password": 'new transaction password',
    "Confirm New Transaction Password": "Confirm New Transaction Password",
    "fullname as per ic": "fullname as per ic",
    "gender": "gender",
    "Male": "Male",
    "Female" :"Female",
    "ic/ passport no.": "IC / passport no.",
    "mobile no.": "mobile no.",
    "residential address": "residential address",
    "postcode": "postcode",
    "joining date": "joining date",
    "kyc status": "KYC status",
    "Upload IC/ Passport": "Upload IC/ Passport",
    "Upload Photo with Holding of IC/ Passport": "Upload Photo with Holding of IC/ Passport",
    "Upload Proof of Address (Utility Bill/ Phone Bill/ Bank Slip)": "Upload Proof of Address (Utility Bill/ Phone Bill/ Bank Slip)",
    "full name": "full name",
    "phone number": "phone number",
    "date of birth": "date of birth",
    "my group brokers": "my group brokers",
    "share QR code": "share QR code",
    "group leader": "group leader",
    "total investment quantity": "total investment quantity",
    "team leadership level": "team leadership level",
    "No. Of People": "No. Of People",
    "Actual rebate lot": "Actual rebate lot",
    "status": "Status",
    "No result found": "No result found",
    "Total Sales:": "Total Sales:",
    "Total Member:": "Total Members:",
    "Total Active Member:": "Total Active Members:",
    "My Rank": 'My Rank',
    "total group members": 'Total Group Members',
    "total active members": "Total Active Members",
    "profit sharing": "Profit Sharing",
    "investment wallet details" : "investment wallet details",
    "my qr code" : "my qr code",
    "Inbox" : "Inbox",
    "profit/ loss (day)" : "profit/ loss (day)",
    "USD Wallet" : "USD Wallet",
    "Winning rate" : "Winning rate",
    "Weekly Transaction" : "Weekly Transaction",
    "Automated Trading Strategies" : "Automated Trading Strategies",
    "Gas Fee" : "Gas Fee",
    "Choose Package" : "Choose Package",
    "investment amount" : "investment amount",
    "Next" : "Next",
    "Confirm" : "Confirm",
    "VIEW DETAILS" : "VIEW DETAILS",
    "cTrader ID" : "cTrader ID",
    "cTrader Password" : "cTrader Password",
    "investment package" : 'investment package',
    "GF Wallet" : 'GF Wallet',
    "Bonus Wallet" : "Bonus Wallet",
    "Rewards" : "Rewards",
    "Investment Wallet" : "Investment Wallet",
    "Capital" : "Capital",
    "Earning" : "Earning",
    "Withdraw Capital" : "Withdraw Capital",
    "Withdraw Profit" : "Withdraw Profit",
    "Unsubscribe" : "Unsubscribe",
    "Withdraw T&C" : 'Withdraw T&C',
    "CRM wallet is allow to withdraw commission and bonus once per week." : 'CRM wallet is allow to withdraw commission and bonus once per week.',
    "C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees." : "C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.",
    "Termination" : "Termination",
    "If investor terminate contract before period end, just able to withdraw invest capital without ROI." : "If investor terminate contract before period end, just able to withdraw invest capital without ROI.",
    "withdrawal request submitted" : "withdrawal request submitted",
    "Enter Transaction Password" : 'Enter Transaction Password',
    "address" : "address",
    "coin" : "coin",
    "Network" : "Network",
    "wallet address" : "wallet address",
    "Descriptions" : "Descriptions",
    "Crypto & eWallet is a channel where deposits are performed via cryptocurrencies." : 'Crypto & eWallet is a channel where deposits are performed via cryptocurrencies.',
    "If you already have existing holds of cryptocurrency, you will be able to send the cryptocurrencies directly as a source of deposit." : "If you already have existing holds of cryptocurrency, you will be able to send the cryptocurrencies directly as a source of deposit.",
    "On the other hand, if you do not hold any cryptocurrency, you will be able to buy cryptocurrencies with your local currency and it will be deposited to account." : 'On the other hand, if you do not hold any cryptocurrency, you will be able to buy cryptocurrencies with your local currency and it will be deposited to account.',
    "Your Wallet will be reflected with the equivalent amount in USD for the amount of cryptocurrency at time of received." : "Your Wallet will be reflected with the equivalent amount in USD for the amount of cryptocurrency at time of received.",
    "Terms & Conditions" : "Terms & Conditions",
    "All transaction fees are borne by client, unless otherwise stated." : "All transaction fees are borne by client, unless otherwise stated.",
    "All transaction receipts are suggested to keep by client, has the right to request as proof." : "All transaction receipts are suggested to keep by client, has the right to request as proof.",
    "The deposit amount will be shown in your wallet right after the broker system acknowledges." : "The deposit amount will be shown in your wallet right after the broker system acknowledges.",
    "The application for deposits/ withdrawals of cryptocurrency will be processed within 15 minutes, unless any unforeseen circumstances." : 'The application for deposits/ withdrawals of cryptocurrency will be processed within 15 minutes, unless any unforeseen circumstances.',
    "withdraw from" : "withdraw from",
    "balance" : "balance",
    "withdrawal type" : 'withdrawal type',
    "wallet address" : "wallet address",
    "PLEASE SELECT" : "PLEASE SELECT",
    "Please Enter Your Wallet Address" : "Please Enter Your Wallet Address",
    "withdraw amount" : "withdraw amount",
    "Enter Your Withdraw Amount" : "Enter Your Withdraw Amount",
    "Enter Your Transaction Password" : "Enter Your Transaction Password",
    "email verify code" : "email verify code",
    "Enter Your Email Verify Code" : "Enter Your Email Verify Code",
    "Send OTP To Email" : "Send OTP To Email",
    "*Withdraw will be charged 5 USDT per transaction" : "*Withdraw will be charged 5 USDT per transaction",
    "Internal Transfer" : "Internal Transfer",
    "Transfer From" : "Transfer From",
    "Transfer To" : "Transfer To",
    "Total amount:" : "Total amount:",
    "amount" : "amount",
    "Enter Amount" : "Enter Amount",
    "USDT Balance" : "USDT Balance",
    "GF Balance" : "GF Balance",
    "Complete" : "Complete",
    "Wallet Transaction History Details" : "Wallet Transaction History Details",
    "Filter:" : "Filter:",
    "Back" : "Back",
    "Enter Verification Code" : "Enter Verification Code",
    "Enter Your New Transaction Password" : "Enter Your New Transaction Password",
    "fullname" : "fullname",
    "Enter Your Fullname" : "Enter Your Fullname",
    "ic/ passport no." : "IC / passport no.",
    "Enter Phone Number" : "Enter Phone Number",
    "Enter Residential Address" : "Enter Residential Address",
    "Enter Your Postcode" : 'Enter Your Postcode',
    "Gas Fee Campaign" : "Gas Fee Campaign",
    "Register" : "Register",
    "Trade Frequency" : "Trade Frequency",
    "Winning Ratio" : "Winning Ratio",
    "Trading Pairs" : "Trading Pairs",
    "Amount of Investment" : "Amount of Investment",
    "Leverage" : "Leverage",
    "Variable Spreads" : "Variable Spreads",
    "Forecast Return" : "Forecast Return",
    "Recommended Capital" : "Recommended Capital",
    "Gas Fee Not Enough" : "Gas Fee Not Enough",
    "Not Enough Balance" : "Not Enough Balance",
    "Top Up Now" : "Top Up Now",
    "* Investing an amount below the recommended threshold may increase the risk of liquidation" : "* Investing an amount below the recommended threshold may increase the risk of liquidation",
    "Please Enter Investment Amount With Minimum 100 USD." : "Please Enter Investment Amount With Minimum 100 USD.",
    "Booking of slots for sharing" :  "Booking of slots for sharing",
    "Name" : "Name",
    "Commission" : "Commission",
    "Gas Fee Rewards" : "Gas Fee Rewards",
    "Free Gas Fee Campaign" : "Free Gas Fee Campaign",
    "Get your free gas fee by sharing to your friends. T&C provided." : "Get your free gas fee by sharing to your friends. T&C provided.",
    "Join Now" : "Join Now",
    "Unsubscribe Package" : "Unsubscribe Package",
    "quick links" : "Quick Links",
    "Total Profit" : "Total Profit",
    "total investment" : "total investment",
    "team bonus" : "team bonus",
    "number of investment package" : "number of investment package",
    "investment package" : "investment package",
    "wallet" : "wallet",
    "account balance" : 'account balance',
    "announcements" : "announcements",
    "Investment" : "Investment",
    "Portfolio" : "Portfolio",
    "Transfer" : "Transfer",
    "Verification" : "Verification",
    "personal performance data" : "personal performance data",
    "Inbox" : "Inbox",
    "Coming Soon" : "Coming Soon",
    "Investment Amount (USD)" : "Investment Amount (USD)",
    "realized p/l %" : 'realized p/l %',
    "Gas Fee" : "Gas Fee",
    "Subscribe Now" : "Subscribe Now",
    "Back to Home" : "Back to Home",
    "Earnings" : "Earnings",
    "Type" : "Type",
    "Asset" : "Asset",
    "Amount" : "Amount",
    "destination" : "destination",
    "Enter Your Name" : "Enter Your Name",
    "Select Date And Time" : "Select Date And Time",
    "Remark" : "Remark",
    "Date And Time" : "Date And Time",
    "Select Gender" : "Select Gender",
    "Enter Your IC / Passport No." : "Enter Your IC / Passport No.",
    "withdrawal amount" : "Withdrawal Amount",
    "Investment Package" : "Investment Package",
    "Available Balance" : "Available Balance",
    "Cancel" : "Cancel",
    "CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees." :"CRM wallet is allow to withdraw commission and bonus once per week C Trader wallet will be able to withdraw with earning ROI once contract period end or else just allow to withdraw Investment Fund without ROI and also be charge 3% processing fees.",
    "Please enter secondary password" : "Please Enter Secondary Password",
    "Please enter withdrawal amount" : "Please Enter Withdrawal Amount",
    "Enter Withdrawal Amount" : "Enter Withdrawal Amount",
    "Please Enter Investment Amount" : "Please Enter Investment Amount",
    "Please enter secondary password." : "Please Enter Secondary Password.",
    "Please select package." : 'Please Select Package.',
    "Oops" : "Oops",
    "Only allow format jpg or png" : "Only allow format jpg or png",
    "Unable Transfer To Same Wallet" : "Unable Transfer To Same Wallet",
    "No notification yet" : "No notification yet",
    "Revenue" : "Revenue",
    "*Required" : "*Required",
    "Click Me for Customer Support" : "Click Me for Customer Support",
    "Gas Fee Wallet" : "Gas Fee Wallet",
    "Top Up Successful" : "Top Up Successful",
    "Top Up Amount" :   "Top Up Amount",
    "Top-UP" : "Top-UP",
    "Current Investment Amount" : "Current Investment Amount",
    "Top Up Investment Amount" : "Top Up Investment Amount",
    "Confirmation" : "Confirmation",
    "Top Up Investment" : "Top Up Investment",
    "day" : "day",
    "Connect" : "Connect",
    "cTrader Link" : "cTrader Link",
    "From" : "From",
    "To" : "To",
    "Personal Perfromance" : "Personal Perfromance",
    "Time" : "Time",
    "Total image size cannot more than 25 MB" : "Total image size cannot more than 25 MB",
    "Total image size cannot less than 50 KB" : "Total image size cannot less than 50 KB",
    "Only Numbers are allowed in this field" : 'Only Numbers are allowed in this field',
    "Crypto Deposit" : "Crypto Deposit",
    "Fiat Deposit": "Fiat Deposit",
    "Order Number" : "Order Number",
    "Payment Currency" : "Payment Currency",
    "Deposit Amount" : "Deposit Amount",
    "Enter Deposit Amount" : "Enter Deposit Amount",
    "Payment Amount" : "Payment Amount",
    "Enter Your Payment Amount" : "Enter Your Payment Amount",
    "Exchange Rate" : "Exchange Rate",
    "Converted Amount" : "Converted Amount",
    "Select Payment Currency" : "Select Payment Currency",
    "Select Type Of Coin" : "Select Type Of Coin",
    "Select Type Of Network" : "Select Type Of Network",
    "Deposit Address" : "Deposit Address",
    "Minimum Deposit" : "Minimum Deposit",
    "Please Select Network!" : "Please Select Network!",
    "Bank Type" : "Bank Type",
    "Select Bank Type" : "Select Bank Type",
    "Please convert to payment amount from deposit amount" : "Please convert to payment amount from deposit amount",
    "language" : "language",
    "transfer amount" : "Transfer Amount",
    "Enter Transfer Amount" : "Enter Transfer Amount",
    "Please enter transfer amount" : "Please enter transfer amount",
    "Withdrawal Method (country)" : "Withdrawal Method (country)",
    "Fiat Withdrawal" : "Fiat Withdrawal",
    "bank account number" : "bank account number",
    "bank name" : "bank name",
    "account holder name" : "account holder name",
    "swift code" : "swift code",
    "bank address" : "bank address",
    "Enter Bank Account" : "Enter Bank Account",
    "Enter Bank Name" : "Enter Bank Name",
    "Enter Account Holder Name" : "Enter Account Holder Name",
    "Enter Swift Code" : "Enter Swift Code",
    "Enter Bank Address" : "Enter Bank Address",
    "Crypto Withdrawal" : "Crypto Withdrawal",
    "Wallet Balance" : "Wallet Balance",
    "*Deposit will be charged 3.5% per transaction." : "*Deposit will be charged 3.5% per transaction.",
    "lot rebate" : "lot rebate",
    "Total Profit/Loss" : "Total Profit/Loss",
    "cTrader Deposit" : "cTRADER DEPOSIT",
    "cTrader Account" : "cTrader Account",
    "Commission Wallet" : "Commission Wallet",
    "*Minimum withdrawal : 100USD" : "*Minimum withdrawal : 100USD",
    "Total Team Deposit" : "Total Team Deposit",
    "Step into a World of Trading Excellence" : "Step into a World of Trading Excellence",
    "cTrader Login ID" : "cTRADER LOGIN ID",
    "Top Up" : "Top Up",
    "USDT Wallet" : "USDT Wallet",
    "cTrader Balance" : "cTRADER BALANCE",
    "Copy Link" : "Copy Link",
    "*Minimum amount: 100 USD" : "*Minimum amount: 100 USD",
    "Activate" : "Activate",
    "Activation Successful" : "Activation Successful",
    "Team Referral Tree" : "Team Referral Tree",
    "Bank Deposit History" : "Bank Deposit History",
    "Currency Code" : "Currency Code",
    "USD Amount" : "USD Amount",
    "Fiat Amount" : "Fiat Amount",
    "TRX Date Time" : "TRX Date Time",
    "Currency" : "Currency",
    "cTrader Balance:" : "cTrader Balance:",
    "Total cTrader Balance" : "Total cTrader Balance",
    "Trading Account" : "Trading Account",
    "Please select leverage" : "Please select leverage"

    
   





}

export default EN;