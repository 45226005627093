import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { translate } from "../../../utils/translate";

const Language = (props) => {
    const dispatch = useDispatch();
    const changeLanguage = (language) => {
		dispatch({
			type: 'UPDATE_LANGUAGE',
			lang: language
		});
		localStorage.setItem('cgi-lang', language);
}	

   return (
        <>
            <div className="mt-5">
                <Row className="justify-content-center align-items-center">
                    <Col lg={4} xs={11} sm={11}>
                        <div className="card card-bx cardBg h-100">
                            <div className="card-header align-items-center border-bottom-0">
                            <span className="announcement-header">{translate(props.lang, "language")}</span>
                            </div>
                            <div className="card-body align-items-center pt-0 pb-4 px-0">
                            <div className={`px-3 ${props.lang === "EN" ?"selected-lang-bg" : ""}`} onClick={()=>changeLanguage('EN')}>
                                <Row className={`justify-content-start align-items-center py-2 `}>
                                    <Col lg={3} md={3} sm={2} xs={2}>
                                        <img alt="images" src={require('../../../images/language/en.png').default} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        <span className="announcement-text">English</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-right">
                                        {props.lang === "EN" ? (
                                            <img alt="images" src={require('../../../images/common/selected.png').default} />
                                        ) : null}
                                    </Col>
                                </Row>
                            </div>
                            <div className={`px-3 ${props.lang === "ZH" ?"selected-lang-bg" : ""}`} onClick={()=>changeLanguage("ZH")}>
                                <Row className={`justify-content-center align-items-center py-2`}>
                                    <Col lg={3} md={3} sm={2} xs={2}>
                                        <img alt="images" src={require('../../../images/language/cn.png').default} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6}>
                                        <span className="announcement-text">中文（简体）</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-right">
                                        {props.lang === "ZH" ? (
                                            <img alt="images" src={require('../../../images/common/selected.png').default} />
                                        ) : null}
                                    </Col>
                                </Row>
                            </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Language);
