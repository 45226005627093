import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import { Accordion, Card } from 'react-bootstrap';
import QRCode from "react-qr-code";
import "../../../css/wallet.scss";
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import ComingSoon from "../ComingSoon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import LiveSupport from "../../components/Live/LiveSupport";
import { set } from "date-fns";
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import Loadingv1 from "../../components/Loading/Loadingv1";
import Select from "react-select";

const CryptoDeposit = (props) => {
    const [deposit, setDeposit] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
   
    const [depositAddress, setDepositAddress] = useState('');
    const [selectedCoin, setSelectedCoin] = useState({ value: '', label: '' });
    useEffect(() => {
        getDepositInfo();
    }, [props.auth]);

    const downloadImage = () => {
        const svg = document.getElementById("qrcode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "qrcode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
    const getDepositInfo = (e) => {
        setLoading(true)
        fetch(api.depositList, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                    setDeposit(result);
                    setSelectedCoin({ value: result.coinTypeList[0].key, label: result.coinTypeList[0].value})
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const copyQRCode = (e) => {
        navigator.clipboard.writeText(e);
        setSuccess("Copied");
        setTimeout(() => {
            setSuccess("");
        }, 1000);
    }

    const setActiveAccordion = (i) => {
        if (i === activeTab) {
            setActiveTab(null);
        } else {
            setActiveTab(i);
        }
    }

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "transparent",
          border: "none",
          boxShadow: state.isFocused ? null : null,
          color: "white",
          height: "3.5rem",
          padding: "7px"
        }),
        menu: (base) => ({
          ...base,
          borderRadius: 0,
          marginTop: 0,
          backdropFilter: "blur(100px)",
          color: "black",
          borderRadius: "14px",
        }),
        menuList: (base) => ({
          ...base,
          padding: 0
        }),
        singleValue: (base) => ({
          ...base,
          color: "#1b1b1b",
        })
      };
    const getWalletAddress = (chainType) => {
        fetch(api.wallet + `/getDepositQr?chainType=${chainType}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0]) {
                  setDepositAddress(result.depositDetails)
                }
                else {
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error:", error);
            });
    }

    const sete = () =>{}
    return (
        <>
            {loading ? (
                <Loadingv1 />
            ) :
                <>
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <Link to="/wallet/deposit" className="d-flex align-items-center mt-4 mt-md-0">
                            <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
                        </Link>
                        <div className="card card-black h-100 py-4 px-4 mt-3">
                            <div className="card-header align-items-center border-bottom-0 d-flex justify-content-center justify-content-md-start ">
                                <span className={`headerText`}>{translate(props.lang, "Crypto Deposit")}</span>
                            </div>
                            <div className="card-body align-items-center ">
                                <Row className="justify-content-center h-100">
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4" id="selectWithdrawal">
                                        <div className="form-group">
                                            <label className="mb-1 ">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "coin")}</span>
                                            </label>
                                            <div className={`input-border`}>
                                                <Select                     
                                                    options={deposit && deposit.coinTypeList.map((item, i) => ({ value: item.key, label: item.value }))}
                                                    placeholder={translate(props.lang, "Select Type Of Coin")}
                                                   
                                                    styles={customStyles}
                                                    onChange={(e)=> setSelectedCoin({ value: e.value, label: e.label })}
                                                    value={selectedCoin}
                                                />
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} xs={12} sm={12} className="mb-4" id="selectWithdrawal">
                                        <div className="form-group">
                                            <label className="mb-1 ">
                                                <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Network")}</span>
                                            </label>
                                            <div className={`input-border`}>
                                                <Select
                                                    options={deposit && deposit.networkList.map((item, i) => ({ value: item.key, label: item.value }))}
                                                    placeholder={translate(props.lang, "Select Type Of Network")}
                                                
                                                    styles={customStyles}
                                                    onChange={(e)=>getWalletAddress(e.value)}
                                                   
                                                />
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mt-4">
                                        <label className="mb-1 ">
                                            <span className={`font-weight-500 font-18 text-black-2 text-capitalize`}>{translate(props.lang, "Deposit Address")}</span>
                                        </label>
                                        {depositAddress === "" ? (
                                            <div className="text-center">
                                                <img src={require('../../../images/noData.png').default} className="img-fluid pt-4" alt="no-data" />
                                                <span className="d-block text-black-1 mt-4">
                                                    {translate(props.lang, "Please Select Network!")}
                                                </span>
                                            </div>
                                        ):
                                            <Row className="justify-content-center justify-content-md-start w-100 align-items-start pt-4 mx-0">
                                                
                                                <Col lg={2} md={5} sm={12} xs={12} className="mb-3 px-1">
                                                    <div className="qr-container p-3 text-center" onClick={() => downloadImage('../../../images/dashboard/qrcode.png')}>
                                                        <QRCode
                                                            id="qrcode"
                                                            size={125}
                                                            style={{ height: "auto", width: "125px" }}
                                                            value={depositAddress ? depositAddress.walletAddress : ""}
                                                            viewBox={`0 0 256 256`}
                                                            bgColor="#EBEBEB"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                    <Col lg={6} md={9} sm={9} xs={9} className="mb-3 px-0">
                                                        <div className="text-start mt-4 ms-0 ms-md-4">
                                                            <span className="font-weight-600 font-18 line-height-18 text-grey-1 d-block mb-2 text-capitalize">{translate(props.lang, "wallet address")}</span>
                                                            <div className="mt-3 pb-4">
                                                                <span className="font-weight-600 font-18 line-height-18 d-block overflow-wrap-anywhere me-3 text-blue-1" >{depositAddress ? depositAddress.walletAddress : ""}</span>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col lg={5} md={3} sm={3} xs={3} className="px-0">
                                                        <div className="text-end mt-5 cursor-pointer">
                                                            <img src={require("../../../images/wallet/copy.png").default} className="img-fluid copy-img" onClick={(e)=>copyQRCode(depositAddress ? depositAddress.walletAddress : "")}/>  
                                                        </div>
                                                        <div className="mt-2 text-end">
                                                            <span className="font-weight-400 font-12 text-success">{translate(props.lang,success)}</span>
                                                        </div>
                                                    </Col>

                                                    {/* <Col lg={6} md={6} sm={6} xs={6} className="mb-3 px-0">
                                                        <div className="text-start ms-0 ms-md-4">
                                                            <span className="font-weight-400 font-16 line-height-18 text-grey-2 d-block mb-2">{translate(props.lang, "Minimum Deposit")}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} md={6} sm={6} xs={6} className="px-0">
                                                        <div className="text-end ">
                                                            <span className="font-weight-400 font-16 line-height-18 text-grey-2 d-block mb-2">{translate(props.lang, "0.00000001 USDT")}</span>
                                                        </div>
                                                    </Col> */}
                                                    </Row>
                                                </Col>
                                

                                            </Row>
                                        }
                                    </Col>
                                    {/* <div className="it-txt text-end d-none d-md-block">{translate(props.lang,"Total amount:")} 1000.00</div> */}

                                </Row>

                            </div>
                        </div>
                    </Col>
                
                    <div className="mt-4">
                        <span className={`font-weight-500 font-17 text-term text-uppercase`}>{translate(props.lang, "Descriptions")}</span>
                        <div>
                            <div className="mt-2">
                                <span className={`font-weight-400 font-16 text-term`}>1. {translate(props.lang, "Crypto & eWallet is a channel where deposits are performed via cryptocurrencies.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>2. {translate(props.lang, "If you already have existing holds of cryptocurrency, you will be able to send the cryptocurrencies directly as a source of deposit.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>3. {translate(props.lang, "On the other hand, if you do not hold any cryptocurrency, you will be able to buy cryptocurrencies with your local currency and it will be deposited to account.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>4. {translate(props.lang, "Your Wallet will be reflected with the equivalent amount in USD for the amount of cryptocurrency at time of received.")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 pb-5">
                        <span className={`font-weight-500 font-17 text-term text-uppercase`}>{translate(props.lang, "Terms & Conditions")}</span>
                        <div>
                            <div className="mt-2">
                                <span className={`font-weight-400 font-16 text-term`}>1. {translate(props.lang, "All transaction fees are borne by client, unless otherwise stated.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>2. {translate(props.lang, "All transaction receipts are suggested to keep by client, has the right to request as proof.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>3. {translate(props.lang, "The deposit amount will be shown in your wallet right after the broker system acknowledges.")}</span>
                            </div>
                            <div className="mt-1">
                                <span className={`font-weight-400 font-16 text-term`}>4. {translate(props.lang, "The application for deposits/ withdrawals of cryptocurrency will be processed within 15 minutes, unless any unforeseen circumstances.")}</span>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>

    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(CryptoDeposit);
