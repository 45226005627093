import React,{useState,useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row,Table } from 'reactstrap';
import { numberWithCommas } from "../../../utils/function";
import "../../../css/wallet.scss";
import { url, api, web_user } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import ComingSoon from "../ComingSoon";
import LiveSupport from "../../components/Live/LiveSupport";
import swal from "sweetalert";
import Loadingv1 from '../../components/Loading/Loadingv1';
import ReactPaginate from "react-paginate";





const Wallets = (props) => {
   const [wallet,setWallet] = useState(null);
   const [gf, setGF] = useState(0);
   const [usdt, setUSDT] = useState(0);
   const [loading, setLoading] = useState(false);
   const productPerpage = 10
   const [pageNumber,setPageNumber] = useState(1);
   const [pageCount, setPageCount] = useState(1);
   const [gfManual,setGFManual] = useState(0);
   useEffect(() => {
      getWallet();
   }, [pageNumber]);
   
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
}
   const getWallet = (e) => {
      setLoading(true);
      fetch(api.walletList + `?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/JSON",
              "Content-Language" : props.lang,
              "Authorization": "Bearer " + props.auth.auth.token
          }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
          let result = JSON.parse(responseJson[1]);
          if(responseJson[0]){
          var pageCount = Math.ceil(result.totalRecords / productPerpage)
          setPageCount(pageCount)
          let gf = result.walletList.filter(item => item.walletType === 'GF_AUTO');
          let gfManual = result.walletList.filter(item => item.walletType === 'GF_MANUAL');
          let usdt = result.walletList.filter(item => item.walletType === 'USDT');
            if (gf.length > 0) {
               setGF(gf[0].walletBalance);
            }

            if (gfManual.length > 0) {
               setGFManual(gfManual[0].walletBalance);
            }

            if (usdt.length > 0) {
               setUSDT(usdt[0].walletBalance);
            }

            
            setWallet(result);
            setLoading(false);
         }
         else{
            swal(translate(props.lang, 'Error'), result.message, "error");
            setLoading(false);
         }
      }).catch(error => {
          console.log("Error:", error);
      });
    }

   return (
      <>
        <Row className="justify-content-center justify-content-md-start mt-3 pb-5">
            <Col lg={6} md={12} sm={12} xs={12}  className="wallet mb-3 mt-3 mt-md-0">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     <Row className="align-items-center">
                        <Col lg={12} md={6} xs={12}  sm={12}>
                       
                           {/* <div className="d-none d-md-block"> */}
                            
                           {/* </div> */}

                           {/* <div className="d-block d-md-none"> */}
                           <Row className="align-items-center">
                              <Col lg={12} md={12} sm={5} xs={5}>
                                 <div className="d-flex align-items-center  justify-content-center justify-content-md-start">
                                    <div className="mb-0 mb-md-4">
                                       <img src={require('../../../images/wallet/wallet.png').default} className="img-fluid card-icon" />
                                    </div>
                                 </div>
                              
                              </Col>
                              <Col lg={12} md={12} sm={7} xs={7}>
                              <div className="ms-0 ms-md-2 mb-2 mt-2 mt-md-0">
                                    {/* <span className="wallet-name text-capitalize">{translate(props.lang,"USDT")}</span> */}
                                    <div>
                                       <span className="wallet-name">{translate(props.lang,"Wallet")}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2">
                                       <div>
                                          <span className="big-number">{usdt ? numberWithCommas(usdt) : 0}</span>
                                       </div>
                                       <div className="mb-0 mb-md-2 ms-0 ms-md-2 mt-0 mt-md-3">
                                          <span className="currency"> USD</span>     
                                       </div>          
                                  </div>
                              </div> 
                              </Col>
                           </Row>
                           {/* </div> */}
                        </Col>
                     </Row>
                  </div>
               </div>
            </Col>
         
            <Col lg={6} md={12} sm={12} xs={12}  className="wallet mb-3">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     <Row className="align-items-center">
                              <Col lg={12} md={12} sm={5} xs={5}>
                                 <div className="d-flex align-items-center  ms-2 justify-content-center justify-content-md-start">
                                    <div className="mb-0 mb-md-4">
                                       <img src={require('../../../images/wallet/BonusWallet.png').default} className="img-fluid card-icon" />
                                    </div>
                                 </div>
                              
                              </Col>
                              <Col lg={12} md={12} sm={7} xs={7}>
                                 <div className="ms-0 ms-md-2 mb-2 mt-2 mt-md-0">
                                          <span className="wallet-name">{translate(props.lang,"lot rebate")}</span>
                                          
                                          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2">
                                             <div>
                                                <span className="big-number">{wallet ? numberWithCommas(wallet.bonusWallet.walletBalance) : 0}</span>
                                             </div>
                                             <div className="mb-0 mb-md-2 ms-0 ms-md-2 mt-0 mt-md-3">
                                                <span className="currency">USD</span>
                                             </div>
                                          </div>         
                                 </div> 
                              </Col>
                           </Row>
                     </div>
               </div>
            </Col>
       
            {/* <Col lg={12} md={12} sm={12} xs={12}  className="wallet order-3 order-md-3 order-lg-2">
               <div className="card card-bx cardBg">
                  <div className="card-body align-items-center">
                     {loading ? (
                           <Loadingv1/>
                        ):
                     <Row className="justify-content-start align-items-center mt-3">
                        <Col lg={8} md={7} xs={7} sm={7}>
                           <div className="ms-1">
                              <div className="d-flex flex-row align-items-center">
      
                                 <div className="text-md-start w-100 text-lg-start text-xs-left text-sm-left">
                                    <span className="wallet-name text-capitalize">{translate(props.lang,"Investment Wallet")}</span>
                                 </div>
                              </div>         
                           </div>
                        </Col>                          
                        <Col lg={4} md={5} xs={5} sm={5} className="">
                           <Row className="align-items-center justify-content-end">
                              <Col lg={9} md={8} sm={10} xs={10} className="text-start">
                                 <div className="d-flex align-items-center justify-content-center">
                                    <img src={require('../../../images/wallet/Tether-USDT-mini.png').default} className="img-fluid mobile-icon mb-1 d-none d-md-block" />
                                    <span className="wallet-big-number ms-4 d-none d-md-block">{wallet ? numberWithCommas(wallet.totalInvestment.walletBalance) : ""}</span>
                                    <span className="currency ms-2 mt-1 d-none d-md-block">USDT</span>
                                    <div className="d-none d-md-block">
                                       <Link to="/wallet/investmentWalletDetails">
                                          <img src={require('../../../images/wallet/wallet-chevron-right2.png').default} className="img-fluid mb-2 ms-0 ms-md-3"/>
                                       </Link>
                                    </div>
                                    <div className="d-block d-md-none">
                                       <Link to="/wallet/investmentWalletDetails">
                                          <span className="view-details">{translate(props.lang,"View Details")}</span>
                                          <img src={require('../../../images/wallet/wallet-chevron-right2.png').default} className="img-fluid mb-0 mb-md-2 ms-2 ms-md-3"/>
                                       </Link>
                                    </div>
                                 </div>
                              </Col>
                           </Row>

                        </Col>
                        <div className="d-block d-md-none">
                              <div className="d-flex align-items-center mt-3">
                                 <img src={require('../../../images/wallet/Tether-USDT-mini.png').default} className="img-fluid mobile-icon mb-1" />
                                 <span className="wallet-big-number ms-4 ">{wallet ? numberWithCommas(wallet.totalInvestment.walletBalance) : 0}</span>
                                 <span className="currency ms-2 mt-1">USDT</span>
                              </div>
                        </div>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-4">
                        <Table bordered className="investHistory">
                        <thead>
                           <tr>
                              <th className="investHistory-th">cTrader ID</th>
                              <th className="investHistory-th">{translate(props.lang, "package")}</th>
                           </tr>
                        </thead>
                        <tbody>
                         {wallet && wallet.investmentWallet.map((item, i)=>(
                           <tr>
                              <td className="investHistory-txt text-center">{item.cTraderId}</td>
                              <td className="investHistory-txt text-center">{item.productName}</td>                 
                           </tr>
                        ))}
                         {wallet && wallet.investmentWallet.length === 0 ? (
                              <tr>
                                 <td colspan="2" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                              </tr>
                         ) : null}
                        </tbody>
                           </Table>
                        </Col>
                     </Row>
                     }
                  <div>
                  {wallet && wallet.investmentWallet.length > 0 ? (
                        <ReactPaginate
                           previousLabel={"<"}
                           nextLabel={">"}
                           pageCount={pageCount}
                           onPageChange={changePage}
                           containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                           previousLinkClassName={"pagination__previouslink text-blucoe-1"}
                           nextLinkClassName={"pagination__nextlink text-blue-1"}
                           disabledClassName={"pagination__link--disabled text-muted"}
                           activeClassName={"pagination__link--active text-white"}

                        />
                     ):null}
                  </div>
                  </div>
               </div>
            </Col> */}
          
         </Row>

      </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Wallets);
