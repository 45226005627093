import { connect } from "react-redux";
import { translate } from "../../utils/translate";

const ComingSoon = (props) => {
    return (
        <div className="bg-transparent">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-12 text-center py-5">
                        <span className="comingSoonText"> {translate(props.lang, "Coming Soon")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ComingSoon);