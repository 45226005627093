import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { api } from "../../../utils/api";
import ReactPaginate from "react-paginate"
import ComingSoon from "../ComingSoon";
import "../../../css/wallet.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import LiveSupport from "../../components/Live/LiveSupport";
import swal from "sweetalert";
import moment from "moment";
import Loadingv1 from '../../components/Loading/Loadingv1';
import { DatePicker } from "antd";
import localeZh from 'antd/locale/zh_CN';
import localeEn from 'antd/locale/en_US';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';

const historyData = [
   { transactionDate: '2022-09-28 15:50', transactionType: 'deposit', asset: 'usdt-trc20', amount: 1530, destination: '12234...2343f7', txId: '759181...29f79', status: 'completed' },
   { transactionDate: '2022-09-28 15:50', transactionType: 'withdrawal', asset: 'usdt-trc20', amount: 20210, destination: '864f2...652h78', txId: '375211...55h24t', status: 'failed' },
]

// const filtetype = [
//    {type: "USDT"},
//    {type: "GF_AUTO"},
//    {type: 'GF_MANUAL'}
// ]

const { RangePicker } = DatePicker;

const WalletHistory = (props) => {
   const [history, setHistory] = useState([]);
   const productPerpage = 10;
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [isOpen, setIsOpen] = useState(false);
   const [walletType, setWalletType] = useState("USDT");
   const [currentWalletType, setCurrentWalletType] = useState("");
   const [loading, setLoading] = useState(false);
   const [timeFrom, setTimeFrom] = useState(null);
   const [timeTo, setTimeTo] = useState(null);
   const [filteType, setFilterType] = useState(null);
   const [params, setParams] = useState('');
   const [totalAmount, setTotalAmount] = useState('');
   const [fromDate, setFromDate] = useState(null);
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
   }
   const moment = require('moment-timezone');

   useEffect(() => {  
      getWalletType();
   }, []);

   useEffect(() => {
      getHistory();
   }, [pageNumber,params,timeFrom,timeTo]);


 

  
   const getHistory = () => {
      var link;
      if(walletType !== currentWalletType){
         setPageNumber(1);
      }    
     if(timeFrom && timeTo){
        link = `/walletTransactionHistoryList?pageNo=${pageNumber}&pageSize=${productPerpage}&from=${timeFrom}&to=${timeTo}`
       
     }
     else{
         link = `/walletTransactionHistoryList?pageNo=${pageNumber}&pageSize=${productPerpage}`
     }
      setLoading(true);
      fetch(api.wallet + link, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0]){
                var pageCount = Math.ceil(result.transactionHistoryList.totalRecords / productPerpage)
                setPageCount(pageCount);
                setHistory(result.transactionHistoryList.walletTrxDtoList);
                setLoading(false);
                setCurrentWalletType(walletType);
                setTotalAmount(result.totalAmount)
                setFromDate(timeFrom);
            }
            else{
               swal(translate(props.lang, 'Error'), result.message, "error");
               setLoading(false);
            }
         }).catch(error => {
            console.log("Error:", error);
         });
   }

   const getWalletType = () => {
      fetch(api.wallet + '/walletTypeList', {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0]){
               setFilterType(result.walletTypeList)
               setWalletType(result.walletTypeList[0].middle)
               // setCurrentWalletType(walletType);
            }
            else{
               swal(translate(props.lang, 'Error'), result.message, "error");
               setLoading(false);
            }
         }).catch(error => {
            console.log("Error:", error);
         });
   }

   const handleTimeChange = (e) =>{
      if(e){
         const formattedFromDate = dayjs(e[0]).format('YYYY-MM-DD');
         const formattedToDate = dayjs(e[1]).format('YYYY-MM-DD');
         setTimeFrom(formattedFromDate);
         setTimeTo(formattedToDate);
         setPageNumber(1);
      
      }
      else{
         setTimeFrom(null);
         setTimeTo(null);
      }
      
      
   }

   const setType = (item) =>{
      setWalletType(item.middle);
      setParams(item);
   }
   return (
      <>
         <div className="mt-3">
            <Row className="justify-content-center align-items-center">
               <Col lg={12} md={12} xs={12} sm={12} >
                  <div className="card card-black h-100 py-4 px-4 mt-3 mt-md-0">
                     <div className="card-header align-items-center border-bottom-0">
                        <span className={`font-weight-600 font-20 text-uppercase text-blue-1`}>{translate(props.lang,"Wallet Transaction History Details")}</span>

                    
                     </div>
                     <Row className="px-0 px-md-4 pb-3">

                            <Col lg={5} md={5} xs={12} sm={12} className="order-2 order-md-1 "  id="dateRange">
                         
                             
                                 <RangePicker className="w-100 dateRange" 
                                    placeholder={[translate(props.lang,"From"), translate(props.lang,"To")]}
                                    onChange={(e)=> handleTimeChange(e)}
                                    
                                 />
                               
                            
                           </Col>
                           {/* <Col lg={7} md={7} xs={12} sm={12} className="text-end order-1 order-md-2 pb-3 pb-md-0">
                           <div className="dropdown_history ms-1 ms-md-0">
                              <div className="dropdown-toggle_history" onClick={(e)=>setIsOpen(!isOpen)}>
                                 <div className="dropdown-box px-2 px-md-4 py-1">
                                       <div className="text-center">
                                          <span> <FontAwesomeIcon icon={faFilter} className="me-2"/>{translate(props.lang,"Filter:")} {walletType}  <img src={require('../../../images/common/arrowdown.png').default} className="img-fluid arrow-down mb-1"/></span>
                                       </div>
                                      
                                 </div>
                              </div>
                              {isOpen && (                           
                                 <ul className="dropdown-menu_history">
                                    {filteType && filteType.map((item,i) => (
                                    <>
                                       {item.middle !== walletType  ? (
                                          <li className="dropdown-txt"onClick={(e)=>setType(item)}>{item.middle}</li>
                                       ):null}
                                    </>
                                    ))}
                                 </ul>                                                           
                              )}                         
                              </div>
                           </Col> */}
                     </Row>
                     <div className="card-body align-items-center pt-3 pb-3">
                        {loading ? (
                           <Loadingv1/>
                        ):
                        <>
                        <Table responsive className="w-100 investHistory">
                           <thead>
                              <tr>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Time")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Type")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Currency")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Amount")}</th>
                                 {/* <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "destination")}</th> */}
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Remark")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "status")}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {history && history.map((item, i) => (
                                 <tr key={i}>
                                    <td className={`investHistory-txt font-weight-400 font-12`}>{moment(item.transactionDate).tz('Asia/Bangkok').format('DD/MM/YYYY hh:mm:ss')}</td>
                                    <td className={`investHistory-txt font-weight-400 font-12 text-capitalize`}>{item.transactionType}</td>
                                    <td className={`investHistory-txt font-weight-400 font-12`}>{item.cryptoType}</td>
                                    <td className={`investHistory-txt font-weight-400 font-12`}>{item.amount}</td>
                                    {/* <td className={`investHistory-txt font-weight-400 font-12`}>{item.destination}</td> */}
                                    <td className={`investHistory-txt font-weight-400 font-12`}>{item.remark ? item.remark : "-"}</td>
                                    <td className={`investHistory-txt font-weight-400 font-12 text-capitalize status ${item.status.toLowerCase()}`}>{item.status}</td>
                                 </tr>
                              ))}
                                    
                              {history && history.length === 0 ? (
                              <tr>
                                 <td colspan="7" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                              </tr>
                           ) : null}
                           </tbody>
                          
                        </Table>   
                        <div className="mt-3">
                           <span className="font-weight-500 font-18 text-blue text-capitalize">{translate(props.lang,"Total amount:")} {totalAmount} </span>
                        </div>            
                        </>
                        }
                     </div>
                     <div>
                     {history && history.length > 0 ? (
                         <div key={walletType + timeFrom + timeTo}>
                           <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                              previousLinkClassName={"pagination__previouslink text-blue-1"}
                              nextLinkClassName={"pagination__nextlink text-blue-1"}
                              disabledClassName={"pagination__link--disabled text-muted"}
                              activeClassName={"pagination__link--active text-white"}
                              pageRangeDisplayed={1}

                           />
                           </div>
                        ):null}
                  
                  </div>
                  </div>
                 
               </Col>
            </Row>
         </div>

      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(WalletHistory);
