import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { url, api } from "../../../utils/api";
import ComingSoon from "../ComingSoon";
import ReactPaginate from "react-paginate"
import LiveSupport from "../../components/Live/LiveSupport";
import Loadingv1 from '../../components/Loading/Loadingv1';
import swal from "sweetalert";


const GroupInvestment = (props) => {
   const [groupInvestment, setGroupInvestment] = useState(null)
   const productPerpage = 10;
   const [pageCount, setPageCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [loading, setLoading] = useState(false);
   const changePage = (event) => {
      setPageNumber(event.selected + 1);
   }
   const getGroupInvestment = (e) => {
      setLoading(true);
      fetch(api.team + `/groupInvestmentSummary?pageSize=${productPerpage}&pageNo=${pageNumber}`, {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0]){
               setGroupInvestment(result.groupList);
               var pageCount = Math.ceil(result.groupList.totalRecords / productPerpage)
               setPageCount(pageCount);
               setLoading(false);
            }
            else{
               swal(translate(props.lang, 'Error'), result.message, "error");
               setLoading(false);
           }
         }).catch(error => {
            console.log("Error:", error);
         });
   }
   useEffect(() => {
      getGroupInvestment();
   }, [pageNumber]);
   return (
      <>
         <div className="mt-3">
            <Row className="justify-content-center align-items-center">
               <Col lg={12} md={12} xs={12} sm={12} className="mt-3 mt-md-0">
                  <div className="card card-black h-100 py-4 px-4">
                     <div className="card-header align-items-center border-bottom-0">
                        <span className={`font-weight-600 font-22 text-uppercase text-blue-1`}>{translate(props.lang, "Group Investment Summary")}</span>
                     </div>
                     <div className="card-body align-items-center pt-3 pb-3">
                        {loading ? (
                           <Loadingv1/>
                        ):
                        <Table responsive className="w-100 investHistory">
                           <thead>
                              <tr>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "group leader")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "total investment quantity")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "team leadership level")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "No. Of People")}</th>
                                 <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "Actual rebate lot")}</th>
                                 {/* <th className={`font-weight-600 font-16 text-capitalize text-white`}>{translate(props.lang, "status")}</th> */}
                              </tr>
                           </thead>
                           <tbody>
                              {groupInvestment && groupInvestment.sponsorSummaryDtoList.map((item, i) => (
                                 <tr key={i}>
                                    <td className={`investHistory-txt font-weight-400`}>{item.leaderName}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.groupInvestmentQuantity}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.managerLevel}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.totalDownline}</td>
                                    <td className={`investHistory-txt font-weight-400`}>{item.totalLotAmount}</td>
                                    {/* <td className={`investHistory-txt font-weight-400 font-12 text-capitalize`}>{item.status}</td> */}
                                 </tr>
                              ))}
                              {groupInvestment && groupInvestment.sponsorSummaryDtoList.length === 0 ? (
                              <tr>
                                 <td colspan="6" className={`font-weight-400 font-12`}>{translate(props.lang, "No result found")}</td>
                              </tr>
                           ) : null}
                           </tbody>
                        </Table>
                        }        
                     </div>
                     <div>
                     {groupInvestment && groupInvestment.sponsorSummaryDtoList.length > 0 ? (
                        <ReactPaginate
                           previousLabel={"<"}
                           nextLabel={">"}
                           pageCount={pageCount}
                           onPageChange={changePage}
                           containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                           previousLinkClassName={"pagination__previouslink text-blue-1"}
                           nextLinkClassName={"pagination__nextlink text-blue-1"}
                           disabledClassName={"pagination__link--disabled text-muted"}
                           activeClassName={"pagination__link--active text-white"}

                        />
                     ):null}
                  </div>
                  </div>
                  
               </Col>
            </Row>
         </div>

      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(GroupInvestment);
