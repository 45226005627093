import { translateRect } from "@fullcalendar/core";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { Carousel } from 'react-bootstrap'
import QRCode from "react-qr-code";
import { FaCopy } from "react-icons/fa";
import { translate } from "../../../utils/translate";
import "../../../css/dashboard.scss";
import { api } from "../../../utils/api";
import { numberWithCommas } from "../../../utils/function";
import $ from 'jquery';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveSupport from '../../components/Live/LiveSupport';
import swal from "sweetalert";
import { Line } from "react-chartjs-2";
import moment from "moment";
var today = new Date()

const quicklinks = [
   { name: 'Deposit', img: require('../../../images/quicklink/deposit_link.png').default, activeImg: require('../../../images/quicklink/deposit_link.png').default, link: 'wallet/deposit' },
   { name: 'Withdrawal', img: require('../../../images/quicklink/portfolio_link.png').default, activeImg: require('../../../images/quicklink/portfolio_link.png').default, link: 'wallet/withdrawal' },
   // { name: 'Portfolio', img: require('../../../images/quicklink/portfolio_link.png').default, activeImg: require('../../../images/quicklink/portfolio_link.png').default, link: 'investment/portfolio' },
   // { name: 'Transfer', img: require('../../../images/quicklink/transfer_link.png').default, activeImg: require('../../../images/quicklink/transfer_link.png').default, link: 'wallet/transfer' },
   { name: 'Team', img: require('../../../images/quicklink/team_link.png').default, activeImg: require('../../../images/quicklink/team_link.png').default, link: 'team' },
   { name: 'Settings', img: require('../../../images/quicklink/setting_link.png').default, activeImg: require('../../../images/quicklink/setting_link.png').default, link: 'setting' },
   { name: 'Verification', img: require('../../../images/quicklink/verification_link.png').default, activeImg: require('../../../images/quicklink/verification_link.png').default, link: 'setting/kyc' },
   { name: 'Profile', img: require('../../../images/quicklink/profile_link.png').default, activeImg: require('../../../images/quicklink/profile_link.png').default, link: 'profile' },
]


var options = {
   legend: {
      labels: {
         usePointStyle: true,
         fontColor: "black"
      }
   },
   scales: {
       yAxes: [{
           ticks: {
               fontColor: "black",
              
           },
          
       }],
       xAxes: [{
           ticks: {
               fontColor: "black",
           }
           
       }]
   }
   
};
const Dashboard = (props) => {
   const [success, setSuccess] = useState(null);
   const [content, setContent] = useState(null)
   const [loading,setLoading] = useState(false);
   const [data,setData] = useState([]);
   const [windowSize, setWindowSize] = useState(getWindowSize())
   function getWindowSize() {
      const { innerWidth, innerHeight } = window;
      return { innerWidth, innerHeight };
  }
   useEffect(() => {
      getWallet();
      // getUserInfo();
      // getDialCode(selectedCountry);
   }, [props.lang]);
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };

    const carouselSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
   

   const copyQRCode = () => {
      let value = window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode;
      var $temp = $("<input>");
      if (value != '') {
         var $temp = $("<input>");
         $("body").append($temp);
         var dummy = $temp.val(value).select();
         dummy.focus();
         document.execCommand("copy");
         $temp.remove();
      }


      setSuccess("Copied Successfully");
      setTimeout(() => {
         setSuccess("");
      }, 1000);
   }


   const downloadImage = () => {
      const svg = document.getElementById("qrcode");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = function () {
         canvas.width = img.width;
         canvas.height = img.height;
         ctx.drawImage(img, 0, 0);
         const pngFile = canvas.toDataURL("image/png");

         const downloadLink = document.createElement("a");
         downloadLink.download = "qrcode";
         downloadLink.href = `${pngFile}`;
         downloadLink.click();
      };

      img.src = "data:image/svg+xml;base64," + btoa(svgData);
   }

   const getWallet = () => {
      fetch(api.user + '/info', {
         method: "GET",
         headers: new Headers({
            "Content-Type": "application/JSON",
            "Content-Language": props.lang,
            "Authorization": "Bearer " + props.auth.auth.token
         }),
      })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {         
          let result = JSON.parse(responseJson[1]);
            if(responseJson[0]){
            setContent(result)
            let chart = {labels: [], datasets: [{ label: translate(props.lang,"Personal Perfromance"), data : [], fill: true,  backgroundColor: "rgba(75,192,192,0.2)",pointStyle: 'line', borderColor: "rgba(75,192,192,1)" }]}
            result.tradingPerformanceList.map((item,i) => {
                // if(i<month){
               chart.labels.push((item.recordDate))
                chart.datasets.map((x,y)=>{
                        x.data.push(item.value)
                })
                })
                setData(chart)
          }
          else{
            swal(translate(props.lang, 'Error'), result.message, "error");
         }
         }).catch(error => {
            console.log("Error:", error);
         });
   }
   const redirect = () =>{
      window.location.href = ""
   }

   return (
      <>
         <div className="my-4 my-md-0 pb-0 pb-md-3  px-2">
            <span className="welcome-title" style={{overflowWrap: "anywhere"}}>{translate(props.lang,"Welcome Back")}, {props.auth.auth.claims.username} </span>
         </div>
         <div>
         <Row id="dashboard_carousel">
            <Col lg={12} md={12} sm={12} xs={12} className="pb-4">
               <Slider {...carouselSettings}>
                           <div className="position-relative bannerBg">
                           <Row className="justify-content-start justify-content-md-center align-items-center h-100 mt-3 mt-md-0 ms-3 ms-md-0">
                              <Col lg={11} md={10} sm={9} xs={9} >
                                 <div className="pb-2 mt-2 mt-md-5 pt-4 ms-1 ms-md-0">
                                    <span className="dashboard-title">{translate(props.lang,"Step into a World of Trading Excellence")}</span>
                                 </div>                         
                                 {/* <Col lg={2} md={4} sm={7} xs={7} className="pb-4 pb-md-0">
                                    <div className="btn red-btn border-none btn-block font-weight-400 font-16 mt-2 py-2" onClick={(e) => redirect(e)}>
                                       <span className="btn-started">{translate(props.lang, "Join Now")}</span>
                                    </div>
                                 </Col> */}
                               </Col>
                           </Row>
                           </div>
               </Slider>
             
                          
                        
               
            </Col>
            <div className="d-block d-md-none">
               <div className="card-header align-items-center border-bottom-0">
                           <span className="announcement-header">{translate(props.lang, "quicklinks")}</span>
                        </div>
                        <div className="card-body align-items-center pt-0 pb-3">
                           <Row className="justify-content-start align-items-center">
                              <div className="imgslider ms-4 pb-5" id="sliderQuicklinks">
                              <Slider {...settings}>
                              {quicklinks.map((item, i) => (
                                 <Col lg={3} md={5} sm={6} xs={6} className="mb-2">
                                    <Link to={item.link}>
                                       <div className="text-center">
                                          <img
                                             src={item.img}
                                             className="img-fluid quicklinks-img"
                                             onMouseOver={e => e.currentTarget.src = item.activeImg}
                                             onMouseLeave={e => e.currentTarget.src = item.img}
                                          />
                                       </div>
                                       <div className="minWidth mt-1">
                                          <span className="quicklinks-name">{translate(props.lang, item.name)}</span>
                                       </div>
                                    </Link>
                                   
                                 </Col>

                              ))}
                              </Slider>
                              </div>
                           </Row>
                  </div>

            </div>
            <Col lg={9} md={12} sm={12} xs={12}>
               <Row className="pb-2 pb-md-0">
                  <Col lg={6} md={6} sm={12} xs={12} className=" h-100">
                  <div className="card card-bx cardBg">
                           <div className="card-body align-items-center mt-0 mt-md-2">
                              <div className="d-none d-md-block">
                                 <div>
                                    <div className="mb-2 pb-2">
                                       <img src={require('../../../images/dashboard/wallet.png').default} className="img-fluid card-icon" />
                                    </div>
                                    <div className="w-70">
                                       <span className="text-primary card-small-title">{translate(props.lang, "wallet")}</span>
                                    </div>
                                 </div>
                                 <div className="mt-2">
                                    <Row className="">
                                    
                                       <Col>
                                          <span className="big-number">{content ? numberWithCommas(content.accountBalance) : 0}</span> <span className="xsmall-desc ms-2">{translate(props.lang, "account balance")}</span>
                                       </Col>
                                    </Row>
                                 </div>
                              </div>

                              <div className="d-block d-md-none">
                                 <Row className="align-items-center">
                                 <Col sm={3} xs={3}>
                                    <div className="">
                                       <img src={require('../../../images/dashboard/wallet.png').default} className="img-fluid" />
                                    </div>
                                 </Col>
                                 <Col sm={9} xs={9} >
                                    <div className="mt-3">
                                       <span className="text-blue-1 font-weight-600 font-18 text-uppercase">{translate(props.lang, "wallet")}</span>
                                    </div>
                                    <div>
                                       <span className="big-number">{content ? numberWithCommas(content.accountBalance) : 0}</span><span className="xsmall-desc ms-2">{translate(props.lang, "account balance")}</span>
                                    </div>
                                 </Col>
                                 </Row>
                              </div>
                           </div>
                        </div>
                        <div className="card card-bx cardBg">
                        <div className="card-body align-items-center mt-0 mt-md-2">
                           <div className="d-none d-md-block">
                              <div>
                                 <div className="mb-2 pb-2">
                                    <img src={require('../../../images/dashboard/totalInvestment.png').default} className="img-fluid card-icon" />
                                 </div>
                                 <div className="w-70">
                                    <span className="text-primary card-small-title" style={{textTransform: "none"}}>{translate(props.lang, "cTrader Deposit")}</span>
                                 </div>
                              </div>
                              <div className="mt-2">
                                 <span className="big-number">{content ? numberWithCommas(content.totalInvestment) : 0}</span> <span className="small-desc">usd</span>
                              </div>
                           </div>

                           <div className="d-block d-md-none">
                                 <Row className="align-items-center">
                                 <Col sm={3} xs={3}>
                                    <div className="">
                                       <img src={require('../../../images/dashboard/totalInvestment.png').default} className="img-fluid" />
                                    </div>
                                 </Col>
                                 <Col sm={9} xs={9} >
                                    <div className="mt-3">
                                       <span className="text-primary card-small-title" style={{textTransform: "none"}}>{translate(props.lang, "cTrader Deposit")}</span>
                                    </div>
                                    <div>
                                       <span className="big-number">{content ? numberWithCommas(content.totalInvestment) : 0}</span> <span className="xsmall-desc ms-2">usd</span>
                                    </div>
                                 </Col>
                                 </Row>
                           </div>
                        </div>

                     </div>

               

                    
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12} className="h-100">
                     <div className="">
                     <div className="card card-bx cardBg" >
                        <div className="card-body align-items-center mt-0 mt-md-2">
                           <div className="d-none d-md-block">
                              <div>
                                 <div className="mb-2 pb-2">
                                    <img src={require('../../../images/dashboard/teamBonus.png').default} className="img-fluid card-icon" />
                                 </div>
                                 <div className="w-70">
                                    <span className="text-primary card-small-title">{translate(props.lang, "lot rebate")}</span>
                                 </div>
                              </div>
                              <div className="mt-2">
                                 <span className="big-number">{content ? numberWithCommas(content.totalTeamBonusForUsdt) : 0}</span> <span className="small-desc">usd</span>
                              </div>
                           </div>

                           <div className="d-block d-md-none">
                                 <Row className="align-items-center">
                                 <Col sm={3} xs={3}>
                                    <div className="">
                                       <img src={require('../../../images/dashboard/teamBonus.png').default} className="img-fluid" />
                                    </div>
                                 </Col>
                                 <Col sm={9} xs={9}>
                                    <div className="mt-3">
                                       <span className="text-primary card-small-title">{translate(props.lang, "lot rebate")}</span>
                                    </div>
                                    <div>
                                       <span className="big-number">{content ? numberWithCommas(content.totalTeamBonusForUsdt) : 0}</span> <span className="xsmall-desc ms-2">usd</span>
                                    </div>
                                 </Col>
                                 </Row>
                           </div>
                        </div>
                     </div>
              
                    
                     </div>



                  </Col>
               </Row>
            </Col>
            <Col lg={3} md={12} sm={12} xs={12}>
               <Row className="justify-content-center align-items-center">
               <Col lg={3} md={6} sm={12} xs={12}>
               <div className="card card-bx cardBg pb-2 d-block d-md-none" >
                  <div className="card-header align-items-center border-bottom-0">
                     <span className="announcement-header">{translate(props.lang, "my qr code")}</span>
                  </div>
                  <div className="card-body align-items-center pt-0 pb-3 text-center">
                     <div onClick={() => downloadImage('../../../images/dashboard/qrcode.png')}>
                        <div className="d-flex justify-content-center">
                           <div className="qr-container p-3 w-50">
                              <QRCode
                                 id="qrcode"
                                 size={128}
                                 fgColor="#012060"
                                 bgColor="transparent"
                                 style={{ height: "auto", width: "100%" }}
                                 value={window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}
                                 viewBox={`0 0 128 128`}
                              />
                           </div>
                        </div>
                        {/* <img src={require('../../../images/dashboard/qrcode.png').default} className="img-fluid" /> */}
                     </div>
                     <div className="my-3 text-center">
                        <span className="referral-text font-weight-600">{translate(props.lang, "Referral Link")}</span>
                     </div>
                    
                        <div className="referral-box-content text-center">
                           <Row className="align-items-center justify-content-center">
                              <Col lg={10} md={10} sm={11} xs={11}>
                                 <div className="">
                                    <span className="referral-value">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                 </div>
                              </Col>
                              
                           </Row>
                        </div>
                        <div className="mt-4 pb-3">
                           <Row className="justify-content-center">
                              <Col xs={11} sm={11}>
                              <div className="choose-package-btn py-3 text-start" onClick={copyQRCode}>
                                 <div className="d-flex w-100 justify-content-center">
                                 <div>
                                 <FaCopy color="white" size="16" /> 
                                 </div>
                                 <div>
                                    <span className="choose-package-text">{translate(props.lang,"Copy Link")}</span>
                              </div>
                              </div>   
                              </div>  
                           </Col>   
                           </Row>  
                        </div>
                     {success ? (
                        <div className="mt-2">
                          <span className="font-weight-400 font-12 text-success">{success}</span>
                        </div>
                     ) : ""}
                  </div>
               </div>
            </Col>
            <Col lg={12} md={6} sm={12} xs={12}>
               <div className="card card-bx cardBg pb-2 d-none d-md-block dashboardCardTop-height" >
                  <div className="card-header align-items-center border-bottom-0">
                     <span className="announcement-header">{translate(props.lang, "my qr code")}</span>
                  </div>
                  <div className="card-body h-100 align-items-center pt-0 pb-3 text-center">
                     <div onClick={() => downloadImage('../../../images/dashboard/qrcode.png')}>
                        <div className="d-flex justify-content-start">
                           <div className="qr-container p-3 w-100">
                              <QRCode
                                 id="qrcode"
                                 size={128}
                                 fgColor="#012060"
                                 bgColor="transparent"
                                 style={{ height: "auto", width: "100%" }}
                                 value={window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}
                                 viewBox={`0 0 128 128`}
                              />
                           </div>
                        </div>
                        {/* <img src={require('../../../images/dashboard/qrcode.png').default} className="img-fluid" /> */}
                     </div>
                     <div className="my-3 text-start">
                        <span className="referral-text font-weight-600">{translate(props.lang, "Referral Link")}</span>
                     </div>
                    
                        <div className="referral-box-content text-start">
                           <Row className="align-items-center">
                              <Col lg={10} md={10} sm={9} xs={9}>
                                 <div className="">
                                    <span className="referral-value">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                 </div>
                              </Col>
                              
                           </Row>
                        </div>
                        <div className="mt-4 pb-3">
                           <div className="choose-package-btn py-3 text-center" onClick={copyQRCode}>
                              <div className="d-flex w-100 justify-content-center">
                              <div>
                              <FaCopy color="white" /> 
                              </div>
                              <div>
                                 <span className="choose-package-text ms-2">{translate(props.lang,"Copy Link")}</span>
                             </div>
                           </div>   
                           </div>       
                        </div>
                    
                     {success ? (
                        <div className="mt-2">
                          <span className="font-weight-400 font-12 text-success">{success}</span>
                        </div>
                     ) : ""}
                  </div>
               </div>
            </Col>
               </Row>
            </Col>
         </Row>
         
         <Row>
            {/* <Col lg={8} md={6} sm={12} xs={12}>
               <div className="card card-bx cardBg pb-2">
                  <div className="card-header align-items-center border-bottom-0">
                     <span className="announcement-header">{translate(props.lang, "personal performance data")}</span>
                  </div>
                  <div className="card-body align-items-center pt-0 pb-3">
                     {!loading ? (
                     <>
                           <div className="d-none d-md-block">
                              <Line data={data} height={windowSize.innerWidth > 668 && windowSize.innerWidth < 991 ? 350 : 150} options={options} />
                           </div>
                           <div className="d-block d-md-none">
                              <Line height={300} data={data} options={options} />
                           </div>
                           </> 
                     ):null}
                  
                 
                  </div>
               </div>
            </Col> */}
             <Col lg={6} md={6} sm={12} xs={12} >
                     <div className="card card-bx cardBg dashboardCardBottom-height">
                        <div className="card-header align-items-center border-bottom-0">
                           <span className="announcement-header">{translate(props.lang, "announcements")}</span>
                        </div>
                        <div className="card-body align-items-center pt-0 pb-4">
                           {/* <span className="announcement-text">No announcement at this moment</span> */}
                           
                           <div>
                              {content && content.announcementList.map((item,i) => (     
                              <>                      
                              <Row className="justify-content-center align-items-start py-1">
                                 <Col lg={3} md={5} sm={4} xs={5}>
                                    <span className="announcement-date">{item.announceDate}</span>
                                 </Col>
                                 <Col lg={9} md={7} sm={8} xs={7}>
                                    <span className="announcement-text">{item.announcement}</span>
                                 </Col>
                              </Row>
                              <hr />
                              </>   
                              ))}

                           {content && content.announcementList.length === 0 ? (     
                              <div><span className={`font-weight-400 font-16`}>{translate(props.lang, "No result found")}</span></div>
                           ):null}
                           </div>
                        </div>
                     </div>
                  </Col>
                  <Col lg={6} md={6}>
                     <div className="d-none d-md-block"> 
                        <div className="card card-bx cardBg  dashboardCardBottom-height">
                           <div className="card-header align-items-center border-bottom-0 ">
                              <span className="announcement-header">{translate(props.lang, "quick links")}</span>
                           </div>
                           <div className="card-body align-items-center pt-0 pb-3">
                              <Row className="justify-content-start align-items-center">
                                 {quicklinks.map((item, i) => (
                                    <Col lg={3} md={5} sm={6} xs={6} className="mb-2">
                                       <Link to={item.link}>
                                          <div className="text-center">
                                             <img
                                                src={item.img}
                                                className="img-fluid quicklinks-img"
                                                onMouseOver={e => e.currentTarget.src = item.activeImg}
                                                onMouseLeave={e => e.currentTarget.src = item.img}
                                             />
                                          </div>
                                          <div className="text-center">
                                             <span className="quicklinks-name">{translate(props.lang, item.name)}</span>
                                          </div>
                                       </Link>
                                    </Col>

                                 ))}
                              </Row>
                           </div>
                        </div>
                     </div>
                  </Col>
        
         </Row>
         </div>


    
      </>
   );
};

const mapStateToProps = (state) => {
   const { auth, i18n } = state;
   return {
      lang: i18n.lang,
      auth: auth,
   }
};

export default connect(mapStateToProps)(Dashboard);
