import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import "../../../css/setting.scss";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import { FaAngleDoubleLeft, FaSpinner } from "react-icons/fa";
import LiveSupport from "../../components/Live/LiveSupport";

const ChangeTransactionPassword = (props) => {
  let errorsObj = { oldPassword: '', newPassword: '', confirmPassword: '', verificationCode: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [sendVerificationCode, setSendVerificationCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const updatePassword = () => {
    let check = true;
    const errorMsg = { ...errorsObj };
    // if (!oldPassword) {
    //   errorMsg.oldPassword = translate(props.lang, 'This field is required.');
    //   check = false;
    // }
    if (!newPassword) {
      errorMsg.newPassword = translate(props.lang, "This field is required");
      check = false;
    }

    if (!confirmPassword) {
      errorMsg.confirmPassword = translate(props.lang, "This field is required");
      check = false;
    }
    if (!verificationCode) {
      errorMsg.verificationCode = translate(props.lang, "This field is required");
      check = false;
    }
    if (newPassword != confirmPassword) {
      errorMsg.confirmPassword = translate(props.lang, "Confirm password does not match with password");
      check = false;
    }

    if (newPassword === confirmPassword && newPassword.length < 6) {
      errorMsg.newPassword = translate(props.lang, "New Password cannot less than 6 characters");
      check = false;
    }
    setErrors(errorMsg);
    if (check) {
      setLoading(true);
      let data = {
        // "oldPassword": oldPassword,
        "newTransactionPassword": newPassword,
        "confirmTransactionPassword": confirmPassword,
        "verifyCode": verificationCode,
      };

      fetch(api.user + '/setupTransactionPassword', {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Content-Language": props.lang,
          "Authorization": "Bearer " + props.auth.auth.token
        }),
        body: JSON.stringify(data)
      })
        .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
          setLoading(false);
          let result = JSON.parse(responseJson[1]);
          if (responseJson[0]) {
            setNewPassword('');
            setConfirmPassword('');
            setVerificationCode('');
            swal(translate(props.lang, 'Success'), result.message, "success");
          } else {
            swal(translate(props.lang, 'Error'), result.message, "error");

          }
        }).catch(error => {
          console.error("error", error);
        });
    } else {
      swal(translate(props.lang, 'Error'), translate(props.lang, 'Please fill in all required fields with valid information.'), "error");
    }
  }

  const sendOTP = (e) => {
    let email = "";
    setLoadingOtp(true);
    fetch(api.user + `/generateTransactionPasswordOtpEmail`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "Content-Language": props.lang,
        "Authorization": "Bearer " + props.auth.auth.token
      }),
    })
      .then(response => Promise.all([response.ok, response.text()]))
      .then(responseJson => {
        let result = JSON.parse(responseJson[1]);
        setLoadingOtp(false);
        if (responseJson[0]) {
          setSendVerificationCode(true);
          swal(translate(props.lang, 'Success'), result.message, "success");
        } else {
          swal(translate(props.lang, 'Error'), result.message, "error");
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  return (
    <>
      <div className="">
        <Row className="justify-content-center align-items-center">
          <Col lg={12} md={12} xs={12} sm={12}>
            <Link to="/setting" className="d-flex align-items-center mt-4 mt-md-0">
              <FaAngleDoubleLeft className="font-16 text-blue" /> <span className="ms-2 font-weight-400 font-16 text-blue">{translate(props.lang, "Back")}</span>
            </Link>
            <div className="card card-black h-100 py-4 px-4 mt-3">
              <div className="card-header align-items-center border-bottom-0 text-center text-md-start">
                <span className={`headerText`}>{translate(props.lang, "Transaction Password")}</span>
              </div>
              <div className="card-body align-items-center">
                <Row className="justify-content-center align-items-start">
                  {/* <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <span className="labelText ms-1">{translate(props.lang, "current transaction password")}</span>
                    <div className={`input-group ${errors.oldPassword ? 'errorBorder' : ''}`}>
                      <Input
                        type="password"
                        name="oldPassword"
                        placeholder={translate(props.lang, "current transaction password")}
                        className="form-control input-transparent"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                    <span className="error errorTextRed">{errors.oldPassword}</span>
                  </Col> */}
                  <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                    <span className="font-weight-500 font-18 text-grey-1 text-capitalize ms-1">{translate(props.lang, "new transaction password")}</span>

                    <div className={`input-group mt-2 ${errors.newPassword ? 'errorBorder' : ''}`}>
                      <Input
                        type="password"
                        name="newPassword"
                        placeholder={translate(props.lang, "Enter Your New Transaction Password")}
                        className="form-control input-transparent"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <span className="error errorTextRed">{translate(props.lang,errors.newPassword)}</span>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                    <span className="font-weight-500 font-18 text-grey-1 text-capitalize ms-1">{translate(props.lang, "Confirm New Transaction Password")}</span>

                    <div className={`input-group mt-2 ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                      <Input
                        type="password"
                        name="confirmPassword"
                        placeholder={translate(props.lang, "Confirm New Transaction Password")}
                        className="form-control input-transparent"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <span className="error errorTextRed">{translate(props.lang,errors.confirmPassword)}</span>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} className="mb-5">
                    <div className="form-group mb-3">
                        <span className="font-weight-500 font-18 text-grey-1 text-capitalize">{translate(props.lang, "Verification Code")}</span>
                      <div className={`input-group mt-2 ${errors.verificationCode ? 'errorBorder' : ''}`}>
                        <input
                          type="text"
                          className="form-control input-transparent font-weight-400 font-16"
                          placeholder={translate(props.lang, "Enter Verification Code")}
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                        />
                        <div className='mx-0 mx-md-2 d-flex justify-content-center align-items-center'>
                          <div className="requestCodeBtn px-3 py-1 me-2" disabled={loadingOtp} onClick={(e) => sendOTP(e)}>
                            <span className="requestCodeBtnText">
                              {
                                !sendVerificationCode ? translate(props.lang, "Send") : translate(props.lang, "Resend")
                              }
                            </span>
                          </div>
                        </div>
 
                      </div>
                      <span className="error">{translate(props.lang,errors.verificationCode)}</span>
                    </div>

                  </Col>
                </Row>
                <div className="text-center form-group mb-3">
                  <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={updatePassword} disabled={loading}>
                    {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(ChangeTransactionPassword);
